// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".volume-1O8d0eUA {\n  width: 24px;\n  height: 24px;\n  flex-shrink: 0;\n}\n\n.volume-1O8d0eUA svg {\n  fill:  rgba(0,0,0,1);\n  transition: fill 0.25s, transform 300ms;\n}\n\n.desktop-3zq1efKR.volume-1O8d0eUA:hover svg,\n.desktop-3zq1efKR.volume-1O8d0eUA:focus svg {\n  fill: rgba(0,0,0,.8);\n}\n\n.light-npGsxX0d.volume-1O8d0eUA svg {\n  fill: #ffffff;\n}\n\n.desktop-3zq1efKR.light-npGsxX0d.volume-1O8d0eUA:hover svg,\n.desktop-3zq1efKR.light-npGsxX0d.volume-1O8d0eUA:focus svg {\n  fill: rgba(255, 255, 255, 0.8);\n}\n\n\n.volume-1O8d0eUA:disabled svg {\n  fill: rgba(68, 68, 68, 0.2);\n}\n\n.light-npGsxX0d.volume-1O8d0eUA:disabled svg {\n  fill: rgba(255, 255, 255, 0.2);\n}\n\n/* temp fix, without .desktop these disabled rules will not apply on desktop */\n.desktop-3zq1efKR.volume-1O8d0eUA:disabled svg {\n  fill: rgba(68, 68, 68, 0.2);\n}\n\n.desktop-3zq1efKR.light-npGsxX0d.volume-1O8d0eUA:disabled svg {\n  fill: rgba(255, 255, 255, 0.2);\n}\n", ""]);
// Exports
exports.locals = {
	"volume": "volume-1O8d0eUA",
	"desktop": "desktop-3zq1efKR",
	"light": "light-npGsxX0d"
};
module.exports = exports;
