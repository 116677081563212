import preact from 'preact';
import Provider from 'preact-context-provider';
import MutedAutoplayOverlay from './component';

const render = (container, player, store) => {
  preact.render(
    <Provider store={store}>
      <MutedAutoplayOverlay player={player} />
    </Provider>,
    container
  );
};

//
// API
//

export default {
  enable: false,
  name: 'mutedAutoplayOverlay',
  proto: {
    _initialize() {
      const player = this._player;
      const currentState = player.store.getState();
      const autoplay = currentState.player.options.autoplay;

      if (!autoplay) {
        return;
      }

      /**
       * controlsOverlay deprecated with VISUALS-794
       * @see https://jira.nyt.net/browse/VISUALS-794
       */
      // Hide controls while waiting to know if autoplay is allowed
      player.emit('plugin:controls:forceDisable');
      // player.emit('plugin:controlsOverlay:disable');

      // Check for when the canAutoplay data has initialized
      const unsubscribe = player.store.whenever(
        state => state.plugins.autoplay.canAutoplayMuted !== undefined,
        true,
        () => {
          unsubscribe();

          // Initialize plugin if canAutoplayMuted, otherwise reenable disabled controls
          if (player.store.getState().plugins.autoplay.canAutoplayMuted) {
            this.init();
          } else {
            // Because we are not autoplaying, revert some properties to resemble standard behavior
            player.emit('plugin:controls:forceEnable');
            // player.emit('plugin:controlsOverlay:enable');
            player.mute(false);
            player.video.removeAttribute('loop');
          }
        }
      );
    },

    init() {
      /**
       * controlsOverlay deprecated with VISUALS-794
       * @see https://jira.nyt.net/browse/VISUALS-794
       */
      const player = this._player;
      const store = player.store;

      render(this._container, player, store);

      player.on(player.events.MEDIA_ELEMENT_MOUNTED, () => {
        player.emit('plugin:controls:forceDisable');
        // player.emit('plugin:controlsOverlay:disable');
      });

      // Pause all other unmuted videos when the video is unmuted
      player.on(player.events.MUTE, muted => {
        if (!muted) {
          Object.keys(window.__VHS__.instances).forEach(instanceId => {
            if (
              player.props.instanceId !== instanceId &&
              !window.__VHS__.instances[instanceId].isMuted()
            ) {
              window.__VHS__.instances[instanceId].pause();
            }
          });
        }
      });

      // Pause umuted videos if this video starts to play while unmuted
      player.on(player.events.PLAY, () => {
        if (!player.isMuted()) {
          Object.keys(window.__VHS__.instances).forEach(instanceId => {
            if (
              player.props.instanceId !== instanceId &&
              !window.__VHS__.instances[instanceId].isMuted()
            ) {
              window.__VHS__.instances[instanceId].pause();
            }
          });
        }
      });

      player.on(player.events.LOAD_START, () => {
        if (player.isMuted()) {
          player.emit('plugin:controls:forceDisable');
          // player.emit('plugin:controlsOverlay:disable');
        }
      });

      // Pause player when out of view if muted. If unmuted, keep playing so users can hear audio.
      player.on(player.events.OUT_VIEWPORT, () => {
        if (player.isMuted()) player.pause();
      });
    },

    // Dummy reducer is needed until we remove `combineReducers`
    reducer() {
      return {
        mutedAutoplayOverlay: (state = {}) => state
      };
    }
  }
};
