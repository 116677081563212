/* eslint-disable no-restricted-globals */

const getParentHostname = () => {
  try {
    return parent.window.location.hostname;
  } catch (e) {
    return '';
  }
};

export default getParentHostname;
