/* eslint-disable react/no-unknown-property */

import preact from 'preact';
import styles from './styles.css';

class Countdown extends preact.Component {
  get containerCssClasses() {
    const cssClasses = [styles.container, styles[this.props.breakpoint]];

    return cssClasses.join(' ');
  }

  get circleCssClasses() {
    const cssClasses = [styles.svgCircle];

    if (this.props.isVisible) {
      cssClasses.push(styles.svgCircleAnimated);
    }

    return cssClasses.join(' ');
  }

  get shouldShowUpNextText() {
    if (
      this.props.breakpoint === 'xxs' ||
      this.props.breakpoint === 'xs' ||
      this.props.breakpoint === 's' ||
      this.props.breakpoint === 'm'
    ) {
      return false;
    }

    return true;
  }

  handleCountdownFinished() {
    this.props.onCountdownFinished();
  }

  render() {
    if (this.props.wasAutoplayCancelled) {
      return null;
    }

    return (
      <div className={this.containerCssClasses}>
        {this.shouldShowUpNextText && (
          <span className={styles.upNextText}>Up Next</span>
        )}
        <svg className={styles.svg} viewBox="0 0 60 60" width="60" height="60">
          <circle
            cx="30"
            cy="30"
            r="27"
            stroke="#FFFFFF"
            stroke-opacity="0.2"
            fill="none"
            stroke-width="2"
          />
          <circle
            className={this.circleCssClasses}
            onAnimationEnd={() => this.handleCountdownFinished()}
            cx="30"
            cy="30"
            r="27"
            stroke="#FFFFFF"
            fill="none"
            stroke-width="2"
          />
        </svg>
        <svg
          className={styles.svgPauseBars}
          width="20"
          height="30"
          viewBox="0 0 20 30"
          fill="#F7F7F7"
        >
          <g>
            <path d="M13 0h7v30h-7zM0 0h7v30H0z" />
          </g>
        </svg>
      </div>
    );
  }
}

export default Countdown;
