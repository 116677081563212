// @ts-check
import getCookie from '../../util/get-cookie';
import { logger } from './purr';
import canAccessHybridConfigPurrDirectives from './canAccessHybridConfigPurrDirectives';
import logHybridConfigPurrDirectivesWarning from './logHybridConfigPurrDirectivesWarning';

/**
 * TODO: Review if this is strictly necessary!
 *
 * Attempt to read the `nyt-purr` cookie early. If we get back the empty string, and the Hybrid
 * config (`window.config`) does not yet exist, then we have to wait for the `window.load` event
 * to fire before the Hybrid config is set. This is becuase the code which injects the Hybrid
 * config into the Hybrid webview on iOS must do so after the document has finished loading.
 * @see https://github.com/nytm/ios-newsreader-fusion/blob/c732ac2/Newsreader/Utilities/UI/Web%20View%20Controller/CommandProcessor/WKUserScript%2BNewsreader.swift#L16
 * @see https://developer.apple.com/documentation/webkit/wkuserscriptinjectiontime/atdocumentend
 *
 * Therefore, we call this function as late as possible within the `Player` class's `create()`
 * method so that the calls to the `purr()` function in `normalizeData()` can remain synchronous.
 *
 * @see https://jira.nyt.net/browse/UXCL-804
 *
 * @param {Window} [win]
 * @param {string} [cookie]
 * @returns {Promise<void>}
 */
export default async function waitForHybridConfig(win = window, cookie = '') {
  /**
   * Do nothing if either the `nyt-purr` cookie can be read or the Hybrid config's `PurrDirectives`
   * property can be read.
   */
  if (
    getCookie('nyt-purr', cookie, win.document) ||
    canAccessHybridConfigPurrDirectives(win)
  ) {
    return;
  }

  /**
   * At this point, we know that either `getCookie('nyt-purr')` has returned the empty string or
   * we could not access `window.config.PurrDirectives`. We next need to decide if we need to wait
   * for the `window.onload` event, since the iOS app's Hybrid webview only makes the Hybrid config
   * available after the document has finished loading. We'll use `document.readyState` to decide
   * whether or not we need to listen and wait for the `window.onload` event.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
   * @see https://github.com/nytm/ios-newsreader-fusion/blob/c732ac2/Newsreader/Utilities/UI/Web%20View%20Controller/CommandProcessor/WKUserScript%2BNewsreader.swift#L16
   * @see https://developer.apple.com/documentation/webkit/wkuserscriptinjectiontime/atdocumentend
   */
  const shouldWaitForWindowOnloadEvent = win.document.readyState !== 'complete';

  if (shouldWaitForWindowOnloadEvent) {
    logger.info(
      'Waiting for the `window.onload` event to see if `window.config.PurrDirectives` is available afterwards...'
    );
    await new Promise(resolve => {
      win.addEventListener('load', resolve);
    });
  }

  /**
   * Check again to see if we can **now** access just the Hybrid config's `PurrDirectives` prop.
   * If we _still_ cannot access it, log a warning to the console.
   */
  if (!canAccessHybridConfigPurrDirectives(win)) {
    logHybridConfigPurrDirectivesWarning(shouldWaitForWindowOnloadEvent);
  }
}
