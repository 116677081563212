// @ts-check
import retry from 'async-retry';
import apiEvents from 'src/player/events';
import { actions } from 'src/player/actions';
import Logger from 'src/util/logger';
import ProgressiveDownload from './progressive-download';

/**
 * To be lazy loaded.
 * @type {typeof import('hls.js')}
 */
let Hls;

class Hlsjs extends ProgressiveDownload {
  constructor(store, container, player, video = null) {
    super(store, container, player, video);

    this.logger = new Logger('vhs:playback:hlsjs');

    this.subscribe();
  }

  async init() {
    await super.init();
    try {
      /**
       * webpack 4: set to `.default` since we're importing a CommonJS module.
       * @see https://medium.com/webpack/webpack-4-import-and-commonjs-d619d626b655
       */
      Hls = (await retry(
        () => import(/* webpackChunkName: "hlsjs" */ 'hls.js'),
        { retries: 5 }
      )).default;
    } catch (error) {
      this.logger.error(
        'Error loading `hls.js`. If you think this is a configuration issue, try setting `VHS.config.publicPath`.\nhttps://github.com/nytimes/vhs/blob/master/doc/API.md#vhsconfigpublicpath'
      );

      throw error;
    }
  }

  async play() {
    this.logger.log('play');

    if (!this.state.isMediaLoaded) {
      await this.load();
    }

    return this.video.play();
  }

  reloadVideo() {
    this.setSource();
    this.triggerLoadStart();
    return this.play();
  }

  destroy() {
    this.pause();
    this.unregisterListeners();

    // clean up HLS
    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
  }

  //
  // Private
  //

  setSource() {
    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }

    this._setupHlsjs(this.state.mediaSrc);
  }

  onDurationChange() {
    this.store.dispatch(
      actions.media.duration.didChange(this.video.duration || 0)
    );
  }

  triggerFatalError(data) {
    data.hlsjs = true;
    this.pause();
    this.player.emit(apiEvents.ERROR, data);
  }

  mapGlobalToLocalState() {
    super.mapGlobalToLocalState();

    const globalState = this.store.getState();
    this.state = {
      ...this.state,
      isMediaLoaded: globalState.player.isMediaLoaded,
      isLive: globalState.player.media.isLive,
      is360: globalState.player.media.is360,
      mediaSrc: globalState.player.media.src,
      debug: globalState.player.options.debug
    };
  }

  _setupHlsjs(renditionUrl) {
    const opts = {
      fragLoadingTimeOut: 20000,
      fragLoadingMaxRetry: 6,
      fragLoadingRetryDelay: 500,
      manifestLoadingTimeOut: 10000,
      manifestLoadingMaxRetry: 6,
      manifestLoadingRetryDelay: 500,
      enableWebVTT: true,
      capLevelToPlayerSize: true,
      maxBufferLength: 30
    };

    // Capping the level to player size for 360
    // videos too restrictive on the quality
    if (this.state.is360) {
      opts.capLevelToPlayerSize = false;
    }

    if (this.state.debug) {
      opts.debug = this.logger;
    }

    this.hls = new Hls(opts);

    // Start 360 videos at a slightly higher quality level
    if (this.state.is360) {
      this.hls.startLevel = 1;
    }

    this.hls.attachMedia(this.video);

    this._setupHlsjsEvents(renditionUrl);
  }

  _setupHlsjsEvents(renditionUrl) {
    this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      this.hls.loadSource(renditionUrl);
    });

    this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
      if (!this.state.isLive) {
        this.hls.nextLevel = this.hls.levels.length - 1;
      }
    });

    this.hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
      this._levelLoaded(event, data);
    });

    this.hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
      this.store.dispatch(actions.media.didSwitchHlsLevel(data.level));
    });

    this.hls.on(Hls.Events.SUBTITLE_TRACK_LOADED, () => {
      this.store.dispatch(actions.media.didSubtitleTrackLoad());
    });

    this.hls.on(Hls.Events.ERROR, (event, data) => {
      if (data.fatal) {
        if (data.type === Hls.ErrorTypes.MEDIA_ERROR) {
          this.hls.recoverMediaError();
        } else {
          this.triggerFatalError(data);
        }
      }
    });
  }

  _levelLoaded(event, data) {
    let currentLevelType;

    if (!this.state.isLive) {
      return;
    }

    if (data && data.details) {
      currentLevelType = data.details.live ? 'live' : 'vod';
      if (this.levelType !== currentLevelType) {
        this.player.emit(apiEvents.LEVEL_TYPE_CHANGE, currentLevelType);
      }
      this.levelType = currentLevelType;
    }
  }
}

export default Hlsjs;
