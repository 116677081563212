// @ts-check
import { logger } from './purr';
import canAccessHybridConfigPurrDirectives from './canAccessHybridConfigPurrDirectives';
// TODO: import from `@nyt/purr`
import getNytPurrCookieValue from './getNytPurrCookieValue';

/**
 * Derive the equivalent `nyt-purr` cookie value from the PURR directives
 * stored in a property Hybrid config (`window.config.PurrDirectives`).
 * @see https://jira.nyt.net/browse/UXCL-804
 * @returns {string}
 */
export default function getPurrCookieValueFromHybridConfig() {
  try {
    if (canAccessHybridConfigPurrDirectives()) {
      return getNytPurrCookieValue(window.config.PurrDirectives);
    }
  } catch (e) {
    logger.error(
      'An error was thrown when attempting to derive the `nyt-purr` cookie value!'
    );
  }

  logger.warn(
    'Could not derive the `nyt-purr` cookie value from `window.config.PurrDirectives`.'
  );
  return '';
}
