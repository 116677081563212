// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Row-3UfofSZ6 {\n  flex-direction: row;\n  display: flex;\n  margin-bottom: 20px;\n  width: 100%;\n}\n\n.Row-3UfofSZ6:last-child {\n  margin-bottom: 0;\n}\n\n.Row-3UfofSZ6.fill-N8gkzj52 {\n  height: 0;\n  min-width: 0;\n  flex-grow: 1;\n}\n\n.Row-3UfofSZ6.top-3GNsMw8f {\n  align-self: flex-start;\n  width: 100%;\n}\n.Row-3UfofSZ6.bottom-34rEctyv {\n  align-self: flex-end;\n  width: 100%;\n}\n.Row-3UfofSZ6.fill-N8gkzj52 {\n  align-self: stretch;\n  width: 100%;\n}\n\n.Row-3UfofSZ6.absolute-HDrym120 {\n  position: absolute;\n}\n\n.Row-3UfofSZ6.margin-auto-2RxoCFWQ {\n  margin: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"Row": "Row-3UfofSZ6",
	"fill": "fill-N8gkzj52",
	"top": "top-3GNsMw8f",
	"bottom": "bottom-34rEctyv",
	"absolute": "absolute-HDrym120",
	"margin-auto": "margin-auto-2RxoCFWQ"
};
module.exports = exports;
