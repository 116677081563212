import preact from 'preact';
import supportsCssLineClamp from 'src/util/supports-css-line-clamp';
import styles from './styles.css';
import Countdown from './countdown';
import PlayIcon from './play-icon';

class VideoLarge extends preact.Component {
  // eslint-disable-next-line class-methods-use-this
  get titleCssClasses() {
    const classes = [styles.title];

    if (!supportsCssLineClamp()) {
      classes.push(styles.singleLineTruncation);
    } else {
      classes.push(styles.multiLineTruncation);
    }

    if (this.props.section === 'opinion') {
      classes.push(styles.opinion);
    }

    return classes.join(' ');
  }

  get containerCssClasses() {
    const classes = [styles.container];

    if (this.props.isHighlighted) {
      classes.push(styles.isHighlighted);
    }

    return classes.join(' ');
  }

  get containerStyle() {
    return {
      width: `calc((100% - ${(this.props.totalSize - 1) * 10}px) / ${
        this.props.totalSize
      })`
    };
  }

  handleClick(event) {
    event.stopPropagation();

    if (this.props.autoplay && !this.props.wasAutoplayCancelled) {
      this.props.cancelAutoplay();
    } else {
      this.props.animateCurtainAndPlay(this.props.id);
    }
  }

  // To disable focus styling
  // eslint-disable-next-line class-methods-use-this
  handleMouseDown(event) {
    event.preventDefault();
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.handleClick(event);
    }
  }

  handleMouseEnter() {
    this.props.onHighlightChange(this.props.index);
  }

  handleMouseLeave() {
    this.props.onHighlightChange();
  }

  render() {
    return (
      <span
        className={this.containerCssClasses}
        style={this.containerStyle}
        onClick={event => this.handleClick(event)}
        onMouseDown={event => this.handleMouseDown(event)}
        onKeyDown={event => this.handleKeyDown(event)}
        onMouseEnter={() => this.handleMouseEnter()}
        onMouseLeave={() => this.handleMouseLeave()}
        role="button"
        tabIndex={0}
      >
        <div>
          <span
            className={styles.container2}
            style={{
              backgroundImage: this.props.posterImg
                ? `url(${this.props.posterImg})`
                : 'none'
            }}
          >
            <span className={styles.mask} />
            <span className={styles.mask2} />
            <span className={styles.metadata}>
              {this.props.duration}
              {this.props.section === 'opinion' && (
                <span>
                  <span className={styles.metadataInterpunct}>&nbsp;</span>
                  Opinion
                </span>
              )}
            </span>

            {!this.props.wasAutoplayCancelled &&
              this.props.autoplay && (
                <Countdown
                  wasAutoplayCancelled={this.props.wasAutoplayCancelled}
                  breakpoint={this.props.breakpoint}
                  isVisible={this.props.isVisible}
                  onCountdownFinished={this.props.onCountdownFinished}
                />
              )}
            {this.props.wasAutoplayCancelled &&
              this.props.autoplay && (
                <PlayIcon
                  breakpoint={this.props.breakpoint}
                  show={this.props.isHighlighted}
                />
              )}
            {!this.props.autoplay && (
              <PlayIcon
                breakpoint={this.props.breakpoint}
                show={this.props.isHighlighted}
              />
            )}
          </span>
          {/* For some reason, the `-webkit-box-orient` rule doesn't get compiled on the CSS file */}
          <span
            className={this.titleCssClasses}
            style={{ '-webkit-box-orient': 'vertical' }}
          >
            {this.props.title}
          </span>
        </div>
      </span>
    );
  }
}

export default VideoLarge;
