/* eslint-disable jsx-a11y/no-static-element-interactions */

import preact from 'preact';
import { connect } from 'preact-redux';
import { bind } from 'monocle-decorators';
import {
  getContainerVisibility,
  getHasRecommendation,
  getAutoplayNext,
  getRecommendedVideos,
  getLayout,
  getShareUrl
} from './selectors';
import EndSlatePresentation from './presentation';
import Curtain from '../curtain';
import actions from '../../actions';
import styles from './styles.css';

const MOBILE_LAYOUT = 'mobile';

const mapStateToProps = (state, ownProps) => ({
  isContainerVisible: getContainerVisibility(state),
  isVisible: state?.plugins?.endSlate?.isVisible,
  breakpoint: state.plugins.responsive.size,
  hasRecommendation: getHasRecommendation(state),
  autoplayNext: getAutoplayNext(state),
  recommendedVideos: getRecommendedVideos(state),
  mediaId: state.player.media.id,
  layout: getLayout(state),
  shareUrl: getShareUrl(state),
  gtmTrackEvent: state?.player?.options?.gtmTrackEvent,
  ...ownProps
});

@connect(mapStateToProps)
class EndSlate extends preact.Component {
  static getInitialState() {
    return {
      isMouseOver: false,
      didShowAnimationRun: false,
      isCurtainAnimating: false,
      isCurtainOpening: false,
      isCurtainClosing: false,
      shouldCurtainOpen: false,
      shouldCurtainClose: false,
      videoIdToBeLoaded: null
    };
  }

  constructor(props) {
    super(props);
    this.state = EndSlate.getInitialState();
  }

  getContainerCssClasses() {
    const cssClasses = [styles.container];

    // For smooth animation, we don't hide container while curtain is animating.
    if (this.props.isContainerVisible || this.state.isCurtainAnimating) {
      cssClasses.push(styles.isVisible);
    }

    return cssClasses.join(' ');
  }

  getContentCssClasses() {
    const cssClasses = [styles.content];

    if (this.props.isVisible && this.props.isContainerVisible) {
      cssClasses.push(styles.isVisible);
    }

    return cssClasses.join(' ');
  }

  //
  // Reactions
  //

  @bind
  onReplayClick() {
    this.animateCurtainAndPlay(this.props.mediaId);
  }

  // For a11y
  @bind
  onKeyPress(event) {
    if (event.code === 'Enter') {
      this.onReplayClick();
    }
  }

  //
  // Animation
  //

  animateCurtainAndPlay(videoId = null) {
    this.setState({
      isCurtainAnimating: true,
      didShowAnimationRun: true,
      shouldCurtainClose: true,
      videoIdToBeLoaded: videoId
    });
  }

  initAnimation() {
    this.setState({
      isCurtainAnimating: true,
      didShowAnimationRun: true,
      shouldCurtainClose: true
    });
  }

  @bind
  async onCurtainClosed() {
    if (this.props.isVisible) {
      if (
        // `options.id` is normalized to `'unknown'` if falsy
        this.state.videoIdToBeLoaded !== 'unknown' &&
        // We don't want to load the current video again if we're replaying the existing video
        this.state.videoIdToBeLoaded !== this.props.mediaId
      ) {
        // Load a new video
        this.context.player.load(this.state.videoIdToBeLoaded, true);
      } else {
        /**
         * Restart playback of the current video. Calling `this.context.player.playback.load()`
         * resets some player state, which ensures that the End Slate fully disappears now and
         * can reappear later. Once the Promise returned by `load()` resolves, we then call `play()`
         * on the playback interface to start over the current video. We also do this in the
         * VideoWithAds playback interface:
         * @see src/player/playback/video-with-dfp.js#L156
         */
        await this.context.player.playback.load();
        this.context.player.playback.play();
      }
      actions(this.context.store).hide();
    } else {
      actions(this.context.store).show();
    }

    this.setState({
      shouldCurtainClose: false,
      shouldCurtainOpen: true
    });
  }

  @bind
  onCurtainOpen() {
    if (!this.props.isVisible) {
      this.setState(EndSlate.getInitialState());
    } else {
      this.setState({
        isCurtainAnimating: false,
        shouldCurtainClose: false,
        shouldCurtainOpen: false
      });
    }
  }

  //
  // Lifecycle hoooks
  //

  componentDidUpdate() {
    if (this.props.isContainerVisible && !this.state.didShowAnimationRun) {
      this.initAnimation();
    }
  }

  //
  // Tracking via GTM from News Monorepo
  //
  @bind
  trackShareButtonClick() {
    const dataLayer = window.dataLayer || [];
    const shareEvent = {
      event: 'moduleInteraction',
      eventData: {
        pagetype: 'article',
        trigger: 'module',
        type: 'click'
      },
      module: {
        region: 'video-end',
        name: 'share-tools',
        element: {
          name: 'share-url',
          label: 'permalink',
          url: this.props.shareUrl
        }
      }
    };

    if (window.UnifiedTracking) {
      window.UnifiedTracking.sendAnalytic('moduleInteraction', shareEvent);
    } else {
      dataLayer.push(shareEvent);
    }
  }

  //
  // Render
  //

  render() {
    return (
      <div className={this.getContainerCssClasses()}>
        <div className={this.getContentCssClasses()}>
          <EndSlatePresentation
            isVisible={this.props.isVisible}
            isCurtainAnimating={this.state.isCurtainAnimating}
            breakpoint={this.props.breakpoint}
            hasRecommendation={this.props.hasRecommendation}
            recommendedVideos={this.props.recommendedVideos}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            onReplayClick={this.onReplayClick}
            onShareButtonClick={this.trackShareButtonClick}
            onKeyPress={this.onKeyPress}
            animateCurtainAndPlay={id => this.animateCurtainAndPlay(id)}
            autoplayNext={this.props.autoplayNext}
            layout={this.props.layout}
            shareUrl={this.props.shareUrl}
          />
        </div>
        <Curtain
          open={this.state.shouldCurtainOpen}
          close={this.state.shouldCurtainClose}
          onClosed={this.onCurtainClosed}
          onOpen={this.onCurtainOpen}
        />
      </div>
    );
  }
}

export default EndSlate;
export { MOBILE_LAYOUT };
