import preact from 'preact';
import { connect } from 'preact-redux';
import Arrow from './arrow';
import styles from './styles.css';

function mapStateToProps(state, ownProps) {
  return {
    playlistName: state?.player?.media?.playlist?.headline,
    seriesName: state?.player?.media?.series,
    viewMoreUrl: state?.player?.media?.playlist?.videos?.[0]?.url,
    ...ownProps
  };
}

@connect(mapStateToProps)
class Footer extends preact.Component {
  // To disable focus styling
  // eslint-disable-next-line class-methods-use-this
  handleMouseDown(event) {
    event.preventDefault();
  }

  render() {
    return (
      <a
        className={styles.container}
        href={this.props.viewMoreUrl || 'https://nytimes.com/video'}
        target="_blank"
        rel="noopener noreferrer"
        onMouseDown={event => this.handleMouseDown(event)}
      >
        <hr className={styles.hr} />
        {this.props.seriesName && (
          <span className={styles.description}>
            From the Series{' '}
            <span className={styles.seriesName}>{this.props.seriesName}</span>
          </span>
        )}
        {!this.props.seriesName &&
          this.props.playlistName && (
            <span className={styles.description}>
              From the Playlist{' '}
              <span className={styles.playlistName}>
                {this.props.playlistName}
              </span>
            </span>
          )}

        <span className={styles.allVideosLink}>
          View All Videos <Arrow />
        </span>
      </a>
    );
  }
}

export default Footer;
