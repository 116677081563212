// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!src/stylesheet/font-families.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".container--M5ChT9D {\n  color: #999999;\n  line-height: 1.23;\n  font-weight: 600;\n  font-size: 13px;\n  position: relative;\n  height: 40px; /* Total height is 50px (40 - 20 + grid padding) */\n  margin-bottom: -20px; /* Hack so outline visually wraps children elements */\n  display: block;\n  text-decoration: none;\n}\n\n.description-1CR1zy5D {\n  padding-left: 10px;\n  position: relative;\n  top: 2px;\n}\n\n.seriesName-1Kg-xNrP {\n  font-size: 13px;\n  line-height: 1.23;\n  color: #f7f7f7;\n}\n\n.playlistName-2uNrnl4x {\n  font-size: 13px;\n  line-height: 1.23;\n  color: #f7f7f7;\n}\n\n.allVideosLink-1uvJbtji {\n  text-align: right;\n  color: #999999;\n  position: absolute;\n  right: 10px;\n  top: 18px;\n  transition: color 0.3s ease-out;\n}\n\n.container--M5ChT9D:hover .allVideosLink-1uvJbtji {\n  color: #f7f7f7;\n}\n\n.hr-AIm1Xl9c {\n  box-sizing: border-box;\n  height: 2px;\n  opacity: 0.1;\n  border: solid 1px #ffffff;\n  margin-bottom: 13px;\n  margin-top: 14px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container--M5ChT9D " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["franklin"] + "",
	"description": "description-1CR1zy5D",
	"seriesName": "seriesName-1Kg-xNrP " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["karnak"] + "",
	"playlistName": "playlistName-2uNrnl4x",
	"allVideosLink": "allVideosLink-1uvJbtji",
	"hr": "hr-AIm1Xl9c"
};
module.exports = exports;
