// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.handle-1zXSZCUd {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 5;\n  fill:#000000;\n}\n.light-3xY1RaNZ .handle-1zXSZCUd {\n  fill: #f7f7f7;\n}\n\n.desktop-2jBlk9zh .handle-1zXSZCUd {\n  width: 6px;\n  height: 6px;\n}\n\n.desktop-2jBlk9zh.scrubbing-2-SaO4ra .handle-1zXSZCUd {\n  width: 6px;\n  height: 6px;\n}\n\n.scrubbing-2-SaO4ra .handle-1zXSZCUd {\n  width: 12px;\n  height: 12px;\n}\n\n.ink-2HVFWFpD {\n  transform: scale(0);\n  width: 20px;\n  height: 20px;\n}\n\n.scrubbing-2-SaO4ra .ink-2HVFWFpD {\n  transform: scale(1) ;\n  -webkit-animation: ripple-3bGNCbCi 200ms ease;\n          animation: ripple-3bGNCbCi 200ms ease;\n  z-index: 4;\n  fill: rgba(0, 0, 0, 0.2);\n}\n.light-3xY1RaNZ.scrubbing-2-SaO4ra .ink-2HVFWFpD {\n  fill: rgba(255, 255, 255, 0.2);\n}\n\n@-webkit-keyframes ripple-3bGNCbCi {\n  from {\n    transform: scale(0);\n  }\n  to {\n    transform: scale(1);\n  }\n}\n\n@keyframes ripple-3bGNCbCi {\n  from {\n    transform: scale(0);\n  }\n  to {\n    transform: scale(1);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"handle": "handle-1zXSZCUd",
	"light": "light-3xY1RaNZ",
	"desktop": "desktop-2jBlk9zh",
	"scrubbing": "scrubbing-2-SaO4ra",
	"ink": "ink-2HVFWFpD",
	"ripple": "ripple-3bGNCbCi"
};
module.exports = exports;
