// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".scrubberContainer-1WyrbTR8 {\n  position: absolute;\n  left: 0;\n  z-index: 4;\n  height: 100%;\n\n  opacity: 1;\n  transform: scale(1);\n  transform-origin: center left;\n  transition: opacity 150ms ease-out, transform 150ms ease-out;\n}\n\n.scrubberContainer-1WyrbTR8.scrubberContainerHide-38e1Psy2{\n  opacity: 0;\n  transform: scale(0.1);\n  transition: opacity 150ms ease-in, transform 150ms ease-in;\n}\n\n.center-2ePOhelf {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n}\n\n.timeline-3hWvXF_F {\n  flex-grow: 1;\n  cursor: pointer;\n  position: relative;\n  height: 40px;\n  transition: transform 400ms ease-out;\n  transform-origin: right;\n  transform: scaleX(1);\n}\n\n.timeline-3hWvXF_F.timeline--placeholder-3vi2HDMp {\n  cursor: inherit;\n}\n\n.timeline__progress-1G5PcPam {\n  width: 0%;\n  height: 2px;\n  margin: 19px 0;\n  position: absolute;\n  background: rgba(0, 0, 0, 1);\n  z-index: 3;\n}\n.light-Hbch6i4G .timeline__progress-1G5PcPam {\n  background: rgba(255, 255, 255);\n}\n\n.timeline__buffered-3wXSHzxd {\n  width: 0%;\n  height: 2px;\n  margin: 19px 0;\n  position: absolute;\n  background: rgba(0, 0, 0, .2);\n  z-index: 2;\n}\n.light-Hbch6i4G .timeline__buffered-3wXSHzxd {\n  background: rgba(255, 255, 255, 0.4);\n}\n\n.timeline__duration-2wArYQHv {\n  width: 100%;\n  height: 2px;\n  margin: 19px 0;\n  position: absolute;\n  background: rgba(0, 0, 0, .1);\n  z-index: 1;\n}\n.light-Hbch6i4G .timeline__duration-2wArYQHv {\n  background: rgba(255, 255, 255, 0.2);\n}\n\n.animate-25gH6GDN {\n  transition: all 800ms ease !important;\n}\n\n/*\n * This is just used for keyboard control of the \"slider\"\n * We have to use the ARIA slider role instead of an input\n * It's just an extra <div> with some keyboard handlers and an outline\n */\n.timeline__slider-3bratC31:focus {\n  height: 2px;\n  width: 100%;\n  outline: 1px solid #4a90e2;\n  outline-offset: 1px;\n}\n", ""]);
// Exports
exports.locals = {
	"scrubberContainer": "scrubberContainer-1WyrbTR8",
	"scrubberContainerHide": "scrubberContainerHide-38e1Psy2",
	"center": "center-2ePOhelf",
	"timeline": "timeline-3hWvXF_F",
	"timeline--placeholder": "timeline--placeholder-3vi2HDMp",
	"timeline__progress": "timeline__progress-1G5PcPam",
	"light": "light-Hbch6i4G",
	"timeline__buffered": "timeline__buffered-3wXSHzxd",
	"timeline__duration": "timeline__duration-2wArYQHv",
	"animate": "animate-25gH6GDN",
	"timeline__slider": "timeline__slider-3bratC31"
};
module.exports = exports;
