// @ts-check
/**
 * TODO: Move to `@nyt/purr`
 * @see https://github.com/kohlmannj/purr-js/blob/68ff239/src/getNytPurrCookieValue.ts
 */

import {
  PURR_DIRECTIVE_COOKIE_VALUE_CHAR_MAP,
  PURR_DIRECTIVE_COOKIE_VALUES
} from './purrConstants';

export default function getNytPurrCookieValue(directives) {
  // Map `PURR_DIRECTIVE_COOKIE_VALUE_CHAR_MAP` to ensure the correct character order and length
  return PURR_DIRECTIVE_COOKIE_VALUE_CHAR_MAP.map(name => {
    const directive = directives[name]; // e.g. 'controllers'
    // also accept raw output of Privacy API (an object like { "value": directiveValue }):
    let directiveValue;
    if (typeof directive === 'string') {
      directiveValue = directive;
    } else if (
      typeof directive === 'object' &&
      'value' in directive &&
      directive.value
    ) {
      directiveValue = directive.value;
    }
    const valueMapping = PURR_DIRECTIVE_COOKIE_VALUES[name];
    if (directiveValue && directiveValue in valueMapping) {
      // e.g. 'c'
      return valueMapping[directiveValue];
    }
    // Return '_' if not in `directives`
    return '_';
  }).join('');
}
