const types = {
  handleFullscreenChange: 'plugins/fullscreen/HANDLE_FULLSCREEN_CHANGE',
  fullscreenApi: 'plugins/fullscreen/HANDLE_FULLSCREEN_API'
};

export default {
  handleFullscreenChange: isFullscreen => ({
    type: types.handleFullscreenChange,
    payload: {
      isFullscreen
    }
  }),
  fullscreenApi: api => ({
    type: types.fullscreenApi,
    payload: {
      api
    }
  })
};

export { types };
