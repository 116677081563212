// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.rewind-3JjXn1Ko svg {\n  fill:  rgba(0,0,0,1);\n  transition: fill 0.25s, transform 300ms;\n}\n\n.desktop-o0RixJeK.rewind-3JjXn1Ko:hover svg,\n.desktop-o0RixJeK.rewind-3JjXn1Ko:focus svg {\n  fill: rgba(0,0,0,.8);\n}\n\n.light-Lxh25U-X.rewind-3JjXn1Ko svg {\n  fill: #ffffff;\n}\n\n.desktop-o0RixJeK.light-Lxh25U-X.rewind-3JjXn1Ko:hover svg,\n.desktop-o0RixJeK.light-Lxh25U-X.rewind-3JjXn1Ko:focus svg {\n  fill: rgba(255, 255, 255, 0.8);\n}\n\n/* on safari the svg is cropped,\n * so increase the container's size as a hacky fix */\n.rewind-3JjXn1Ko {\n  width: 31px;\n  height: 31px;\n}\n.rewind-3JjXn1Ko svg{\n  width: 30px;\n  height: 30px;\n}\n\n/* disable these buttons */\n.rewind-3JjXn1Ko:disabled svg {\n  fill: rgba(68, 68, 68, 0.2);\n}\n\n.light-Lxh25U-X.rewind-3JjXn1Ko:disabled svg {\n  fill: rgba(255, 255, 255, 0.2);\n}\n\n/* temp fix, without .desktop these disabled rules will not apply on desktop */\n.desktop-o0RixJeK.rewind-3JjXn1Ko:disabled svg {\n  fill: rgba(68, 68, 68, 0.2);\n}\n\n.desktop-o0RixJeK.light-Lxh25U-X.rewind-3JjXn1Ko:disabled svg {\n  fill: rgba(255, 255, 255, 0.2);\n}\n", ""]);
// Exports
exports.locals = {
	"rewind": "rewind-3JjXn1Ko",
	"desktop": "desktop-o0RixJeK",
	"light": "light-Lxh25U-X"
};
module.exports = exports;
