import preact from 'preact';
import Provider from 'preact-context-provider';
import LiveAutoplayOverlay from './component';

const render = (container, player, store) => {
  preact.render(
    <Provider store={store}>
      <LiveAutoplayOverlay player={player} />
    </Provider>,
    container
  );
};

//
// API
//

export default {
  enable: false,
  name: 'liveAutoplayOverlay',
  proto: {
    _initialize() {
      const player = this._player;
      const currentState = player.store.getState();
      const autoplay = currentState.player.options.autoplay;

      if (!autoplay) {
        return;
      }

      const unsubscribe = player.store.whenever(
        state =>
          state.plugins.autoplay.canAutoplayMuted &&
          !state.plugins.autoplay.canAutoplayUnmuted,
        true,
        () => {
          unsubscribe();
          this.init();
        }
      );
    },

    init() {
      const player = this._player;
      const store = player.store;

      player.emit('plugin:controls:forceDisable');

      render(this._container, player, store);

      /**
       * controlsOverlay deprecated with VISUALS-794
       * @see https://jira.nyt.net/browse/VISUALS-794
       */
      player.on(player.events.MEDIA_ELEMENT_MOUNTED, () => {
        player.emit('plugin:controls:disable');
        // player.emit('plugin:controlsOverlay:disable');
      });

      player.on(player.events.LOAD_START, () => {
        if (player.store.getState().player.isMediaMuted) {
          player.emit('plugin:controls:disable');
          // player.emit('plugin:controlsOverlay:disable');
        }
      });

      player.on(player.events.AD_STARTED, () => {
        player.emit('plugin:controls:enable');
        // player.emit('plugin:controlsOverlay:enable');
      });

      player.on(player.events.AD_SKIPPED, () => {
        if (player.store.getState().player.isMediaMuted) {
          player.emit('plugin:controls:disable');
          // player.emit('plugin:controlsOverlay:disable');
        }
      });

      player.on(player.events.AD_COMPLETED, () => {
        if (player.store.getState().player.isMediaMuted) {
          player.emit('plugin:controls:disable');
          // player.emit('plugin:controlsOverlay:disable');
        }
      });
    },

    // Dummy reducer is needed until we remove `combineReducers`
    reducer() {
      return {
        liveAutoplayOverlay: (state = {}) => state
      };
    }
  }
};
