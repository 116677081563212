import preact from 'preact';
import { connect } from 'preact-redux';
import safeChain from 'safe-chain';
import { bind } from 'monocle-decorators';
import './styles.scss';

const mapStateToProps = (state, ownProps) => ({
  controlsActive: safeChain(state, 'player.isActive'),
  ...ownProps
});

@connect(mapStateToProps)
class MutedAutoplayOverlay extends preact.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: props.clicked || false
    };
  }

  @bind
  onClick() {
    const isLive = this.props.player?.options?.live;

    if (!this.state.clicked) {
      this.setState({ clicked: true });
      this.props.player.mute(false);
      if (!isLive) {
        this.props.player.emit('plugin:controls:reset');
        this.props.player.emit('plugin:controls:enable');
        /**
         * controlsOverlay deprecated with VISUALS-794
         * @see https://jira.nyt.net/browse/VISUALS-794
         */
        // this.props.player.emit('plugin:controlsOverlay:enable');
        // After a user unmutes the video, remove the looping functionality
        this.props.player.options.loop = false;
        this.props.player.options.mutedAutoplayOverlayClicked = true;
        this.props.player.video.removeAttribute('loop');
      }
    } else if (isLive) {
      this.setState({ clicked: false });
      this.props.player.mute(true);
    }
  }

  render() {
    const isLive = this.props.player?.options?.live;
    const hasInteracted = this.state.clicked;

    if (hasInteracted && !isLive) return null;

    return (
      <button
        title={hasInteracted ? 'Tap To mute' : 'Tap To Unmute'}
        className="overlay"
        onClick={this.onClick}
      >
        {isLive && <div className="liveBadge">Live</div>}
        {!hasInteracted && (
          <div className="unmuteIconWrapper">
            <div className="backgroundCircle" />
            <div className="volume-muted" />
          </div>
        )}
      </button>
    );
  }
}

export default MutedAutoplayOverlay;
