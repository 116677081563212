// @ts-check
/**
 * The list of plugins which should be enabled when instantiating
 * an Audio Player with `VHS.audio()`.
 */
export default {
  audioControls: true,
  eventErrorDispatcher: true,
  pauseOtherPlayers: true,
  responsive: true,
  viewportIntersectionObserver: true,
  nytAnalytics: true
};
