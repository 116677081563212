/* eslint-disable class-methods-use-this */
import preact from 'preact';
import { bind } from 'monocle-decorators';
import { Grid, Cell, Row } from 'src/util/grid-component';
import Poster from '../../poster';
import styles from './styles.css';
import RecommendationList from '../../recommendation-list';
import ReplayButtonSvg from './img/ReplayButton.svg';
import ShareButtonSvg from './img/ShareButton.svg';
import ShareButtonLinkedCopiedSvg from './img/ShareButtonLinkCopied.svg';

class EndSlatePresentation extends preact.Component {
  render() {
    // Mobile should have `m` breakpoint as the highest
    let breakpoint;
    switch (this.props.breakpoint) {
      case 'xxl':
      case 'xl':
      case 'l':
        breakpoint = 'm';
        break;
      default:
        breakpoint = this.props.breakpoint;
        break;
    }
    return (
      <div
        className={styles.container}
        role="region"
        aria-label="video end slate"
      >
        <Poster />
        <Grid
          horizontal="center"
          vertical="middle"
          padding={this.props.gridPadding}
          fill
          style={{ backgroundColor: 'rgba(18,18,18,0.6)' }}
        >
          <Row
            style={{
              marginBottom: '5px'
            }}
          >
            <Cell size="12of12" horizontal="center">
              <ReplayButton
                className={styles.endslateButton}
                onClick={this.props.onReplayClick}
              />
            </Cell>
          </Row>
          {this.props.shareUrl && (
            <Row>
              <Cell size="12of12" horizontal="center">
                <ShareButton
                  onClick={this.props.onShareButtonClick}
                  shareUrl={this.props.shareUrl}
                />
              </Cell>
            </Row>
          )}
        </Grid>
        <Grid
          horizontal="center"
          vertical="bottom"
          padding={this.props.gridPadding}
          fill
          style={{ backgroundColor: 'rgba(18,18,18,0.6)' }}
        >
          {this.props.hasRecommendation && (
            <Row>
              <Cell size="12of12">
                <RecommendationList
                  gridPadding={this.props.gridPadding}
                  breakpoint={breakpoint}
                  recommendedVideos={this.props.recommendedVideos}
                  isVisible={this.props.isVisible}
                  animateCurtainAndPlay={this.props.animateCurtainAndPlay}
                  autoplayNext={this.props.autoplayNext}
                  presentation={this.props.layout}
                />
              </Cell>
            </Row>
          )}
        </Grid>
      </div>
    );
  }
}

EndSlatePresentation.defaultProps = {
  gridPadding: 30
};

// Replay Button
class ReplayButton extends preact.Component {
  render() {
    return (
      <button
        title="Replay"
        aria-label="Replay"
        className={styles.endslateButton}
        onClick={this.props.onClick}
      >
        <img style={{ opacity: 1 }} src={ReplayButtonSvg} alt="Replay" />
      </button>
    );
  }
}

// Share Button
class ShareButton extends preact.Component {
  constructor() {
    super();
    this.state = {
      linkIsCopied: false
    };
  }

  @bind
  generateShareUrl() {
    let newUrl = '';
    const { shareUrl } = this.props;
    const url = new URL(shareUrl);
    const params = new URLSearchParams(url.search);

    params.set('smid', 'url-share');
    newUrl = `${url.origin}${url.pathname}?${params.toString()}`;
    return newUrl;
  }

  @bind
  onClick(event) {
    event.preventDefault();
    const shareUrl = this.generateShareUrl();
    if (window.isSecureContext) {
      // Copy link to clipboard
      navigator.clipboard.writeText(shareUrl).then(() => {
        this.props.onClick();
        // Switch to Link Copied svg
        this.setState({
          linkIsCopied: true
        });

        // Revert button back to un-copied state.
        setTimeout(() => {
          this.setState({
            linkIsCopied: false
          });
        }, 2500);
      });
    } else {
      console.log(`Clipboard not accessible in HTTP. Share URL: ${shareUrl}`);
    }
  }

  render() {
    return (
      <button
        title="Share"
        aria-label="Share"
        className={styles.endslateButton}
        onClick={this.onClick}
      >
        <img
          src={
            this.state.linkIsCopied
              ? ShareButtonLinkedCopiedSvg
              : ShareButtonSvg
          }
          alt={this.state.linkIsCopied ? 'Link Copied!' : 'Share'}
          style={{ opacity: 1 }}
        />
      </button>
    );
  }
}

export default EndSlatePresentation;
