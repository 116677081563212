// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!src/stylesheet/font-families.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, "/* container */\n\n.container-1hGbH1-t {\n  width: 60px;\n  height: 60px;\n  background-color: rgba(0, 0, 0, 0.5);\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  margin-top: -30px;\n  margin-left: -30px;\n  transform-origin: center;\n  border-radius: 50%;\n}\n\n.container-1hGbH1-t.xxl-S7bw08na {}\n.container-1hGbH1-t.xl-19u_f9i2 {}\n.container-1hGbH1-t.l-KVDIcz63 {}\n\n.container-1hGbH1-t.m-Nxvb5HRY,\n.container-1hGbH1-t.s-rCz3OzMX,\n.container-1hGbH1-t.xs-FqFHzKmT,\n.container-1hGbH1-t.xxs-i5Lqoyu2 {\n  transform: scale(0.66);\n}\n\n/* upNextText */\n\n.upNextText-1UFhBjG0 {\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 1.14;\n  text-align: center;\n  color: #f7f7f7;\n  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);\n  width: 100%;\n  top: -23px;\n  position: absolute;\n}\n\n/* circle */\n\n.svg-BC7tLuU2 {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%) rotate(-90deg);\n}\n\n.svgCircle-3azs64LY {\n  stroke-dasharray: 169.64600329384882;\n}\n\n.svgCircleAnimated-3vD0T-P2 {\n  -webkit-animation: dash-2Z6QEwij 6s linear forwards;\n          animation: dash-2Z6QEwij 6s linear forwards;\n}\n\n@-webkit-keyframes dash-2Z6QEwij {\n  from {\n    /* stroke-dashoffset = circunference length = 2 * π * radius */\n    stroke-dashoffset: 169.64600329384882;\n  }\n  to {\n    stroke-dashoffset: 0;\n  }\n}\n\n@keyframes dash-2Z6QEwij {\n  from {\n    /* stroke-dashoffset = circunference length = 2 * π * radius */\n    stroke-dashoffset: 169.64600329384882;\n  }\n  to {\n    stroke-dashoffset: 0;\n  }\n}\n\n/* pause bars */\n\n.svgPauseBars-1zgJGZug {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1hGbH1-t",
	"xxl": "xxl-S7bw08na",
	"xl": "xl-19u_f9i2",
	"l": "l-KVDIcz63",
	"m": "m-Nxvb5HRY",
	"s": "s-rCz3OzMX",
	"xs": "xs-FqFHzKmT",
	"xxs": "xxs-i5Lqoyu2",
	"upNextText": "upNextText-1UFhBjG0 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["franklin"] + "",
	"svg": "svg-BC7tLuU2",
	"svgCircle": "svgCircle-3azs64LY",
	"svgCircleAnimated": "svgCircleAnimated-3vD0T-P2",
	"dash": "dash-2Z6QEwij",
	"svgPauseBars": "svgPauseBars-1zgJGZug"
};
module.exports = exports;
