import { types } from './actions';

const initialState = () => ({
  didEnableTranscript: false,
  didDisableTranscript: false,
  shouldEnableTranscript: false,
  shouldDisableTranscript: false,
  isTranscriptAvailable: false,
  isTranscriptEnabled: false
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case types.transcriptAvailable: {
      const { isTranscriptAvailable } = action;
      return {
        ...state,
        isTranscriptAvailable
      };
    }
    case types.shouldEnableTranscript: {
      return {
        ...state,
        shouldEnableTranscript: true
      };
    }
    case types.shouldDisableTranscript: {
      return {
        ...state,
        shouldDisableTranscript: true
      };
    }
    case types.didEnableTranscript: {
      return {
        ...state,
        isTranscriptEnabled: true,
        shouldEnableTranscript: false
      };
    }
    case types.didDisableTranscript: {
      return {
        ...state,
        isTranscriptEnabled: false,
        shouldDisableTranscript: false
      };
    }
    default:
      return state;
  }
};
