export default {
  PROGRESSIVE_DOWNLOAD: 'progressive_download',
  HLS: 'hls',
  HLSJS: 'hlsjs',
  LIVE: 'live',
  DFP: 'dfp',
  THREESIXTY_PROGRESSIVE_DOWNLOAD: '360_progressive_download',
  THREESIXTY_HLSJS: '360_hlsjs',
  AUDIO: 'audio',
  CINEMAGRAPH: 'cinemagraph'
};
