// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!src/stylesheet/font-families.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".countdown-1QKNtJ50 {\n  position: absolute;\n  z-index: 40;\n  box-sizing: border-box;\n  color: #fff;\n  background: #666;\n  background: rgba(102, 102, 102, 0.7);\n  border-radius: 100px;\n  text-align: center;\n  transition: background 0.4s, width 1s;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  font-weight: 700;\n\n  /*responsive*/\n  top: 10px;\n  right: 10px;\n  width: auto;\n  padding: 11px 15px;\n  font-size: 13px;\n}\n\n.countdown-1QKNtJ50.xxl-qWr7tA9G,\n.countdown-1QKNtJ50.xl-1QXqoJIT {\n  top: 10px;\n  right: 10px;\n  width: 140px;\n  padding: 16px 20px;\n  font-size: 14px;\n}\n\n.countdown-1QKNtJ50.l-gTeUX-1C {\n  top: 10px;\n  right: 10px;\n  width: 120px;\n  padding: 11px 15px;\n  font-size: 13px;\n}\n\n.countdown-1QKNtJ50.m-b6VEUEE7 {\n  top: 10px;\n  right: 10px;\n  width: 100px;\n  padding: 10px 14px;\n  font-size: 11px;\n}\n\n.countdown-1QKNtJ50.s-1X8FDBeL {\n  top: 5px;\n  right: 5px;\n  width: 100px;\n  padding: 6px 9px;\n  font-size: 10px;\n}\n\n.countdown-1QKNtJ50.xs-3MrI0JsM,\n.countdown-1QKNtJ50.xxs-1S21aDca {\n  top: 0;\n  right: 0;\n  width: 100%;\n  padding: 7px 9px 6px;\n  font-size: 10px;\n\n  border-radius: 0;\n  text-align: right;\n}\n\n.label-3a3bqUnC {\n  border-right: 1px solid #888;\n  padding-right: 10px;\n  margin-right: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"countdown": "countdown-1QKNtJ50 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["franklin"] + "",
	"xxl": "xxl-qWr7tA9G",
	"xl": "xl-1QXqoJIT",
	"l": "l-gTeUX-1C",
	"m": "m-b6VEUEE7",
	"s": "s-1X8FDBeL",
	"xs": "xs-3MrI0JsM",
	"xxs": "xxs-1S21aDca",
	"label": "label-3a3bqUnC"
};
module.exports = exports;
