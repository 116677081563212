import preact from 'preact';
import styles from './styles.css';

class PlayIcon extends preact.Component {
  get containerCssClasses() {
    const cssClasses = [styles.container, styles[this.props.breakpoint]];

    if (this.props.show) {
      cssClasses.push(styles.show);
    }

    return cssClasses.join(' ');
  }

  render() {
    return (
      <div className={this.containerCssClasses}>
        <svg
          className={styles.playIcon}
          width="26"
          height="30"
          viewBox="0 0 26 30"
          fill="#F7F7F7"
        >
          <g>
            <path d="M26 15L0 30V0z" />
          </g>
        </svg>
      </div>
    );
  }
}

export default PlayIcon;
