// @ts-check

import supportH264 from './support-h264';
import supportWebM from './support-webm';

/**
 * @returns {string}
 */
const getSupportedType = () => {
  if (supportH264()) {
    return 'mp4';
  } else if (supportWebM()) {
    return 'webm';
  }

  return '';
};

export default getSupportedType;
