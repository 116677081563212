import preact from 'preact';
import { Provider, connect } from 'preact-redux';
import isMobile from 'src/util/is-mobile';
import isDesktop from 'src/util/is-desktop';
import getShareUrl from './selectors';
import PersistentShareButtonIcon from './icon';
import styles from './styles.css';

const name = 'persistent-share-button';

const mapStateToProps = state => {
  const mutedAutoplayOverlay = state?.player?.options?.mutedAutoplayOverlay;
  const persistentControls = state?.player?.options?.controls?.persistent;

  let shouldShowMutedAutoplayOverlay = true;
  if (mutedAutoplayOverlay) {
    shouldShowMutedAutoplayOverlay =
      state?.player?.options?.mutedAutoplayOverlayClicked;
  }

  const show =
    isMobile() &&
    state?.player?.isMediaPlaying &&
    !state?.player?.isActive &&
    !state?.player?.isAdActive &&
    shouldShowMutedAutoplayOverlay &&
    !persistentControls;

  return {
    show,
    shareUrl: getShareUrl(state),
    config: state?.player?.options?.persistentShareButton
  };
};

let PersistentShareButton = props => {
  const { shareUrl, config, show } = props;
  if (
    isDesktop() ||
    !shareUrl ||
    !config?.onClick ||
    typeof config?.onClick !== 'function'
  ) {
    return null;
  }

  return (
    <div
      className={`${styles[`${name}-container`]} ${
        show ? styles[`${name}-active`] : styles[`${name}-hidden`]
      }`}
    >
      <button onClick={config.onClick} aria-label="Share this video">
        <PersistentShareButtonIcon />
      </button>
    </div>
  );
};

PersistentShareButton = connect(mapStateToProps)(PersistentShareButton);

const render = (container, store, player) => {
  preact.render(
    <Provider store={store}>
      <PersistentShareButton player={player} />
    </Provider>,
    container
  );
};

//
// API
//

export default {
  enable: true,
  name,
  proto: {
    _initialize() {
      const player = this._player;
      const store = player.store;
      render(this._container, store, player);
    }
  }
};
