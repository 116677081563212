import clone from 'clone';
import dom from 'corleone';
import { debounce } from 'monocle-decorators';
import captionsActions from 'src/plugins/captions/actions';
import apiEvents from 'src/player/events';
import formatDuration from 'src/util/format-duration';
import isIE11 from 'src/util/is-ie11';
import reducer from './reducer';
import actions from './actions';
import template from './template.html';
import './style.scss';
import { getShareUrl } from '../end-slate/component/end-slate/selectors';

function mapStateToProps(state, prevProps) {
  const { instanceId, isTouch, isMediaMuted, volume } = state.player;
  const progress = (state.player.media && state.player.media.progress) || 0;
  const isLive = Boolean(
    state?.plugins?.controls?.isLive || state?.player?.options?.live
  );
  const onShowTranscript = state?.player?.options?.transcript?.onShowTranscript;

  const isCoverPluginActive = Boolean(state?.player?.options?.cover);
  const headline = state?.player?.media?.headline;
  const hideScrubber = state?.player?.options?.controls?.hideScrubber;
  const persistent = state?.player?.options?.controls?.persistent;
  const templateClass = state?.player?.options?.controls?.templateClass;
  const playerType = state.player.options?.type;
  const shareUrl = getShareUrl(state);

  return {
    ...prevProps,
    ...state.plugins.controls,
    headline,
    hideScrubber,
    isLive,
    instanceId,
    isTouch,
    isMediaMuted,
    isCoverPluginActive,
    onShowTranscript,
    persistent,
    playerType,
    progress,
    shareUrl,
    templateClass,
    volume
  };
}

function getPlayButtonTitle(headline) {
  if (!headline) {
    return 'Play Video';
  }

  return `Play Video "${headline}"`;
}

function getPauseButtonTitle(headline) {
  if (!headline) {
    return 'Pause Video';
  }

  return `Pause Video "${headline}"`;
}

export default {
  name: 'controls',
  enable: true,
  proto: {
    template,
    _initialize() {
      this.props = mapStateToProps(this._player.store.getState(), {});
      this.triggerControlsSizeChangedDebounced = debounce(
        this.triggerControlsSizeChanged.bind(this),
        150
      );
      this.subscribeToStore();

      this.render();
      // usage:
      // player.emit('plugin:controls:enable');
      // player.emit('plugin:controls:disable');
      this._on('enable', this.enable.bind(this));
      this._on('disable', this.disable.bind(this));
      this._on('forceEnable', this.forceEnable.bind(this));
      this._on('forceDisable', this.forceDisable.bind(this));
      this._on('reset', this.reset.bind(this));
    },

    reducer() {
      return { controls: reducer };
    },

    subscribeToStore() {
      this._player.store.subscribe(() => {
        const lastProps = this.props;
        this.props = mapStateToProps(
          this._player.store.getState(),
          clone(this.props)
        );
        if (this.props.duration !== lastProps.duration) {
          this.updateDuration();
        }
      });
      this._player.store.whenever(
        'plugins.captions.areCaptionsAvailable',
        true,
        () => this.enableCaptions()
      );
      this._player.store.whenever(
        'plugins.captions.areCaptionsAvailable',
        false,
        () => this.disableCaptions()
      );
      this._player.store.whenever(
        'plugins.transcript.isTranscriptEnabled',
        true,
        () => this.enableTranscript()
      );
      this._player.store.whenever(
        'plugins.transcript.isTranscriptEnabled',
        false,
        () => this.disableTranscript()
      );
      this._player.store.whenever(
        'plugins.captions.areCaptionsEnabled',
        true,
        () => this.captionsOn()
      );
      this._player.store.whenever(
        'plugins.captions.areCaptionsEnabled',
        false,
        () => this.captionsOff()
      );

      const unsubscribe = this._player.store.whenever(
        'plugins.fullscreen.api',
        false,
        () => {
          this.ui.fullscreen.remove();
          unsubscribe();
        }
      );
    },

    _media_element_mounted() {
      if (this.props.controlsEnabled) {
        this.setupControls();
      }
    },

    _loadstart() {
      this._media_element_mounted();
      this._pause();
    },

    _ended() {
      const button = this.ui.play;
      if (!button) return;
      const playButtonTitle = getPlayButtonTitle(this.props.headline);

      button.classList.remove('vhs-icon-pause');
      button.classList.add('vhs-icon-play');
      button.setAttribute('title', playButtonTitle);
      button.setAttribute('aria-label', playButtonTitle);
    },

    _idle() {
      this.hide();
    },

    _active() {
      this.show();
    },

    _play() {
      const button = this.ui.play;
      this.ui.controls.inert = false; // Make controls "tababble" again
      const pauseButtonTitle = getPauseButtonTitle(this.props.headline);
      this.ui.controls.classList.add('vhs-controls-enabled');
      if (button) {
        button.classList.remove('vhs-icon-play');
        button.classList.add('vhs-icon-pause');
        button.setAttribute('title', pauseButtonTitle);
        button.setAttribute('aria-label', pauseButtonTitle);
      }

      // media playback causes the video container to gain focus
      // so we need to set the focus back to the button that
      // triggered playback on the next tick
      const lastFocusedElement = this.focusedElement;
      if (lastFocusedElement) {
        setTimeout(() => {
          lastFocusedElement.focus();
        }, 0);
      }
    },

    _pause() {
      const button = this.ui.play;
      if (!button) return;
      const playButtonTitle = getPlayButtonTitle(this.props.headline);

      button.classList.remove('vhs-icon-pause');
      button.classList.add('vhs-icon-play');
      button.setAttribute('title', playButtonTitle);
      button.setAttribute('aria-label', playButtonTitle);
    },

    _go_fullscreen() {
      this.ui.controls.classList.add('vhs-controls-fullscreen');
      const button = this.ui.fullscreen;
      if (!button) return;
      button.classList.remove('vhs-icon-resize-full');
      button.classList.add('vhs-icon-resize-default');
      button.setAttribute('title', 'Exit Fullscreen');
      button.setAttribute('aria-label', 'Exit Fullscreen');
    },

    _exit_fullscreen() {
      this.ui.controls.classList.remove('vhs-controls-fullscreen');
      const button = this.ui.fullscreen;
      if (!button) return;
      button.classList.remove('vhs-icon-resize-default');
      button.classList.add('vhs-icon-resize-full');
      button.setAttribute('title', 'Enter Fullscreen');
      button.setAttribute('aria-label', 'Enter Fullscreen');
    },

    _mute() {
      this.updateVolumeUI();
    },

    _volume_change() {
      this.updateVolumeUI();
    },

    _progress(e) {
      if (e.bufferEnd) {
        const bufferPosition = this.getSeekPosition(e.bufferEnd);
        if (this.ui.progressBuffer)
          this.ui.progressBuffer.style.width = `${bufferPosition}%`;
      }
    },

    _time_update(time) {
      if (!this.props.isMouseDown) {
        this.updateProgressUI(time);
      }
    },

    _ad_started() {
      this.setupAdMode();
      this._play();
    },

    _ad_paused() {
      this._pause();
    },

    _ad_resumed() {
      this._play();
    },

    _ad_completed() {
      if (this.props.isLive) {
        this.setupLiveMode();
      } else {
        this.setupVodMode();
        if (this.ui.controls) {
          this.ui.controls.classList.add('vhs-controls-active');
          this.ui.controls.classList.add('vhs-controls-enabled');
          this.ui.controls.classList.remove('vhs-controls-ads-inactive');
        }
      }
    },

    _ad_skipped() {
      this._ad_completed();
    },

    show() {
      if (this.props.forcedDisable) return;
      if (this.ui.gradient) {
        this.ui.gradient.classList.add('vhs-controls-active');
        this.ui.gradient.classList.remove('vhs-controls-hidden');
      }
      this.ui.controls.classList.add('vhs-controls-enabled');
      this.ui.controls.classList.add('vhs-controls-active');
      this.ui.controls.classList.remove('vhs-controls-hidden');
    },

    hide() {
      if (this.props.forcedEnable || this.props.persistent) return;

      this.ui.controls.classList.add('vhs-controls-hidden');
      this.ui.gradient.classList.add('vhs-controls-hidden');
      this.ui.controls.classList.remove('vhs-controls-active');
      this.ui.gradient.classList.remove('vhs-controls-active');
    },

    enable() {
      if (this.props.forcedDisable || !this.element) {
        return;
      }

      if (this.ui.gradient) this.ui.gradient.style.display = 'block';
      this.show();
    },

    disable() {
      if (!this.element) {
        return;
      }
      this.ui.controls.classList.remove('vhs-controls-enabled');
      this.ui.gradient.style.display = 'none';
      this.hide();
    },

    forceEnable() {
      this._player.store.dispatch(actions.forceEnable());
    },

    forceDisable() {
      this._player.store.dispatch(actions.forceDisable());
    },

    reset() {
      this._player.store.dispatch(actions.reset());
    },

    setupControls() {
      this.enable();
      this.updateVolumeUI();
      if (this.props.templateClass) {
        this.ui.controls.classList.add(this.props.templateClass);
        this.ui.gradient.classList.add(this.props.templateClass);
      }
      if (this.props.isTouch) {
        this.ui.controls.classList.remove('vhs-controls-no-touch');
        this.ui.gradient.classList.remove('vhs-controls-no-touch');
        this.ui.marker.classList.add(
          'vhs-plugin-controls-progress-marker--active'
        );
      }

      if (isIE11()) {
        this.fixStyleForIE11();
      }

      if (this.props.isLive) {
        this.setupLiveMode();
      } else {
        this.setupVodMode();
      }

      if (this.props.hideScrubber) {
        this.ui.progress.classList.add('vhs-progress-hidden');
      }

      if (!this.props.shareUrl) {
        this.ui.share.style.display = 'none';
      }

      this.triggerControlsSizeChanged();
    },

    setupLiveMode() {
      this.ui.transcript.classList.add('vhs-icon-transcript-hidden');
      this.ui.progress.classList.add('vhs-progress-hidden');
      this.ui.ad.style.display = 'none';
      this.ui.gradient.style.display = 'block';
      this.ui.messageContainer.classList.add('vhs-message-container-enabled');
      this.ui.live.style.display = 'flex';
      this._player.store.dispatch(captionsActions.captionsAvailable(false));
    },

    setupAdMode() {
      this.ui.transcript.classList.add('vhs-icon-transcript-hidden');
      this.ui.progress.classList.add('vhs-progress-hidden');
      this.ui.live.style.display = 'none';
      this.ui.messageContainer.classList.add('vhs-message-container-enabled');
      this.ui.ad.style.display = 'block';
      this.ui.gradient.style.display = 'none';
      this.ui.controls.classList.remove('vhs-controls-active');
      this.ui.controls.classList.remove('vhs-controls-enabled');
      this.ui.controls.classList.add('vhs-controls-ads-inactive');
      this._player.store.dispatch(captionsActions.captionsAvailable(false));
    },

    setupVodMode() {
      if (this.ui.messageContainer) {
        this.ui.messageContainer.classList.remove(
          'vhs-message-container-enabled'
        );
      }
      if (this.ui.transcript)
        this.ui.transcript.classList.remove('vhs-icon-transcript-hidden');
      if (this.ui.progress)
        this.ui.progress.classList.remove('vhs-progress-hidden');
      if (this.ui.gradient) this.ui.gradient.style.display = 'block';
    },

    levelTypeChange(levelType) {
      if (levelType === 'live' || this.props.isLive) {
        this.setupLiveMode();
      } else if (levelType === 'vod') {
        this.setupVodMode();
      }
    },

    updateProgressUI(time) {
      const progressTime = this.ui.progressTime;
      const marker = this.ui.marker;
      const currentTime = this.ui.currentTime;
      const progressSlider = this.ui.progressSlider;

      const percent = this.getSeekPosition(time);
      const formattedProgress = formatDuration(time);
      const formattedDuration = formatDuration(this.props.duration);

      if (progressTime) progressTime.style.width = `${percent}%`;
      if (marker) marker.style.left = `${percent}%`;
      if (currentTime) currentTime.textContent = formattedProgress;
      if (progressSlider) {
        progressSlider.setAttribute('aria-valuenow', time);
        progressSlider.setAttribute(
          'aria-valuetext',
          `${formattedProgress} of ${formattedDuration}`
        );
      }
    },

    getSeekPosition(time) {
      const position = time / this.props.duration * 100;
      const trimmedPosition = Math.max(0, Math.min(position, 100));
      return parseFloat(trimmedPosition.toFixed(2));
    },

    getTimePosition(event) {
      const pageX = this.props.isTouch
        ? event.changedTouches[0].pageX
        : event.pageX;
      const x = pageX - dom.offset(this.ui.progressTimeline).left;
      const time =
        this.props.duration / this.ui.progressTimeline.clientWidth * x;
      const trimmedTime = Math.max(0, Math.min(time, this.props.duration));
      return trimmedTime;
    },

    triggerControlsSizeChanged() {
      if (
        this._player.emit &&
        (this.width !== this.ui.controls.clientWidth ||
          this.height !== this.ui.controls.clientHeight)
      ) {
        this.width = this.ui.controls.clientWidth;
        this.height = this.ui.controls.clientHeight;
        this._player.store.dispatch(actions.heightChange(this.height));
        this._player.emit(apiEvents.CONTROLS_SIZE_CHANGED, {
          width: this.width,
          height: this.height
        });
      }
    },

    render() {
      const fullscreen = this.props.fullscreen;
      const headline = this.props.headline;
      const playButtonTitle = getPlayButtonTitle(headline);
      const pauseButtonTitle = getPauseButtonTitle(headline);
      const captionsEnabled = this.props.captionsEnabled;
      const isMediaMuted = this.props.isMediaMuted;

      this.element = dom.create(
        this.template({
          fullscreen,
          playButtonTitle,
          pauseButtonTitle,
          captionsEnabled,
          isMediaMuted
        })
      );
      this._container.appendChild(this.element);

      this.registerElements();
      this.bindEvents();

      if (this.props.isCoverPluginActive) {
        this.ui.controls.inert = true;
      }
    },

    registerElements() {
      const prefix = '.vhs-plugin-controls';
      this.ui = {
        controls: this.element.querySelector(`${prefix}`),
        gradient: this.element.querySelector(`${prefix}-gradient`),
        play: this.element.querySelector(`${prefix}-play`),
        playContainer: this.element.querySelector(`${prefix}-play-container`),
        fullscreen: this.element.querySelector(`${prefix}-fullscreen`),
        volumeContainer: this.element.querySelector(
          `${prefix}-volume-container`
        ),
        volume: this.element.querySelector(`${prefix}-volume`),
        volumeSliderContainer: this.element.querySelector(
          `${prefix}-volume-slider-container`
        ),
        volumeSliderFill: this.element.querySelector(
          `${prefix}-volume-slider-fill`
        ),
        volumeSlider: this.element.querySelector(`${prefix}-volume-slider`),
        messageContainer: this.element.querySelector(
          `${prefix}-message-container`
        ),
        progress: this.element.querySelector(`${prefix}-progress`),
        progressSlider: this.element.querySelector(`${prefix}-progress-slider`),
        progressTimeline: this.element.querySelector(
          `${prefix}-progress-timeline`
        ),
        progressBuffer: this.element.querySelector(`${prefix}-progress-buffer`),
        progressTime: this.element.querySelector(`${prefix}-progress-time`),
        marker: this.element.querySelector(`${prefix}-progress-marker`),
        duration: this.element.querySelector(`${prefix}-duration`),
        currentTime: this.element.querySelector(`${prefix}-current-time`),
        cc: this.element.querySelector(`${prefix}-cc`),
        transcript: this.element.querySelector(`${prefix}-transcript`),
        live: this.element.querySelector(`${prefix}-live-label`),
        ad: this.element.querySelector(`${prefix}-ad-label`),
        share: this.element.querySelector(`${prefix}-share`)
      };
    },

    bindEvents() {
      this.bindCommonEvents();
      if (this.props.isTouch) {
        this.bindTouchEvents();
      } else {
        this.bindMouseEvents();
        this.bindKeyboardEvents();
      }
    },

    bindCommonEvents() {
      if (this.ui.play)
        this.ui.play.addEventListener('click', () => {
          this._player.togglePlay();
        });
      if (this.ui.fullscreen)
        this.ui.fullscreen.addEventListener('click', () => {
          this._player.toggleFullscreen();
        });
      if (this.ui.volume)
        this.ui.volume.addEventListener('click', () => {
          this.handleVolumeToggle();
        });
      if (this.ui.cc)
        this.ui.cc.addEventListener('click', () => {
          this.toggleCC();
        });

      if (this.ui.share)
        this.ui.share.addEventListener('click', () => {
          this.handleShare();
        });

      if (this.ui.transcript)
        this.ui.transcript.addEventListener('click', evt => {
          this.showTranscript(evt);
        });

      // IE11 doesn't fire "input" events; instead it fires "change" in the way that "input" is
      // supposed to be fired. We can stop listening to "change" event once we stop supporting IE11.
      if (this.ui.volumeSlider)
        this.ui.volumeSlider.addEventListener('input', e => {
          this.handleVolumeChange(e);
        });
      if (this.ui.volumeSlider)
        this.ui.volumeSlider.addEventListener('change', e => {
          this.handleVolumeChange(e);
        });
      window.addEventListener('resize', () => {
        this.triggerControlsSizeChangedDebounced();
      });

      this._player.on(
        apiEvents.LEVEL_TYPE_CHANGE,
        this.levelTypeChange.bind(this)
      );
    },

    bindTouchEvents() {
      if (!this.ui.progressTimeline) return;
      this.ui.progressTimeline.addEventListener('touchstart', e => {
        this.progressTouchStart(e);
      });
      this.ui.progressTimeline.addEventListener('touchend', e => {
        this.progressTouchEnd(e);
      });
      this.ui.progressTimeline.addEventListener('touchmove', e => {
        this.progressTouchMove(e);
      });
    },

    bindMouseEvents() {
      if (this.ui.volumeContainer) {
        this.ui.volumeContainer.addEventListener('mouseenter', () => {
          this.volumeActive();
        });
        this.ui.volumeContainer.addEventListener('mouseleave', () => {
          this.volumeIdle();
        });
      }
      if (this.ui.progressTimeline) {
        this.ui.progressTimeline.addEventListener('mouseenter', () => {
          this.progressMouseEnter();
        });
        this.ui.progressTimeline.addEventListener('mouseleave', e => {
          this.progressMouseLeave(e);
        });
        this.ui.progressTimeline.addEventListener('mouseup', e => {
          this.progressMouseUp(e);
        });
        this.ui.progressTimeline.addEventListener('mousedown', e => {
          this.progressMouseDown(e);
        });
        this.ui.progressTimeline.addEventListener('mousemove', e => {
          this.progressMouseMove(e);
        });
      }

      document.addEventListener('mousemove', e => {
        this.documentMouseMove(e);
      });
      document.addEventListener('mouseup', e => {
        this.documentMouseUp(e);
      });

      // prevent focus() being called on button/input click
      // to avoid :focus styles (for a11y) being applied on click
      const elements = [
        this.ui.play,
        this.ui.progressSlider,
        this.ui.cc,
        this.ui.share,
        this.ui.transcript,
        this.ui.volume,
        this.ui.fullscreen
      ];

      const onMousedown = e => {
        e.preventDefault();
        if (this.focusedElement) {
          this.focusedElement.blur();
          this.focusedElement = null;
        }
      };

      elements.forEach(element => {
        if (element) element.addEventListener('mousedown', onMousedown);
      });

      const onVolumeSliderMousedown = () => {
        // set a flag to indicate this has been clicked
        // we cannot stop the event's propagation because we need to
        // consume the input events to change the volume
        this.volumeSliderClicked = true;

        if (this.focusedElement) {
          this.focusedElement.blur();
          this.focusedElement = null;
        }
      };
      if (this.ui.volumeSlider)
        this.ui.volumeSlider.addEventListener(
          'mousedown',
          onVolumeSliderMousedown
        );
    },

    bindKeyboardEvents() {
      // get all focusable elements
      const elements = [
        this.ui.play,
        this.ui.progressSlider,
        this.ui.cc,
        this.ui.share,
        this.ui.volume,
        this.ui.fullscreen,
        this.ui.transcript
      ];

      // attach focus and blur events
      const onBlur = () => {
        this._player.setActiveState();
        this.focusedElement = null;
      };

      elements.forEach(element => {
        if (element) {
          element.addEventListener('focus', () => {
            this._player.setActiveState();
            this.focusedElement = element;
          });
          element.addEventListener('blur', onBlur);
        }
      });

      const onVolumeSliderFocus = () => {
        if (this.volumeSliderClicked) {
          // this is a mouse event, so do undo the focus styles
          this.ui.volumeSlider.blur();

          this.volumeSliderClicked = false;
        } else {
          this.focusedElement = this.ui.volumeSlider;
        }
        this._player.setActiveState();
        this.volumeActive();
      };
      const onVolumeSliderBlur = () => {
        if (!this.volumeSliderClicked) {
          this.volumeIdle();
          this._player.setIdleState();
        }
        this.focusedElement = null;
      };
      if (this.ui.volumeSlider) {
        this.ui.volumeSlider.addEventListener('focus', onVolumeSliderFocus);
        this.ui.volumeSlider.addEventListener('blur', onVolumeSliderBlur);
      }

      // per-button keydown handlers
      const onPlayKeydown = e => {
        this._player.setActiveState();
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          this._player.togglePlay();
        }
      };

      const onProgressSliderKeydown = e => {
        this._player.setActiveState();
        if (
          e.key === 'ArrowRight' ||
          e.key === 'Right' ||
          e.key === 'ArrowUp' ||
          e.key === 'Up'
        ) {
          e.preventDefault();
          this._player.seek(
            Math.min(this.props.progress + 5, this.props.duration)
          );
        } else if (
          e.key === 'ArrowLeft' ||
          e.key === 'Left' ||
          e.key === 'ArrowDown' ||
          e.key === 'Down'
        ) {
          e.preventDefault();
          this._player.seek(Math.max(this.props.progress - 5, 0));
        }
      };

      const onCCKeydown = e => {
        this._player.setActiveState();
        if (e.key === 'Enter') {
          e.preventDefault();
          this.toggleCC();
        }
      };

      const onShareKeydown = e => {
        this._player.setActiveState();
        if (e.key === 'Enter') {
          e.preventDefault();
          this.handleShare();
        }
      };

      const onTranscriptKeydown = e => {
        this._player.setActiveState();
        if (e.key === 'Enter') {
          e.preventDefault();
          this.showTranscript(e);
        }
      };

      const onVolumeToggleKeydown = e => {
        this._player.setActiveState();
        if (e.key === 'Enter') {
          e.preventDefault();
          this.handleVolumeToggle();
        }
      };

      const onVolumeSliderKeydown = () => {
        this._player.setActiveState();
        this.volumeActive();
      };

      const onFullscreenKeydown = e => {
        this._player.setActiveState();
        if (e.key === 'Enter') {
          e.preventDefault();
          this._player.toggleFullscreen();
        }
      };

      if (this.ui.play) this.ui.play.addEventListener('keydown', onPlayKeydown);
      if (this.ui.progressSlider)
        this.ui.progressSlider.addEventListener(
          'keydown',
          onProgressSliderKeydown
        );
      if (this.ui.cc) this.ui.cc.addEventListener('keydown', onCCKeydown);
      if (this.ui.share)
        this.ui.share.addEventListener('keydown', onShareKeydown);
      if (this.ui.transcript)
        this.ui.transcript.addEventListener('keydown', onTranscriptKeydown);
      if (this.ui.volume)
        this.ui.volume.addEventListener('keydown', onVolumeToggleKeydown);
      if (this.ui.volumeSlider)
        this.ui.volumeSlider.addEventListener('keydown', onVolumeSliderKeydown);
      if (this.ui.fullscreen)
        this.ui.fullscreen.addEventListener('keydown', onFullscreenKeydown);
    },

    documentMouseMove(e) {
      if (this.props.isMouseDown) {
        this.progressMouseMove(e);
      }
    },

    documentMouseUp(e) {
      if (this.props.isMouseDown) {
        this._player.store.dispatch(actions.mouseDown(false));
        this._player.seek(this.getTimePosition(e));
        this.progressMouseLeave(e);
        this.progressMouseUp(e);
      }
    },

    progressTouchStart(e) {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      this._emit('progress:touchstart', this.ui.progressTimeline);
      this._player.store.dispatch(actions.wasPlaying(!this._player.isPaused()));
      this._player.pause();
    },

    progressTouchEnd(e) {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      this._emit('progress:touchend');
      this._player.seek(this.getTimePosition(e));
      if (this.props.wasPlaying) {
        this._player.play();
      }
    },

    progressTouchMove(e) {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      const time = this.getTimePosition(e);
      this._emit('progress:touchmove', {
        time,
        formattedTime: formatDuration(time)
      });
      this.updateProgressUI(time);
    },

    progressMouseEnter() {
      this._emit('progress:mouseenter', this.ui.progressTimeline);
      const marker = this.ui.marker;
      marker.classList.add('vhs-plugin-controls-progress-marker--active');
    },

    progressMouseLeave() {
      this._emit('progress:mouseleave');
      const marker = this.ui.marker;
      marker.classList.remove('vhs-plugin-controls-progress-marker--active');
    },

    progressMouseUp(e) {
      const time = this.getTimePosition(e);
      this._player.store.dispatch(actions.mouseDown(false));
      this.ui.marker.classList.remove(
        'vhs-plugin-controls-progress-marker-grabbing'
      );
      this._player.seek(time);
      if (this.props.wasPlaying && this._player.isPaused()) {
        if (time < this.props.duration) {
          this._player.togglePlay();
        }
        this._player.store.dispatch(actions.wasPlaying(false));
      }
    },

    progressMouseDown(e) {
      this._player.store.dispatch(actions.mouseDown(true));
      this.ui.marker.classList.add(
        'vhs-plugin-controls-progress-marker-grabbing'
      );
      this.progressMouseMove(e);
    },

    progressMouseMove(e) {
      const time = this.getTimePosition(e);
      this._emit('progress:mousemove', {
        time,
        formattedTime: formatDuration(time)
      });
      if (this.props.isMouseDown) {
        if (!this._player.isPaused()) {
          this._player.togglePlay();
          this._player.store.dispatch(actions.wasPlaying(true));
        }
        this.updateProgressUI(time);
      }
    },

    updateDuration() {
      if (!this.ui.duration) return;
      const { duration, progress } = this.props;
      const formattedDuration = formatDuration(duration);
      const formattedProgress = formatDuration(progress);

      this.ui.duration.textContent = formattedDuration;
      this.ui.progressSlider.setAttribute('aria-valuemax', this.props.duration);
      this.ui.progressSlider.setAttribute(
        'aria-valuetext',
        `${formattedProgress} of ${formattedDuration}`
      );
    },

    toggleCC() {
      if (this.props.captionsEnabled) {
        this._player.store.dispatch(captionsActions.shouldDisableCaptions());
      } else {
        this._player.store.dispatch(captionsActions.shouldEnableCaptions());
      }
    },

    generateShareUrl() {
      let newUrl = '';
      const { shareUrl } = this.props;
      if (!shareUrl) return newUrl;
      const url = new URL(shareUrl);
      const params = new URLSearchParams(url.search);

      params.set('smid', 'url-share');
      newUrl = `${url.origin}${url.pathname}?${params.toString()}`;
      return newUrl;
    },

    handleShare() {
      const shareUrl = this.generateShareUrl();
      if (!shareUrl) return;
      if (window.isSecureContext) {
        // Copy link to clipboard
        navigator.clipboard.writeText(shareUrl);
      } else {
        console.log(`Clipboard not accessible in HTTP. Share URL: ${shareUrl}`);
      }
      // track for metrics
      this.trackShareButtonClick();
      // Show share confirmation
      this.ui.share.classList.add('active');
      // Remove the class after a delay
      setTimeout(() => {
        this.ui.share.classList.remove('active');
      }, 2000);
    },

    enableCaptions() {
      const store = this._player.store;
      if (this.ui.cc) this.ui.cc.classList.remove('vhs-icon-cc-disabled');
      if (store.getState().player.options.captionsDefaultOn) {
        store.dispatch(captionsActions.shouldEnableCaptions());
      }
    },

    disableCaptions() {
      if (this.ui.cc) this.ui.cc.classList.add('vhs-icon-cc-disabled');
    },

    captionsOn() {
      const button = this.ui.cc;
      if (!button) return;
      button.classList.remove('vhs-icon-cc-off');
      button.classList.add('vhs-icon-cc-on');
      button.setAttribute('title', 'Turn Off Closed Captions');
      button.setAttribute('aria-label', 'Turn Off Closed Captions');
    },

    captionsOff() {
      const button = this.ui.cc;
      if (!button) return;
      button.classList.remove('vhs-icon-cc-on');
      button.classList.add('vhs-icon-cc-off');
      button.setAttribute('title', 'Turn On Closed Captions');
      button.setAttribute('aria-label', 'Turn On Closed Captions');
    },

    enableTranscript() {
      if (this.ui.transcript)
        this.ui.transcript.classList.remove('vhs-icon-transcript-disabled');
    },

    disableTranscript() {
      if (this.ui.transcript)
        this.ui.transcript.classList.add('vhs-icon-transcript-disabled');
    },

    showTranscript(evt) {
      if (
        this.props.onShowTranscript &&
        typeof this.props.onShowTranscript === 'function'
      ) {
        const isFullscreen = this._player.store.getState().plugins?.fullscreen
          ?.isFullscreen;
        if (isFullscreen && typeof this._player.fullscreen === 'function') {
          this._player.fullscreen(false);
        }
        this.props.onShowTranscript(evt);
      }
    },

    handleVolumeToggle() {
      if (this.props.isMediaMuted && this.props.volume === 0) {
        this._player.setVolume(0.75);
        this._player.mute(false);
      } else {
        this._player.toggleVolume();
      }
    },

    handleVolumeChange(e) {
      const volume = e.target.value;
      this._player.setVolume(volume / 100);
      this._player.mute(volume === '0');
    },

    updateVolumeUI() {
      if (!this.ui.volume) return;
      const volume = this.props.isMediaMuted ? 0 : this.props.volume;

      if (volume > 0.5) {
        this.ui.volume.classList.remove('vhs-icon-volume-half');
        this.ui.volume.classList.remove('vhs-icon-volume-off');
        this.ui.volume.classList.add('vhs-icon-volume-on');
        this.ui.volume.setAttribute('title', 'Mute');
        this.ui.volume.setAttribute('aria-label', 'Mute');
      } else if (volume > 0) {
        this.ui.volume.classList.remove('vhs-icon-volume-on');
        this.ui.volume.classList.remove('vhs-icon-volume-off');
        this.ui.volume.classList.add('vhs-icon-volume-half');
        this.ui.volume.setAttribute('title', 'Mute');
        this.ui.volume.setAttribute('aria-label', 'Mute');
      } else {
        this.ui.volume.classList.remove('vhs-icon-volume-on');
        this.ui.volume.classList.remove('vhs-icon-volume-half');
        this.ui.volume.classList.add('vhs-icon-volume-off');
        this.ui.volume.setAttribute('title', 'Unmute');
        this.ui.volume.setAttribute('aria-label', 'Unmute');
      }

      this.updateVolumeSliderUI();
    },

    updateVolumeSliderUI() {
      if (!this.ui.volumeSlider) return;
      const volume = this.props.isMediaMuted ? 0 : this.props.volume * 100;

      this.ui.volumeSliderFill.style.width = `${volume}%`;
      this.ui.volumeSlider.setAttribute('value', volume);
      this.ui.volumeSlider.setAttribute('aria-valuenow', volume);
    },

    volumeActive() {
      if (!this.ui.volumeSliderContainer) return;
      this.ui.volumeSliderContainer.classList.remove(
        'vhs-plugin-controls-volume-slider-hide'
      );
      this.ui.volumeSliderContainer.classList.add(
        'vhs-plugin-controls-volume-slider-show'
      );
    },

    volumeIdle() {
      if (!this.ui.volumeSliderContainer) return;
      this.ui.volumeSliderContainer.classList.remove(
        'vhs-plugin-controls-volume-slider-show'
      );
      this.ui.volumeSliderContainer.classList.add(
        'vhs-plugin-controls-volume-slider-hide'
      );
    },

    trackShareButtonClick() {
      const dataLayer = window.dataLayer || [];
      const shareEvent = {
        event: 'moduleInteraction',
        eventData: {
          pagetype: this.props.playerType,
          trigger: 'module',
          type: 'click'
        },
        module: {
          region: 'mid-video',
          name: 'share-tools',
          element: {
            name: 'share-url',
            label: 'permalink',
            url: this.props.shareUrl
          }
        }
      };

      if (window.UnifiedTracking) {
        window.UnifiedTracking.sendAnalytic('moduleInteraction', shareEvent);
      } else {
        dataLayer.push(shareEvent);
      }
    },

    fixStyleForIE11() {
      if (this.ui.volumeSlider) this.ui.volumeSlider.style.border = 'none';
    }
  }
};
