// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-QBaRfVbo {\n  box-sizing: border-box;\n  position: relative;\n  width: 100%;\n  z-index: 1;\n}\n\n.background-VrKEz3Uc {\n  position: absolute;\n  background-color: rgba(18, 18, 18, 0.95);\n  height: 100%;\n}\n\n.spacer-AQ4SIhup {\n  margin-top: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container-QBaRfVbo",
	"background": "background-VrKEz3Uc",
	"spacer": "spacer-AQ4SIhup"
};
module.exports = exports;
