// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Empty selector for testing purposes. Used to get a reference to the element in the DOM. */\n.container-2Rhh3sWE {\n}\n\n/* BUTTONS */\n.endslateButton-2wsgrHow {\n  color: #f7f7f7;\n  border: none;\n  border-radius: 3px;\n  cursor: pointer;\n  -webkit-animation-fill-mode: forwards;\n          animation-fill-mode: forwards;\n  padding: 0;\n  outline: none;\n  position: relative;\n  z-index: 1;\n  display: inline-flex;\n  align-self: flex-start;\n  top: 2px;\n\n  /* Needed to prevent flickering at the end of replay button animation */\n  /* http://stackoverflow.com/questions/14729492/css3-transform-rotate-causing-1px-shift-in-chrome */\n  -webkit-backface-visibility: hidden;\n          backface-visibility: hidden;\n}\n\n.endslateButton-2wsgrHow img {\n  border-radius: 3px;\n  transition: box-shadow 0.3s;\n}\n\n.endslateButton-2wsgrHow:hover img {\n  box-shadow: 0 0 5px 1px rgba(255, 255, 255, 0.45);\n  transition: box-shadow 0.3s;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container-2Rhh3sWE",
	"endslateButton": "endslateButton-2wsgrHow"
};
module.exports = exports;
