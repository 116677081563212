import retry from 'async-retry';
import preact from 'preact';
import { Provider } from 'preact-redux';
import Logger from 'src/util/logger';

const logger = new Logger('vhs:plugin:cover');

const name = 'cover';

const render = (Cover, container, store, player) => {
  preact.render(
    <Provider store={store}>
      <Cover player={player} />
    </Provider>,
    container
  );
};

//
// API
//

export default {
  enable: false,
  name,
  proto: {
    async _initialize() {
      let Cover;

      try {
        Cover = await retry(
          () => import(/* webpackChunkName: "plugin-cover-vi" */ './component'),
          { retries: 5 }
        );
      } catch (error) {
        logger.error(
          'Error loading `plugin-cover.js`. If you think this is a configuration issue, try setting `VHS.config.publicPath`.\nhttps://github.com/nytimes/vhs/blob/master/doc/API.md#vhsconfigpublicpath'
        );

        throw error;
      }

      render(Cover.default, this._container, this._player.store, this._player);
    }
  }
};
