// @ts-check
import { logger } from './purr';

/**
 * @param {boolean} [didWaitForWindowOnloadEvent]
 * @returns {void}
 */
export default function logHybridConfigPurrDirectivesWarning(
  didWaitForWindowOnloadEvent = false
) {
  logger.warn(
    `${
      didWaitForWindowOnloadEvent
        ? 'Waited for `window.onload` event'
        : 'Page has already loaded'
    }, but the \`nyt-purr\` cookie is not set and \`window.config.PurrDirectives\` cannot be accessed`
  );
}
