import getWindow from './get-window';

const MOBILE_WIDTH_BREAKPOINT = 600;

const isSmartphone = () => {
  const win = getWindow();
  const viewportWidth = win && win.innerWidth;
  if (viewportWidth <= MOBILE_WIDTH_BREAKPOINT) {
    return true;
  }
  return false;
};

export default isSmartphone;
