// @ts-check
/* global webpackPluginVhsVersion */

import getUrlParameterByName from '../util/get-url-parameter-by-name';
import Logger from '../util/logger';
import isDesktop from '../util/is-desktop';
import getHost from '../util/get-host';
import getEnvFromHost from '../util/get-env-from-host';
import normalizeUnit from '../util/normalize-unit';
import getDeviceType from '../util/get-device-type';
import purr from '../util-nyt/purr';
import mediaTypes from './media-types';

/**
 * @typedef {'localhost'|'test'|'development'|'staging'|'production'|'preview'} Env
 */

/**
 * Supported values for ads. This is a subset of https://purr.tech.nyt.net/directives/#purr_adconfiguration_v3.
 * If `true`, we consider it to be the most permissive ads configuration.
 * @typedef {boolean|'full'|'npa'|'rdp'|'ltd'} Ads
 */

/**
 * Supported values for sources. This is to enable https://github.com/nytimes/vhs/blob/main/doc/OPTION.md#multiple-level-rendition-support
 * @typedef {Object} Source
 * @prop {number} width
 * @prop {number} height
 * @prop {string} url
 * @prop {string} [type]
 */

/** @typedef {Source[]} Sources */

/**
 * The type of audiovisual media represented by this player instance.
 * @see src/player/media-types.js
 *
 * @typedef {'audio' | 'video'} MediaType
 */

/**
 * @typedef {Object} Options
 * @prop {Ads} ads
 * @prop {boolean} analytics
 * @prop {boolean} api
 * @prop {boolean} autoplay
 * @prop {boolean} comscore
 * @prop {null|string|HTMLElement} container
 * @prop {boolean} cover
 * @prop {boolean|undefined} coverVi
 * @prop {undefined|null|boolean|{ fullscreen: boolean }} controls
 * @prop {boolean} debug
 * @prop {null|number} duration
 * @prop {Env} env
 * @prop {boolean} embedPoppableOnStart
 * @prop {Env} dataEnv
 * @prop {string} id
 * @prop {number|string} height
 * @prop {boolean} loop
 * @prop {boolean} loopingCover
 * @prop {MediaType} mediaType
 * @prop {boolean} muted
 * @prop {boolean} muteToggle
 * @prop {boolean} mutedAutoplay
 * @prop {boolean} nativeControls
 * @prop {null|function} onReady
 * @prop {false} playlist
 * @prop {boolean|'none'} preload
 * @prop {boolean} poster
 * @prop {string} ratio
 * @prop {string|null} shareUrl
 * @prop {Source|null} source
 * @prop {Sources|null} sources
 * @prop {string|null} src
 * @prop {number} timeUntilIdle
 * @prop {null|string} type
 * @prop {string} usage
 * @prop {boolean} userInitiatedNext
 * @prop {boolean} userInitiatedPlay
 * @prop {number|string} width
 * @prop {boolean} nytAnalytics
 * @prop {string} nytAnalyticsId
 * @prop {boolean} liveAutoplayOverlay
 * @prop {boolean} mutedAutoplayOverlay
 * @prop {boolean} pauseOtherPlayers
 * @prop {boolean} captionsDefaultOn
 * @prop {boolean} live
 */

const logger = new Logger('vhs:player');

/**
 * @param {Options} options
 * @returns {Options}
 */
export function normalizeAds(options) {
  if (options.ads === false) {
    return options;
  }

  // PURR cookie schema: https://purr.tech.nyt.net/purr-cookie-schema/#cookie-format
  const purrAdConfiguration = purr('PURR_AdConfiguration_v3');

  if (purrAdConfiguration === 'f') {
    options.ads = 'full';
    return options;
  } else if (purrAdConfiguration === 'r') {
    options.ads = 'rdp';
    return options;
  } else if (purrAdConfiguration === 'n') {
    options.ads = 'npa';
    return options;
  } else if (purrAdConfiguration === 'l') {
    options.ads = 'ltd';
    return options;
  } else if (purrAdConfiguration === 's') {
    options.ads = false;
    return options;
  }

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function normalizeControls(options) {
  const defaultControls = {
    // commenting this out, it seems to be outdated
    // fullscreen: !isHybridAndroid()
  };

  if (typeof options.controls === 'undefined') {
    options.controls = {
      ...defaultControls
    };

    return options;
  }

  if (Array.isArray(options.controls)) {
    options.controls = false;

    return options;
  }

  if (options.controls === null) {
    options.controls = false;

    return options;
  }

  if (typeof options.controls === 'object') {
    options.controls = {
      ...defaultControls,
      ...options.controls
    };

    return options;
  }

  options.controls = Boolean(options.controls);

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function normalizeRatio(options) {
  if (
    !options.ratio &&
    options.width &&
    options.height &&
    typeof options.width === 'number' &&
    typeof options.height === 'number'
  ) {
    options.ratio = `${options.width}:${options.height}`;
    return options;
  }

  if (!options.ratio && options.mediaType !== mediaTypes.AUDIO) {
    logger.warn('Received a falsy ratio. Setting it to default 16:9');
    options.ratio = '16:9';
  }
  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function normalizeCover(options) {
  if (options.poster !== undefined) {
    logger.warn(
      'Deprecation: options.poster was renamed to options.cover. Consider changing it.'
    );
  }

  if (options.coverVi !== undefined) {
    logger.warn(
      'Deprecation: options.coverVi was renamed to options.cover. Consider changing it.'
    );
  }

  options.cover = Boolean(options.cover || options.coverVi || options.poster);

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function normalizeWidthAndHeight(options) {
  if (options.width) {
    options.width = normalizeUnit(options.width);
  } else {
    options.width = '600px';
  }
  if (options.height) {
    options.height = normalizeUnit(options.height);
  } else if (options.mediaType !== mediaTypes.AUDIO) {
    options.height = '338px';
  }

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function enableNativeControlsForIeMobile(options) {
  return {
    ...options,
    nativeControls: getDeviceType() === 'ie_mobile'
  };
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function disableAdsForLoopingMedia(options) {
  // Do NOT use ads or comscore if looping,
  if (options.loop) {
    options.ads = false;
    options.comscore = false;
  }

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
export function normalizeAnalytics(options) {
  /**
   * Docs about PURR:
   * @see https://purr.tech.nyt.net/directives/#purr_acceptabletrackers
   * @see https://purr.tech.nyt.net/purr-cookie-schema/#cookie-format
   */
  const purrTrackersConfiguration = purr('PURR_AcceptableTrackers_v2');

  if (options.analytics === false) {
    options.comscore = false;
    options.nytAnalytics = false;

    return options;
  }

  //
  // Below logic is for fast PURR changes.
  //

  // 'c' === controllers
  if (purrTrackersConfiguration === 'c') {
    return options;
  }

  // 'p' === processors
  if (purrTrackersConfiguration === 'p') {
    options.comscore = false;

    return options;
  }

  // 'e' === essentials
  if (purrTrackersConfiguration === 'e') {
    options.comscore = false;

    return options;
  }

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function disableAutoplayForEmbedPoppableOnStart(options) {
  if (
    options.embedPoppableOnStart &&
    options.autoplay &&
    isDesktop() &&
    getUrlParameterByName('embedcode') === 'true'
  ) {
    options.autoplay = false;
  }

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function backwardsCompatibilityForMutedAutoplay(options) {
  if (options.mutedAutoplay) {
    options.autoplay = true;
    options.muted = true;
    options.muteToggle = true;
  }
  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function normalizeLive(options) {
  if (options.live) {
    options.ads = false;
    if (!options.liveAutoplayOverlay) {
      options.mutedAutoplayOverlay = true;
    }
  }

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function normalizeMutedAutoplayOverlay(options) {
  if (options.mutedAutoplayOverlay) {
    options.autoplay = true;
    options.muted = true;
    options.loop = true;
    options.pauseOtherPlayers = false;
    options.captionsDefaultOn = true;
    // Quick note to say that product has given permission to disable ads here for `mutedAutoplayOverlay`,
    // as the use case for these videos is primarily short form and in a way where it editorially makes sense to immediately play video.
    options.ads = false;
  }

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function env(options) {
  const validEnvironments = [
    'localhost',
    'test',
    'development',
    'staging',
    'production',
    'preview'
  ];
  const productionHosts = [
    'www.nytimes.com',
    'cooking.nytimes.com',
    'paidpost.nytimes.com',
    'brandedplaylist.nytimes.com'
  ];
  const host = getHost();
  // if env is not set, find a logical default from host
  if (options.env === undefined) {
    options.env = getEnvFromHost();
  } else if (
    validEnvironments.indexOf(options.env) === -1 ||
    productionHosts.indexOf(host) !== -1
  ) {
    // protect against VHS being set to stg on production
    // or being set to something other than the valid environment
    options.env = 'production';
  }

  if (options.dataEnv === undefined) {
    options.dataEnv = options.env;
  } else if (validEnvironments.indexOf(options.dataEnv) === -1) {
    options.dataEnv = 'production';
  }

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
function validate(options) {
  if (
    options.id === '' &&
    options.src === null &&
    options.sources === null &&
    options.source === null
  ) {
    throw new Error(
      'options.id, options.src, options.source or options.sources should be provided'
    );
  }

  if (options.container === null) {
    throw new Error('options.container should be provided');
  }

  if (options.api && options.nytAnalyticsId) {
    throw new Error(
      'options.nytAnalyticsId should not be used with a Scoop video'
    );
  } else if (!options.api && options.id) {
    logger.warn(
      `options.id is set, even though options.api is set to false. If you're using options.id solely for tracking a non-Scoop video, please consider using options.nytAnalyticsId instead: https://github.com/nytm/vhs3/blob/${webpackPluginVhsVersion}/doc/OPTIONS.md#nytAnalyticsId`
    );
  } else if (!options.api && !options.nytAnalyticsId) {
    logger.warn(
      `options.nytAnalyticsId should be provided for tracking a non-Scoop video: https://github.com/nytm/vhs3/blob/${webpackPluginVhsVersion}/doc/OPTIONS.md#nytAnalyticsId`
    );
  }

  return options;
}

/**
 * @param {Options} options
 * @returns {Options}
 */
export default function pipeline(options) {
  /** @type {Options} */
  let x = {
    ads: true,
    api: true,
    analytics: true,
    autoplay: false,
    container: null,
    cover: true,
    debug: false,
    duration: null,
    embedPoppableOnStart: false,
    id: '',
    loop: false,
    loopingCover: false,
    mediaType: mediaTypes.VIDEO,
    muted: false,
    nativeControls: false,
    nytAnalytics: true,
    nytAnalyticsId: '',
    onReady: null,
    playlist: false,
    preload: 'none',
    shareUrl: null,
    source: null,
    src: null,
    timeUntilIdle: 2000,
    type: null,
    usage: 'vanilla_js',
    userInitiatedNext: false,
    userInitiatedPlay: false,
    liveAutoplayOverlay: null,
    mutedAutoplayOverlay: false,
    live: false,
    ...options
  };
  x = normalizeControls(x);
  x = normalizeRatio(x);
  x = normalizeCover(x);
  x = normalizeWidthAndHeight(x);
  x = enableNativeControlsForIeMobile(x);
  x = disableAdsForLoopingMedia(x);
  x = disableAutoplayForEmbedPoppableOnStart(x);
  x = backwardsCompatibilityForMutedAutoplay(x);
  x = env(x);
  x = normalizeLive(x);
  x = normalizeMutedAutoplayOverlay(x);
  x = validate(x);

  return x;
}
