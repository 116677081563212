import preact, { Component } from 'preact'; // eslint-disable-line no-unused-vars
import { connect } from 'preact-redux';
import { bind } from 'monocle-decorators';
import Spinner from '../spinner';
import styles from './styles.css';

const PlayIcon = () => (
  <svg
    aria-hidden="true"
    id="playIcon"
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#363636"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 38L37 28L22 18V38Z"
    />
    <path
      d="M54.25 27.5C54.25 42.2736 42.2736 54.25 27.5 54.25C12.7264 54.25 0.75 42.2736 0.75 27.5C0.75 12.7264 12.7264 0.75 27.5 0.75C42.2736 0.75 54.25 12.7264 54.25 27.5Z"
      stroke="#DFDFDF"
      strokeWidth="1.5"
    />
  </svg>
);

const PauseIcon = () => (
  <svg
    aria-hidden="true"
    id="pauseIcon"
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.25 27.5C54.25 42.2736 42.2736 54.25 27.5 54.25C12.7264 54.25 0.75 42.2736 0.75 27.5C0.75 12.7264 12.7264 0.75 27.5 0.75C42.2736 0.75 54.25 12.7264 54.25 27.5Z"
      stroke="#DFDFDF"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 18H25V38H20V18Z"
      fill="#5A5A5A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 18H35V38H30V18Z"
      fill="#5A5A5A"
    />
  </svg>
);

const mapStateToProps = (state, ownProps) => ({
  forceHover: state.plugins.audioControls.forceHover,
  ...ownProps
});

@connect(mapStateToProps)
class Play extends Component {
  constructor() {
    super();
    this.state = {
      isHovered: false
    };
  }

  @bind
  handleMouseEnter() {
    this.setState({
      isHovered: true
    });
  }

  @bind
  handleMouseLeave() {
    this.setState({
      isHovered: false
    });
  }

  render({
    canPause,
    onClick,
    onMouseDown,
    onFocus,
    onBlur,
    isMediaBuffering,
    forceHover,
    theme,
    breakpoint,
    layout,
    cover
  }) {
    const isHovered = forceHover || this.state.isHovered;

    const themeClass = theme === 'light' ? styles.light : '';
    const bufferingClass = isMediaBuffering ? styles.buffering : '';
    const hoverClass = isHovered ? styles.hover : '';
    const breakpointClass = styles[breakpoint] || '';
    const layoutClass = styles[layout] || ''; // desktop vs mobile
    const coverClass = (cover && styles.cover) || '';

    if (canPause) {
      return (
        <button
          className={`${
            styles.pause
          } ${bufferingClass} ${themeClass} ${breakpointClass} ${layoutClass} ${coverClass}`}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onFocus={onFocus}
          onBlur={onBlur}
          title="Pause Audio"
          aria-label="Pause Audio"
        >
          <PauseIcon />
        </button>
      );
    }

    return (
      <button
        className={`${
          styles.play
        } ${bufferingClass} ${hoverClass} ${themeClass} ${breakpointClass} ${layoutClass} ${coverClass}`}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        title="Play Audio"
        aria-label="Play Audio"
      >
        <Circle
          show={
            !isMediaBuffering && (layout === 'mobile' || (cover && isHovered))
          }
        />
        <Spinner
          theme={theme}
          breakpoint={breakpoint}
          layout={layout}
          show={isMediaBuffering}
        />
        <PlayIcon />
      </button>
    );
  }
}

const Circle = ({ show }) => (
  <div className={`${styles.circle} ${(show && styles.show) || ''} `} />
);

export default Play;
