// @ts-check
import getCookie from '../../util/get-cookie';
import Logger from '../../util/logger';
// TODO: import from `@nyt/purr`
import {
  PURR_DIRECTIVE_COOKIE_VALUE_CHAR_MAP,
  PURR_DIRECTIVE_COOKIE_VALUES
} from './purrConstants';
import getPurrCookieValueFromHybridConfig from './getPurrCookieValueFromHybridConfig';
import logDerivedPurrCookieValue from './logDerivedPurrCookieValue';

export const logger = new Logger('vhs:purr');

/** @type {Set<import('./purrConstants').DirectiveName>} */
const deprecatedDirectives = new Set([
  'PURR_AcceptableTrackers',
  'PURR_AdConfiguration'
]);

/**
 * Returns value for PURR directive.
 *
 * More details about all PURR directives:
 * @see https://purr.tech.nyt.net/directives/#purr_acceptabletrackers
 * @see https://purr.tech.nyt.net/purr-cookie-schema/#cookie-format
 *
 * @param {import('./purrConstants').DirectiveName} directive
 * @param {string} [cookie]
 * @returns {string}
 */
export default function purr(directive, cookie = '') {
  if (!(directive in PURR_DIRECTIVE_COOKIE_VALUES)) {
    throw new TypeError(
      'Unknown PURR value. Visit https://purr.tech.nyt.net/purr-cookie-schema/#cookie-format for supported values.'
    );
  }

  if (deprecatedDirectives.has(directive)) {
    logger.warn(`PURR directive deprecated: ${directive}`);
  }

  const cookieIndex = PURR_DIRECTIVE_COOKIE_VALUE_CHAR_MAP.indexOf(directive);
  let purrCookie = getCookie('nyt-purr', cookie);
  /**
   * If we cannot get the `nyt-purr` cookie value from `cookie`, try deriving the cookie value
   * from a property of the Hybrid config (`window.config.PurrDirectives`).
   * @see https://jira.nyt.net/browse/UXCL-804
   */
  if (!purrCookie) {
    purrCookie = getPurrCookieValueFromHybridConfig();
    logDerivedPurrCookieValue(purrCookie);
  }

  if (cookieIndex > -1 && cookieIndex < purrCookie.length) {
    return purrCookie[cookieIndex];
  }

  return '';
}
