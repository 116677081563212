// @ts-check
/**
 * TODO: Move to `@nyt/purr`
 * @see https://github.com/kohlmannj/purr-js/blob/68ff239/src/constants.ts
 */

/**
 * Mappings of directives and their possible values to `nyt-purr` cookie character values.
 * @see https://purr.tech.nyt.net/purr-cookie-schema/#cookie-format
 */
export const PURR_DIRECTIVE_COOKIE_VALUES = {
  /** @deprecated */
  PURR_AcceptableTrackers: {
    controllers: 'c',
    processors: 'p'
  },
  /** @deprecated */
  PURR_AdConfiguration: {
    full: 'f',
    npa: 'n',
    'adluce-socrates': 's'
  },
  PURR_DataSaleOptOutUI: {
    hide: 'h',
    show: 's'
  },
  PURR_DataProcessingConsentUI: {
    hide: 'h',
    show: 's'
  },
  PURR_AcceptableTrackers_v2: {
    controllers: 'c',
    processors: 'p',
    essentials: 'e'
  },
  PURR_AdConfiguration_v2: {
    full: 'f',
    rdp: 'r',
    npa: 'n',
    adluce: 'a',
    'adluce-socrates': 's'
  },
  PURR_DataProcessingPreferenceUI: {
    hide: 'h',
    'allow-opt-out': 'o',
    'allow-opt-in': 'i',
    'allow-opt-in-or-out': 'a'
  },
  PURR_DataSaleOptOutUI_v2: {
    hide: 'h',
    show: 's',
    'show-opted-out': 'o'
  },
  PURR_CaliforniaNoticesUI: {
    hide: 'h',
    show: 's'
  },
  PURR_EmailMarketingOptInUI: {
    checked: 'c',
    unchecked: 'u'
  },
  PURR_DeleteIPAddress: {
    delete: 'd',
    keep: 'k'
  },
  PURR_AdConfiguration_v3: {
    full: 'f',
    rdp: 'r',
    npa: 'n',
    'adluce-socrates': 's',
    ltd: 'l'
  }
};

/**
 * An ordered array of directive names, which we can use to determine which character index
 * of the `nyt-purr` cookie maps to which directive, and then, which possible values.
 * @see https://purr.tech.nyt.net/purr-cookie-schema/#cookie-format
 */
export const PURR_DIRECTIVE_COOKIE_VALUE_CHAR_MAP = [
  'PURR_AcceptableTrackers',
  'PURR_AdConfiguration',
  'PURR_DataSaleOptOutUI',
  'PURR_DataProcessingConsentUI',
  'PURR_AcceptableTrackers_v2',
  'PURR_AdConfiguration_v2',
  'PURR_DataProcessingPreferenceUI',
  'PURR_DataSaleOptOutUI_v2',
  'PURR_CaliforniaNoticesUI',
  'PURR_EmailMarketingOptInUI',
  'PURR_DeleteIPAddress',
  'PURR_AdConfiguration_v3'
];
