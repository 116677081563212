import screenfull from 'screenfull';
import Logger from 'src/util/logger';
import apiEvents from 'src/player/events';
import reducer from './reducer';
import actions from './actions';

const deprecationLogger = new Logger('vhs');

export default {
  name: 'fullscreen',
  enable: true,
  proto: {
    state: {},
    unsubscriber: undefined,

    _ready() {
      this.mapGlobalToLocalState();
      this.subscribeToStore();

      // Bind instance methods that we pass to other functions as callbacks
      this.onFullscreenChange = this.onFullscreenChange.bind(this);
      this.fullscreen = this.fullscreen.bind(this);
      this.toggleFullscreen = this.toggleFullscreen.bind(this);
      this.isFullscreen = this.isFullscreen.bind(this);
      this.checkForFullscreenApi = this.checkForFullscreenApi.bind(this);

      this.checkForFullscreenApi();
      this.restoreScrollOnExitFullscreen();
      this.getPlayState();
      this.handlePlayStateOnExit();
    },

    /**
     * Check for an available fullscreen API, and afterwards, dispatch a state
     * store update to set `plugins.fullscreen.api`. If there is an available
     * fullscreen API at that time, additionally call `this.decoratePlayer()`
     * and `this.bindEventListeners()`.
     *
     * ## About the `fallbackToOnLoadedMetadata` parameter
     * In Safari on iOS, `this._player.video.webkitSupportsFullscreen` may be
     * `false` when `_ready()` is called. In this case, it's highly that we
     * don't yet have any video metadata loaded (`this._player.video.readyState === 0`);
     * Apple's own docs even mention this possibility.
     *
     * In this case, we can check again for fullscreen support when the `<video>`
     * element's `loadedmetadata` event fires.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
     * @see https://developer.apple.com/documentation/webkitjs/htmlvideoelement/1628805-webkitsupportsfullscreen
     */
    checkForFullscreenApi() {
      let api = false;
      if (!this.state.isMedia360) {
        if (screenfull.isEnabled) {
          api = 'standard';
        } else if (this._player.video.webkitSupportsFullscreen) {
          api = 'webkit-video-only';
        } else if (this._player.video.readyState === 0) {
          // Bail out and check again once video metadata has loaded
          this._player.on(
            apiEvents.LOADED_METADATA,
            this.checkForFullscreenApi
          );
          return;
        }
      }

      // Stop listening to the `loadedmetadata` event
      this._player.off(apiEvents.LOADED_METADATA, this.checkForFullscreenApi);

      this._player.store.dispatch(actions.fullscreenApi(api));

      if (!this.state.api) return;

      this.decoratePlayer();
      this.bindEventListeners();
    },

    // saves the current window scroll before entering fullscreen
    saveScrollValue() {
      this.state.windowScroll = window.scrollY;
    },

    // restore the window scroll to whatever value it was before entering fullscreen; useful for preventing the scroll from resetting to 0 when exiting full screen.
    restoreScrollOnExitFullscreen() {
      this._player.on(apiEvents.EXIT_FULLSCREEN, () => {
        if (this.state.windowScroll) {
          window.scroll(0, this.state.windowScroll);
        }
      });
    },

    getPlayState() {
      ['PLAY', 'PAUSE'].forEach(event => {
        this._player.on(apiEvents[event], () => {
          const isOnFullScreen = this._player.video.webkitDisplayingFullscreen;
          if (isOnFullScreen) {
            this.state.isPlaying = event === 'PLAY';
          }
        });
      });
    },

    // Note: Overwriting the default pause for IOS >= 14 when exit fullscreen
    handlePlayStateOnExit() {
      this._player.on(apiEvents.EXIT_FULLSCREEN, () => {
        if (this.state.isPlaying) {
          if (this.state.exitPause) {
            this._player.pause();
          } else {
            setTimeout(() => {
              this._player.play();
            }, 500);
          }
        }
      });
    },

    _destroy() {
      this.unsubscribe();

      if (!this.state.api) return;

      this.undecoratePlayer();
      this.unbindEventListeners();
    },

    decoratePlayer() {
      this._player.fullscreen = this.fullscreen;
      this._player.toggleFullscreen = this.toggleFullscreen;
      this._player.isFullscreen = this.isFullscreen;
    },

    undecoratePlayer() {
      this._player.fullscreen = undefined;
      this._player.toggleFullscreen = undefined;
      this._player.isFullscreen = undefined;
    },

    bindEventListeners() {
      if (this.state.api === 'standard') {
        screenfull.on('change', this.onFullscreenChange);
      } else if (this.state.api === 'webkit-video-only') {
        ['webkitbeginfullscreen', 'webkitendfullscreen'].forEach(eventName => {
          this._player.video.addEventListener(
            eventName,
            this.onFullscreenChange
          );
        });
      }
    },

    unbindEventListeners() {
      if (this.state.api === 'standard') {
        screenfull.off('change', this.onFullscreenChange);
      } else if (this.state.api === 'webkit-video-only') {
        ['webkitbeginfullscreen', 'webkitendfullscreen'].forEach(eventName => {
          this._player.video.removeEventListener(
            eventName,
            this.onFullscreenChange
          );
        });
      }
    },

    didEnterFullscreen() {
      if (this.state.api === 'standard') {
        return screenfull.element === this._player.container;
      }
      if (this.state.api === 'webkit-video-only') {
        return this._player.video.webkitPresentationMode === 'fullscreen';
      }
      return false;
    },

    /** Must be bound to the plugin instance in `_ready()` */
    onFullscreenChange() {
      const store = this._player.store;
      const { options, video } = this._player;
      // If it was fullscreen
      if (this.state.isFullscreen) {
        // re-apply cropping
        if (options?.cropVertical) {
          video.style.height = 'auto';
        }
        this._player.emit(apiEvents.EXIT_FULLSCREEN);
        store.dispatch(actions.handleFullscreenChange(false));
      } else if (this.didEnterFullscreen()) {
        // stop cropping if we're fullscreen
        if (options?.cropVertical) {
          video.style.height = '100%';
        }
        this._player.emit(apiEvents.GO_FULLSCREEN);
        store.dispatch(actions.handleFullscreenChange(true));
        const playerState = store.getState();

        // Note: Overwriting the default pause for IOS >= 14 when exit fullscreen
        if (playerState?.player?.isIphone) {
          this.state.isPlaying = playerState?.player?.isMediaPlaying;
        }
        if (this.state.expandUnmutePlay) {
          this._player.play();
          this._player.mute(false);
        }
      }
    },

    /** Must be bound to the plugin instance in `_ready()` */
    fullscreen(goFullscreen) {
      if (goFullscreen) {
        this.saveScrollValue();
        if (this.state.api === 'standard') {
          screenfull.request(this._player.container);
        } else if (this.state.api === 'webkit-video-only') {
          this._player.video.webkitEnterFullscreen();
        }
      } else if (this.state.api === 'standard') {
        screenfull.exit();
      } else if (this.state.api === 'webkit-video-only') {
        this._player.video.webkitExitFullscreen();
      }
    },

    /** Must be bound to the plugin instance in `_ready()` */
    toggleFullscreen() {
      this.fullscreen(!this.state.isFullscreen);
    },

    /** Must be bound to the plugin instance in `_ready()` */
    isFullscreen() {
      deprecationLogger.warn('`player.isFullscreen` is deprecated');
      return this.state.isFullscreen;
    },

    mapGlobalToLocalState() {
      const globalState = this._player.store.getState();
      const isPlaying = globalState.player?.isMediaPlaying;
      const exitPause = globalState.player?.options?.controls?.unExpandPause;
      const expandUnmutePlay =
        globalState?.player?.options?.controls?.expandUnmutePlay;

      this.state = {
        ...this.state,
        ...globalState.plugins.fullscreen,
        exitPause,
        expandUnmutePlay,
        isMedia360: globalState.player.media.is360,
        isPlaying
      };
    },

    subscribeToStore() {
      this.unsubscriber = this._player.store.subscribe(() =>
        this.mapGlobalToLocalState()
      );
    },

    unsubscribe() {
      if (this.unsubscriber && typeof this.unsubscriber === 'function') {
        this.unsubscriber();
      }
    },

    reducer() {
      return { fullscreen: reducer };
    }
  }
};
