// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!src/stylesheet/font-families.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, "@-webkit-keyframes fade-in-1mDrHEhL {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fade-in-1mDrHEhL {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.overlay-19fDZ37B {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  /* Matches `$zindex-controls` in `src/stylesheet/z-index.scss` */\n  z-index: 40;\n  -webkit-animation: fade-in-1mDrHEhL 500ms ease-out forwards;\n          animation: fade-in-1mDrHEhL 500ms ease-out forwards;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: transparent;\n  border: none;\n}\n\n.unmuteCta-2JotPa9b {\n  color: white;\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 16px;\n  display: flex;\n  align-items: center;\n  background-color: #0000006b;\n  padding: 10px 15px;\n  border-radius: 3px;\n  display: flex;\n  align-items: center;\n}\n\n.cta-2ofTjDz9 {\n  text-transform: uppercase;\n  margin-top: 2px;\n}\n\n.volume-1_GozD1r {\n  margin-right: 10px;\n  display: flex;\n}\n\n.volume-1_GozD1r svg {\n  fill: white;\n}\n\n.volume-1_GozD1r svg path {\n  stroke: white;\n}\n\n.liveBadge-2VLT13-_ {\n  display: inline-block;\n  font-size: 14px;\n  font-weight: 700;\n  padding: 4px 8px 3px;\n  color: #fff;\n  background-color: #d11e26;\n  border-radius: 3px;\n  text-transform: uppercase;\n  position: absolute;\n  top: 15px;\n  right: 15px;\n}\n", ""]);
// Exports
exports.locals = {
	"overlay": "overlay-19fDZ37B",
	"fade-in": "fade-in-1mDrHEhL",
	"unmuteCta": "unmuteCta-2JotPa9b " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["franklin"] + "",
	"cta": "cta-2ofTjDz9",
	"volume": "volume-1_GozD1r",
	"liveBadge": "liveBadge-2VLT13-_ " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["franklin"] + ""
};
module.exports = exports;
