import clone from 'clone';

function mapStateToProps(state, prevProps) {
  const api = state?.plugins?.fullscreen?.api;
  const isFullscreen = state?.plugins?.fullscreen?.isFullscreen;
  return { ...prevProps, api, isFullscreen };
}

export default {
  name: 'autoExitFullscreenOnEnded',
  enable: true,
  proto: {
    props: {},
    unsubscriber: undefined,

    _initialize() {
      this.props = mapStateToProps(this._player.store.getState(), {});
      this.subscribeToStore();
    },

    _destroy() {
      this.unsubscribe();
    },

    subscribeToStore() {
      this.unsubscriber = this._player.store.subscribe(() => {
        this.props = mapStateToProps(
          this._player.store.getState(),
          clone(this.props)
        );
      });
    },

    unsubscribe() {
      if (this.unsubscriber && typeof this.unsubscriber === 'function') {
        this.unsubscriber();
      }
    },

    _ended() {
      if (
        this.props.isFullscreen &&
        this.props.api === 'webkit-video-only' &&
        typeof this._player.fullscreen === 'function'
      ) {
        this._player.fullscreen(false);
      }
    }
  }
};
