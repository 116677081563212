import adCountdown from './ad-countdown';
import audioControls from './audio-controls';
import audioLockForNonSubs from './audio-lock-for-non-subs';
import autoExitFullscreenOnEnded from './auto-exit-fullscreen-on-ended';
import autoplay from './autoplay';
import browserNotSupported from './browser-not-supported';
import buffering from './buffering';
import captions from './captions';
import clickVideoToTogglePlay from './click-video-to-toggle-play';
import comscore from './comscore/comscore';
import contextualMenu from './contextual-menu';
import controls from './controls';
/**
 * controlsOverlay deprecated with VISUALS-794
 * @see https://jira.nyt.net/browse/VISUALS-794
 */
// import controlsOverlay from './controls-overlay';
import copySlate from './copy-slate';
import cover from './cover';
import embedPoppableOnStart from './embed-poppable-on-start';
import endSlate from './end-slate';
import eventErrorDispatcher from './event-error-dispatcher';
import errorSlate from './error-slate';
import fullscreen from './fullscreen';
import fullscreenOnLandscape from './fullscreen-on-landscape';
import liveAutoplayOverlay from './live-autoplay-overlay';
import mutedAutoplayOverlay from './muted-autoplay-overlay';
import muteToggle from './mute-toggle';
import nytAnalytics from './nyt-analytics';
import orientation from './orientation';
import pauseOtherPlayer from './pause-other-players';
import persistentShareButton from './persistent-share-button';
import playNext from './play-next/play-next';
import responsive from './responsive';
import sharetools from './sharetools';
import spacebarToggle from './spacebar-toggle';
import timecodePopup from './timecode-popup';
import transcript from './transcript';
import viewportIntersectionObserver from './viewport-intersection-observer';

export default [
  eventErrorDispatcher, // Always first
  adCountdown,
  audioControls,
  audioLockForNonSubs,
  autoExitFullscreenOnEnded,
  autoplay,
  browserNotSupported,
  buffering,
  captions,
  clickVideoToTogglePlay,
  comscore,
  contextualMenu,
  controls,
  /**
   * controlsOverlay deprecated with VISUALS-794
   * @see https://jira.nyt.net/browse/VISUALS-794
   */
  // controlsOverlay,
  copySlate,
  cover,
  embedPoppableOnStart,
  endSlate,
  errorSlate,
  fullscreen,
  fullscreenOnLandscape,
  liveAutoplayOverlay,
  mutedAutoplayOverlay,
  muteToggle,
  nytAnalytics,
  orientation,
  pauseOtherPlayer,
  persistentShareButton,
  playNext,
  responsive,
  sharetools,
  spacebarToggle,
  timecodePopup,
  transcript,
  viewportIntersectionObserver
];
