import preact from 'preact';
import Logger from 'src/util/logger';
import VideoLarge from './large';
import VideoSmall from './small';
import { MOBILE_LAYOUT } from '../../../end-slate';

const logger = new Logger('vhs:plugin:end-slate');

class Video extends preact.Component {
  handleCountdownFinished() {
    this.props.animateCurtainAndPlay(this.props.id);
  }

  canAutoplay() {
    return this.props.autoplay && this.props.adSensitivity !== 'TRAGEDY';
  }

  componentDidUpdate() {
    if (this.props.autoplay && this.props.adSensitivity === 'TRAGEDY') {
      logger.log("Aborting autoplay since it's a tragedy tagged video.");
    }
  }

  render() {
    if (this.props.presentation === MOBILE_LAYOUT) {
      return (
        <VideoSmall
          {...this.props}
          autoplay={this.canAutoplay()}
          onCountdownFinished={() => this.handleCountdownFinished()}
        />
      );
    }

    switch (this.props.breakpoint) {
      case 'xxl':
      case 'xl':
      case 'l':
        return (
          <VideoLarge
            {...this.props}
            autoplay={this.canAutoplay()}
            onCountdownFinished={() => this.handleCountdownFinished()}
          />
        );
      default:
        return (
          <VideoSmall
            {...this.props}
            autoplay={this.canAutoplay()}
            onCountdownFinished={() => this.handleCountdownFinished()}
          />
        );
    }
  }
}

export default Video;
