// @ts-check
/**
 * Whether or not the given argument (the `window` global by default) contains an object-type
 * `window.config.PurrDirectives`, from which we'll attempt to read the PURR directives.
 * @see https://jira.nyt.net/browse/UXCL-804
 *
 * @param {Window} [win]
 * @returns {boolean}
 */
export default function canAccessHybridConfigPurrDirectives(win = window) {
  return (
    typeof win === 'object' &&
    'config' in win &&
    !!win.config &&
    'PurrDirectives' in win.config &&
    !!win.config.PurrDirectives &&
    typeof win.config.PurrDirectives === 'object'
  );
}
