import { types } from './actions';

const initialState = {
  isFullscreen: false,
  api: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.handleFullscreenChange:
      return {
        ...state,
        ...action.payload
      };
    case types.fullscreenApi:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
