module.exports = function anonymous(locals, escape, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = utils.escapeXML(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escape = escape || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
        .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<ul class=\"<%= pluginClassName %>\">\n<% items.forEach(function(item){ %>\n  <li class=\"<%= item.isVersion ? 'version ' : '' %><%= item.isActive ? 'active' : '' %>\"><%= item.text %></li>\n<% }) %>\n</ul>\n"
  , __filename = "src/plugins/contextual-menu/template.html";
try {
  var __output = [], __append = __output.push.bind(__output);
  with (locals || {}) {
    ; __append("<ul class=\"")
    ; __append(escape( pluginClassName ))
    ; __append("\">\n")
    ; __line = 2
    ;  items.forEach(function(item){ 
    ; __append("\n  <li class=\"")
    ; __line = 3
    ; __append(escape( item.isVersion ? 'version ' : '' ))
    ; __append(escape( item.isActive ? 'active' : '' ))
    ; __append("\">")
    ; __append(escape( item.text ))
    ; __append("</li>\n")
    ; __line = 4
    ;  }) 
    ; __append("\n</ul>\n")
    ; __line = 6
  }
  return __output.join("");
} catch (e) {
  rethrow(e, __lines, __filename, __line);
}

}