// @ts-check
import isNativeHlsSupported from 'src/util/is-native-hls-supported';
import isSafari from 'src/util/is-safari';
import getSupportedType from 'src/util/get-supported-type';
import isDesktop from 'src/util/is-desktop';
import isHlsjsSupported from 'src/util/is-hlsjs-supported';
import isSmartphone from 'src/util/is-smartphone';

/**
 * Get the closest media Source / rendition to the target dimensions
 *
 * @param {import('src/player/options-pipeline').Source[]} renditions Array of Sources / renditions
 * @param {number} target The target number to be closest to
 * @return {import('src/player/options-pipeline').Source | null} the closest Source to the target height
 */
const closest = (renditions, target) => {
  if (renditions.length === 0) return null;
  let smallest = renditions[renditions.length - 1];
  const prop = smallest.width < smallest.height ? 'width' : 'height';
  let smallestDiff = Math.abs(smallest[prop] - target);

  renditions.forEach(rendition => {
    const diff = Math.abs(rendition[prop] - target);
    if (diff < smallestDiff) {
      smallest = rendition;
      smallestDiff = diff;
    }
  });
  return smallest;
};

/**
 * Get an array of renditions with just one file type extension
 *
 * @param {String} type The file extension of the video to filter on i.e. 'mp4', 'webm', 'm3u8'
 * @param {import('src/player/options-pipeline').Source[]} renditions An array of rendition objects
 * @returns {import('src/player/options-pipeline').Source[]} Returns an array of renditions of the specified extension i.e. mp4, webm
 */
const filterByFileType = (type, renditions) => {
  /** @type {Record<string, boolean>} */
  const heights = {};
  /** @type {import('src/player/options-pipeline').Source[]} */
  const arr = [];

  if (!Array.isArray(renditions)) {
    return arr;
  }

  renditions.forEach(rendition => {
    if (
      !(rendition.height in heights) &&
      rendition &&
      rendition.url &&
      rendition.url.indexOf(type) > -1
    ) {
      arr.push(rendition);
      heights[rendition.height] = true;
    }
  });

  return arr;
};

/**
 * Select the appropriate rendition for the browser and platform
 *
 * @param {import('src/player/options-pipeline').Source[]} renditions An array of Source / rendition objects
 * @param {Boolean} is360 Whether or not a video is 360
 * @returns {String}
 */
const selectVideoRendition = (renditions, is360) => {
  let selectedRenditionUrl = '';

  const isPhone = isSmartphone();
  const isHlsSupportedOnTheDevice =
    (isNativeHlsSupported() || isHlsjsSupported()) && !(is360 && isSafari());
  if (isHlsSupportedOnTheDevice) {
    // Thing we want to do with new HLS rendition selection logic:
    // - Maybe: When on mobile, prioritize "mobile" `hlsfmp4` and `hls` renditions over non-mobile ren
    // - Prioritize `hlsfmp4` renditions over `hls` renditions (order-independent)
    // - Ensure that HLS rendition URLs end with `.m3u8`

    const desktopHlsRendition = renditions.find(
      rendition =>
        rendition.type === 'hls' ||
        rendition.type === 'hlsfmp4' ||
        // if the rendition does not have a type, we want to pick it based on the file extension
        // but we don't want to pick the mobile version of hls if we are on desktop
        // this also handles edge case where sources are provided without a type attribute
        (rendition.url &&
          rendition.url.indexOf('.m3u8') !== -1 &&
          rendition.type !== 'hls_mobile' &&
          rendition.type !== 'hlsfmp4_mobile')
    );

    if (desktopHlsRendition) {
      selectedRenditionUrl = desktopHlsRendition.url;
    }
    if (isPhone) {
      const mobileHLSRendition = renditions.find(
        rendition =>
          rendition.type === 'hls_mobile' || rendition.type === 'hlsfmp4_mobile'
      );
      // If there's no mobile hls provided via the type. Pick any other hls, even desktop
      const fallbackHLSRendition = renditions.find(
        rendition => rendition.url && rendition.url.indexOf('.m3u8') !== -1
      );
      if (mobileHLSRendition || fallbackHLSRendition) {
        selectedRenditionUrl = (mobileHLSRendition || fallbackHLSRendition).url;
      }
    }
  }

  // If the device doesn't support HLS or HlsJS, or `renditions` does not contain an HLS rendition...
  if (selectedRenditionUrl === '') {
    // fallback progressive download
    // Also keeps support for when initializing the player with a list of non-HLS sources,
    // see doc/OPTIONS.md#source eg for Interactives
    const targetHeight = isDesktop() || is360 ? 720 : 360;
    const filteredRenditions = filterByFileType(
      `.${getSupportedType()}`,
      renditions
    );
    filteredRenditions.sort((a, b) => a.height - b.height);
    let nonHlsRendition = closest(filteredRenditions, targetHeight);

    if (isPhone) {
      const filteredMobileRenditions = filterByFileType(
        `.${getSupportedType()}`,
        renditions.filter(
          rendition =>
            rendition.url &&
            rendition.type &&
            rendition.type.indexOf('hls') === -1
        )
      );
      if (filteredMobileRenditions.length) {
        nonHlsRendition = closest(filteredMobileRenditions, targetHeight);
      }
    }

    if (nonHlsRendition && nonHlsRendition.url) {
      // If there is NO HLS rendition use this rendition
      selectedRenditionUrl = nonHlsRendition.url;
    }
  }

  return selectedRenditionUrl;
};

export default selectVideoRendition;
