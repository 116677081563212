const getShareUrl = state => {
  const isEmbeddedOnly = state?.player?.media?.isEmbeddedOnly;
  const mediaUrl = state?.player?.media?.url;
  const relatedUrl = state?.player?.media?.related?.[0]?.url;
  const shareUrl = state?.player?.options?.shareUrl;

  return (
    shareUrl ||
    (isEmbeddedOnly && relatedUrl) ||
    (!isEmbeddedOnly && mediaUrl) ||
    ''
  );
};

export default getShareUrl;
