import preact from 'preact';
import { connect } from 'preact-redux';
import { bind } from 'monocle-decorators';
import Volume from 'src/plugins/audio-controls/component/volume';
import styles from './styles.css';

const mapStateToProps = (state, ownProps) => ({
  controlsActive: state?.player?.isActive,
  isAdActive: state?.player?.isAdActive,
  isMediaMuted: state?.player?.isMediaMuted,
  ...ownProps
});

@connect(mapStateToProps)
class LiveAutoplayOverlay extends preact.Component {
  constructor() {
    super();
    this.state = {
      clicked: false
    };
  }

  @bind
  onClick() {
    this.setState({ clicked: true });
    this.props.player.mute(false);
    this.props.player.emit('plugin:controls:reset');
    this.props.player.emit('plugin:controls:enable');
    /**
     * controlsOverlay deprecated with VISUALS-794
     * @see https://jira.nyt.net/browse/VISUALS-794
     */
    // this.props.player.emit('plugin:controlsOverlay:enable');
  }

  render() {
    const hasInteracted = this.state.clicked;
    const isLive =
      this.props.player &&
      this.props.player.options &&
      this.props.player.options.live;

    if (this.props.isAdActive) return null;
    if (hasInteracted && this.props.controlsActive) return null;
    if (hasInteracted) return null;

    return (
      <button
        title="Tap To Unmute"
        className={styles.overlay}
        onClick={this.onClick}
      >
        {isLive && <div className={styles.liveBadge}>Live</div>}
        <div className={styles.unmuteCta}>
          <div className={styles.volume}>
            <Volume isMediaMuted />
          </div>
          <span className={styles.cta}>Tap To Unmute</span>
        </div>
      </button>
    );
  }
}

export default LiveAutoplayOverlay;
