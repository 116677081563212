// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.fast-forward-1JbaMpeL svg {\n  fill:  rgba(0,0,0,1);\n  transition: fill 0.25s, transform 300ms;\n}\n\n.desktop-Q_FP0IBX.fast-forward-1JbaMpeL:hover svg,\n.desktop-Q_FP0IBX.fast-forward-1JbaMpeL:focus svg {\n  fill: rgba(0,0,0,.8);\n}\n\n.light-1gwOs_oX.fast-forward-1JbaMpeL svg {\n  fill: #ffffff;\n}\n\n.desktop-Q_FP0IBX.light-1gwOs_oX.fast-forward-1JbaMpeL:hover svg,\n.desktop-Q_FP0IBX.light-1gwOs_oX.fast-forward-1JbaMpeL:focus svg{\n  fill: rgba(255, 255, 255, 0.8);\n}\n\n/* on safari the svg is cropped,\n * so increase the container's size as a hacky fix */\n.fast-forward-1JbaMpeL {\n  width: 31px;\n  height: 31px;\n}\n.fast-forward-1JbaMpeL svg {\n  width: 30px;\n  height: 30px;\n}\n\n/* disable these buttons */\n.fast-forward-1JbaMpeL:disabled svg {\n  fill: rgba(68, 68, 68, 0.2);\n}\n\n.light-1gwOs_oX.fast-forward-1JbaMpeL:disabled svg {\n  fill: rgba(255, 255, 255, 0.2);\n}\n\n/* temp fix, without .desktop these disabled rules will not apply on desktop */\n.desktop-Q_FP0IBX.fast-forward-1JbaMpeL:disabled svg {\n  fill: rgba(68, 68, 68, 0.2);\n}\n\n.desktop-Q_FP0IBX.light-1gwOs_oX.fast-forward-1JbaMpeL:disabled svg {\n  fill: rgba(255, 255, 255, 0.2);\n}\n", ""]);
// Exports
exports.locals = {
	"fast-forward": "fast-forward-1JbaMpeL",
	"desktop": "desktop-Q_FP0IBX",
	"light": "light-1gwOs_oX"
};
module.exports = exports;
