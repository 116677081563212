// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* container */\n\n.container-2jYqXHjM {\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  z-index: 1;\n}\n\n/* curtain */\n\n.curtain-HWq2LaMu {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  background-color: black;\n  -webkit-animation-timing-function: ease-in;\n          animation-timing-function: ease-in;\n  -webkit-animation-fill-mode: forwards;\n          animation-fill-mode: forwards;\n  -webkit-animation-duration: 0.5s;\n          animation-duration: 0.5s;\n  opacity: 0;\n}\n\n.curtain-HWq2LaMu.fadeIn-3ytW3vaF {\n  -webkit-animation-name: fadeIn-3ytW3vaF;\n          animation-name: fadeIn-3ytW3vaF;\n}\n\n.curtain-HWq2LaMu.fadeOut-2jmhAn19 {\n  -webkit-animation-name: fadeOut-2jmhAn19;\n          animation-name: fadeOut-2jmhAn19;\n}\n\n/* animations */\n\n@-webkit-keyframes fadeIn-3ytW3vaF {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeIn-3ytW3vaF {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n@-webkit-keyframes fadeOut-2jmhAn19 {\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n@keyframes fadeOut-2jmhAn19 {\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container-2jYqXHjM",
	"curtain": "curtain-HWq2LaMu",
	"fadeIn": "fadeIn-3ytW3vaF",
	"fadeOut": "fadeOut-2jmhAn19"
};
module.exports = exports;
