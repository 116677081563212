// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.rewind-3Nwv_yWj svg {\n  fill:  rgba(0,0,0,1);\n  transition: fill 0.25s, transform 300ms;\n}\n\n.desktop-NN-EuJBl.rewind-3Nwv_yWj:hover svg,\n.desktop-NN-EuJBl.rewind-3Nwv_yWj:focus svg {\n  fill: rgba(0,0,0,.8);\n}\n\n.light-3w4u-m8G.rewind-3Nwv_yWj svg {\n  fill: #ffffff;\n}\n\n.desktop-NN-EuJBl.light-3w4u-m8G.rewind-3Nwv_yWj:hover svg,\n.desktop-NN-EuJBl.light-3w4u-m8G.rewind-3Nwv_yWj:focus svg {\n  fill: rgba(255, 255, 255, 0.8);\n}\n\n/* on safari the svg is cropped,\n * so increase the container's size as a hacky fix */\n.rewind-3Nwv_yWj {\n  width: 56px;\n  height: 56px;\n}\n.rewind-3Nwv_yWj svg{\n  width: 55px;\n  height: 55px;\n}\n.rewind-3Nwv_yWj svg path {\n  fill: rgb(223, 223, 223);\n}\n\n.rewindInnerPath-1PVzZnGA {\n  transform: scale(1.8) translate(7px, 13px);\n}\n\n.rewindOuterPath-3dKGwdvo {\n  transform: scale(1.8);\n}\n\n/* disable these buttons */\n.rewind-3Nwv_yWj:disabled svg {\n  fill: rgba(68, 68, 68, 0.2);\n}\n\n.light-3w4u-m8G.rewind-3Nwv_yWj:disabled svg {\n  fill: rgba(255, 255, 255, 0.2);\n}\n\n/* temp fix, without .desktop these disabled rules will not apply on desktop */\n.desktop-NN-EuJBl.rewind-3Nwv_yWj:disabled svg {\n  fill: rgba(68, 68, 68, 0.2);\n}\n\n.desktop-NN-EuJBl.light-3w4u-m8G.rewind-3Nwv_yWj:disabled svg {\n  fill: rgba(255, 255, 255, 0.2);\n}\n", ""]);
// Exports
exports.locals = {
	"rewind": "rewind-3Nwv_yWj",
	"desktop": "desktop-NN-EuJBl",
	"light": "light-3w4u-m8G",
	"rewindInnerPath": "rewindInnerPath-1PVzZnGA",
	"rewindOuterPath": "rewindOuterPath-3dKGwdvo"
};
module.exports = exports;
