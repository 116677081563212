import preact from 'preact';
import formatDuration from 'src/util/format-duration';
import styles from './styles.css';
import Video from '../large/video';
import RecommendationLarge from '../large';

class RecommendationListSmall extends preact.Component {
  getPosterImg(...args) {
    return RecommendationLarge.prototype.getPosterImg.apply(this, args);
  }

  get backgroundStyle() {
    return {
      left: `-${this.props.gridPadding}px`,
      width: `calc(100% + ${this.props.gridPadding * 2}px)`
    };
  }

  get containerStyle() {
    const height = this.props.breakpoint === 'm' ? 70 : 60;

    return {
      paddingBottom: `${this.props.gridPadding}px`,
      marginBottom: `-${this.props.gridPadding}px`,
      height: `calc(${height}px - ${this.props.gridPadding}px)`
    };
  }

  get containerCssClass() {
    const classes = [styles.container, styles[this.props.breakpoint]];

    return classes.join(' ');
  }

  handleClick() {
    this.props.animateCurtainAndPlay(this.props.recommendedVideos[0].id);
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.handleClick(event);
    }
  }

  render() {
    return (
      <div
        className={this.containerCssClass}
        style={this.containerStyle}
        onClick={() => this.handleClick()}
        onKeyDown={event => this.handleKeyDown(event)}
        role="button"
        tabIndex={0}
      >
        <div className={styles.background} style={this.backgroundStyle} />
        <span className={styles.upNextText}>Up Next</span>
        <span className={styles.title}>
          {this.props.recommendedVideos[0].headline}
        </span>
        <Video
          id={this.props.recommendedVideos[0].id}
          posterImg={this.getPosterImg(0)}
          duration={formatDuration(
            this.props.recommendedVideos[0].duration / 1000
          )}
          title={this.props.recommendedVideos[0].headline}
          totalSize={this.numberOfRecommendedVideos}
          autoplay
          adSensitivity={this.props.recommendedVideos[0].adSensitivity}
          wasAutoplayCancelled={this.props.wasAutoplayCancelled}
          cancelAutoplay={this.props.cancelAutoplay}
          breakpoint={this.props.breakpoint}
          gridPadding={this.props.gridPadding}
          isVisible={this.props.isVisible}
          animateCurtainAndPlay={this.props.animateCurtainAndPlay}
          presentation={this.props.presentation}
        />
      </div>
    );
  }
}

export default RecommendationListSmall;
