const proto = {
  _media_element_mounted() {
    const currentState = this._player.store.getState();
    const nativeControls = currentState.player?.options?.nativeControls;
    const live = currentState.player?.options?.live;
    const expandUnmutePlay =
      currentState.player?.options?.controls?.expandUnmutePlay;
    const toggleEnable = !nativeControls && !live;

    if (expandUnmutePlay) {
      this.playFullscreenBound = () => {
        if (!this._player.isFullscreen() && this._player.fullscreen) {
          this._player.fullscreen(true);
        }
      };
      this._player.video.addEventListener('click', this.playFullscreenBound);
    }

    this.togglePlay = () => {
      if (this._player.isMuted() && !this._player.isPaused()) {
        this._player.mute(false);
      } else {
        this._player.togglePlay();
      }
    };

    if (toggleEnable && !expandUnmutePlay) {
      this._player.video.addEventListener('click', this.togglePlay);
    }
  },

  _destroy() {
    // Need to check for removeEventListener existence due to video stubbing in tests
    if (this._player.video && this._player.video.removeEventListener) {
      this._player.video.removeEventListener('click', this.togglePlay);
    }
  }
};

export default {
  name: 'clickVideoToTogglePlay',
  proto,
  enable: true
};
