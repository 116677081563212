// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".poster-plugin-z-index-2PI2645M {\n  z-index: 44;\n}\n\n.captions-plugin-z-index-OjsfQXtY {\n  z-index: 39;\n}\n", ""]);
// Exports
exports.locals = {
	"poster-plugin-z-index": "poster-plugin-z-index-2PI2645M",
	"captions-plugin-z-index": "captions-plugin-z-index-OjsfQXtY"
};
module.exports = exports;
