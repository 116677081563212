import reducer from './reducer';
import actions from './actions';

const pluginClassName = 'transcript';

export default {
  name: 'transcript',
  enable: true,
  proto: {
    pluginClassName,

    _initialize() {
      this.subscribe(this, this._player.store);
    },

    _ready() {
      const store = this._player.store;

      if (
        this._player.options.transcript &&
        this._player.options.transcript.onShowTranscript
      ) {
        store.dispatch(actions.shouldEnableTranscript());
      }
    },

    subscribe() {
      const { store } = this._player;
      store.whenever('plugins.transcript.shouldEnableTranscript', true, () => {
        store.dispatch(actions.didEnableTranscript());
      });
      store.whenever('plugins.transcript.shouldDisableTranscript', true, () => {
        store.dispatch(actions.didDisableTranscript());
      });
    },

    reducer() {
      return { transcript: reducer };
    }
  }
};
