module.exports = function anonymous(locals, escape, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = utils.escapeXML(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escape = escape || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
        .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div class=\"<%= pluginClassName %>\">\n    <div class=\"<%= pluginClassName + '-center-container' %>\">\n        <h4><%= text %></h4>\n        <div class=\"vhs-embed-form-container\">\n            <input type=\"text\" value=\"<%- value %>\">\n        </div>\n        <i class=\"<%= pluginClassName + '-close-button'%>\" tabindex=\"0\" title=\"Close\" aria-label=\"Close\">\n            <svg class=\"<%= pluginClassName + '-close-button-x'%>\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 19.293 19.293\"><defs><path id=\"a\" d=\"M-389-551.42H635v1024H-389z\"/></defs><defs><path id=\"b\" d=\"M-389-551.42H635v1024H-389z\"/></defs><defs><path id=\"c\" d=\"M-389-551.42H635v1024H-389z\"/></defs><defs><path id=\"d\" d=\"M-389-551.42H635v1024H-389z\"/></defs><defs><path id=\"e\" d=\"M-389-551.42H635v1024H-389z\"/></defs><defs><path id=\"f\" d=\"M-389-551.42H635v1024H-389z\"/></defs><path d=\"M9.647 19.293c-2.577 0-5-1.003-6.82-2.825C1.002 14.646 0 12.223 0 9.648c0-2.578 1.003-5 2.825-6.823S7.07 0 9.647 0s5 1.003 6.82 2.825c1.823 1.822 2.826 4.244 2.826 6.82 0 2.578-1.003 5-2.825 6.822-1.822 1.823-4.245 2.826-6.82 2.826zm0-17.545c-2.11 0-4.094.822-5.585 2.314S1.748 7.537 1.748 9.647s.822 4.093 2.314 5.585 3.476 2.314 5.585 2.314 4.094-.822 5.585-2.314c1.492-1.492 2.314-3.476 2.314-5.585 0-2.11-.822-4.094-2.314-5.585s-3.476-2.314-5.585-2.314z\" fill=\"#FFFFFF\"/><g fill=\"#FFFFFF\"><path d=\"M6.01 12.054l6.043-6.044 1.23 1.23-6.044 6.045z\"/><path d=\"M7.24 6.01l6.043 6.043-1.23 1.23L6.008 7.24z\"/></g></svg>\n        </i>\n    </div>\n</div>\n"
  , __filename = "src/plugins/copy-slate/template.html";
try {
  var __output = [], __append = __output.push.bind(__output);
  with (locals || {}) {
    ; __append("<div class=\"")
    ; __append(escape( pluginClassName ))
    ; __append("\">\n    <div class=\"")
    ; __line = 2
    ; __append(escape( pluginClassName + '-center-container' ))
    ; __append("\">\n        <h4>")
    ; __line = 3
    ; __append(escape( text ))
    ; __append("</h4>\n        <div class=\"vhs-embed-form-container\">\n            <input type=\"text\" value=\"")
    ; __line = 5
    ; __append( value )
    ; __append("\">\n        </div>\n        <i class=\"")
    ; __line = 7
    ; __append(escape( pluginClassName + '-close-button'))
    ; __append("\" tabindex=\"0\" title=\"Close\" aria-label=\"Close\">\n            <svg class=\"")
    ; __line = 8
    ; __append(escape( pluginClassName + '-close-button-x'))
    ; __append("\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 19.293 19.293\"><defs><path id=\"a\" d=\"M-389-551.42H635v1024H-389z\"/></defs><defs><path id=\"b\" d=\"M-389-551.42H635v1024H-389z\"/></defs><defs><path id=\"c\" d=\"M-389-551.42H635v1024H-389z\"/></defs><defs><path id=\"d\" d=\"M-389-551.42H635v1024H-389z\"/></defs><defs><path id=\"e\" d=\"M-389-551.42H635v1024H-389z\"/></defs><defs><path id=\"f\" d=\"M-389-551.42H635v1024H-389z\"/></defs><path d=\"M9.647 19.293c-2.577 0-5-1.003-6.82-2.825C1.002 14.646 0 12.223 0 9.648c0-2.578 1.003-5 2.825-6.823S7.07 0 9.647 0s5 1.003 6.82 2.825c1.823 1.822 2.826 4.244 2.826 6.82 0 2.578-1.003 5-2.825 6.822-1.822 1.823-4.245 2.826-6.82 2.826zm0-17.545c-2.11 0-4.094.822-5.585 2.314S1.748 7.537 1.748 9.647s.822 4.093 2.314 5.585 3.476 2.314 5.585 2.314 4.094-.822 5.585-2.314c1.492-1.492 2.314-3.476 2.314-5.585 0-2.11-.822-4.094-2.314-5.585s-3.476-2.314-5.585-2.314z\" fill=\"#FFFFFF\"/><g fill=\"#FFFFFF\"><path d=\"M6.01 12.054l6.043-6.044 1.23 1.23-6.044 6.045z\"/><path d=\"M7.24 6.01l6.043 6.043-1.23 1.23L6.008 7.24z\"/></g></svg>\n        </i>\n    </div>\n</div>\n")
    ; __line = 12
  }
  return __output.join("");
} catch (e) {
  rethrow(e, __lines, __filename, __line);
}

}