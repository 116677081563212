// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* container */\n\n.container-1OKsg-Fn {\n  z-index: 50;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  visibility: hidden;\n  top: 0;\n  left: 0;\n}\n\n.container-1OKsg-Fn.isVisible-11DPZD81 {\n  visibility: visible;\n}\n\n/* content */\n\n.content-21_ZBTEY {\n  visibility: hidden;\n}\n\n.content-21_ZBTEY.isVisible-11DPZD81 {\n  visibility: visible;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1OKsg-Fn",
	"isVisible": "isVisible-11DPZD81",
	"content": "content-21_ZBTEY"
};
module.exports = exports;
