// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!src/stylesheet/font-families.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, "/* container */\n\n.container-1z3qaemI {\n  width: 100%;\n  position: relative;\n  z-index: 1;\n  cursor: pointer;\n}\n\n.container-1z3qaemI:focus {\n  outline: none;\n}\n\n/* background */\n\n.background-3GeSowaX {\n  position: absolute;\n  background-color: rgba(18, 18, 18, 0.95);\n  height: 100%;\n  width: 100%;\n}\n\n.container-1z3qaemI:focus .background-3GeSowaX {\n  outline: 1px solid #4a90e2;\n}\n\n/* upNextText */\n\n.upNextText-kzLz-0ub {\n  font-size: 13px;\n  font-weight: 600;\n  line-height: 1.23;\n  color: #999999;\n  position: absolute;\n  top: 9px;\n  left: 0;\n}\n\n.container-1z3qaemI.m-uoSV5F74 .upNextText-kzLz-0ub {\n  top: 14px;\n}\n\n/* title */\n\n.title-1txebCZs {\n  position: absolute;\n  top: 29px;\n  left: 0;\n  width: calc(100% - 100px);\n  font-size: 15px;\n  font-weight: 500;\n  line-height: 1.2;\n  color: #ffffff;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: block;\n  white-space: nowrap;\n  width: calc(100% - 150px);\n}\n\n.container-1z3qaemI.m-uoSV5F74 .title-1txebCZs {\n  top: 34px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1z3qaemI",
	"background": "background-3GeSowaX",
	"upNextText": "upNextText-kzLz-0ub " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["franklin"] + "",
	"m": "m-uoSV5F74",
	"title": "title-1txebCZs " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["cheltenham"] + ""
};
module.exports = exports;
