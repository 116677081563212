import preact from 'preact'; // eslint-disable-line no-unused-vars

const PersistentShareButtonIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="31"
      height="31"
      rx="15.5"
      fill="#121212"
      fillOpacity="0.6"
    />
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#424242" />
    <path
      d="M9.10566 21.5943C9.03954 21.5939 8.97437 21.5786 8.91494 21.5496C8.85551 21.5206 8.80333 21.4787 8.76226 21.4269C8.72119 21.3751 8.69227 21.3147 8.67763 21.2502C8.66299 21.1857 8.663 21.1188 8.67766 21.0543C9.89099 15.751 12.809 13.009 16.8983 13.2677V10.8843C16.9029 10.7936 16.9311 10.7057 16.9802 10.6293C17.0294 10.5529 17.0977 10.4907 17.1783 10.449C17.2626 10.4095 17.3565 10.3952 17.4487 10.4077C17.541 10.4203 17.6276 10.4591 17.6983 10.5197L23.1617 15.177C23.2643 15.274 23.3248 15.4073 23.3303 15.5483C23.3207 15.6878 23.2579 15.8183 23.155 15.913L17.691 20.5497C17.617 20.6033 17.5308 20.6378 17.4401 20.65C17.3495 20.6622 17.2573 20.6518 17.1717 20.6197C17.0886 20.5792 17.0184 20.5164 16.9689 20.4384C16.9195 20.3603 16.8927 20.2701 16.8917 20.1777V17.6597C11.2797 17.0563 9.57566 21.111 9.50499 21.293C9.47681 21.378 9.42346 21.4523 9.35202 21.5063C9.28058 21.5602 9.19443 21.5912 9.10499 21.595L9.10566 21.5943ZM15.8043 16.7543C16.3204 16.7529 16.8359 16.7881 17.347 16.8597C17.461 16.8834 17.5642 16.9434 17.6414 17.0306C17.7185 17.1178 17.7654 17.2276 17.775 17.3437V19.357L22.243 15.569L17.775 11.7743V13.6963C17.7729 13.7636 17.7576 13.8297 17.7299 13.8911C17.7023 13.9524 17.6627 14.0076 17.6137 14.0537C17.5638 14.0968 17.506 14.1296 17.4434 14.1502C17.3808 14.1708 17.3147 14.1788 17.249 14.1737C14.0437 13.8157 11.673 15.407 10.2837 18.7883C11.7825 17.4044 13.7646 16.664 15.8037 16.7263L15.8043 16.7543Z"
      fill="#F8F8F8"
    />
  </svg>
);

export default PersistentShareButtonIcon;
