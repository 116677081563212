import preact from 'preact'; // eslint-disable-line no-unused-vars

import formatDuration from 'src/util/format-duration';
import formatDurationPlaceholder from 'src/util/format-duration-placeholder';
import styles from './styles.css';

const placeholder = <span className={styles.placeholder}>Listen</span>;

const Duration = ({
  countdownMode,
  duration,
  progress,
  style,
  theme,
  mediaPlayed
}) => {
  let text;
  if (duration) {
    if (!mediaPlayed) {
      text = (
        <span className={styles.placeholder}>
          {' '}
          · {formatDurationPlaceholder(duration)}
        </span>
      );
    } else if (countdownMode) {
      const remainingTime = formatDuration(Math.max(duration - progress, 0));
      const ended = remainingTime === '0:00';
      text = `${ended ? '' : '-'}${remainingTime}`;
    } else {
      text = formatDuration(progress);
    }
  }

  const placeholderClass = duration ? '' : styles['duration--placeholder'];
  const themeClass = theme === 'light' ? styles.light : '';

  return (
    <div
      className={`${styles.duration} ${placeholderClass} ${themeClass}`}
      style={style || {}}
    >
      {!mediaPlayed && placeholder}
      {text}
    </div>
  );
};

export default Duration;
