// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.handle-yWumRNVF {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 5;\n  fill:#000000;\n}\n.light-1iQ_-Gik .handle-yWumRNVF {\n  fill: #f7f7f7;\n}\n\n.desktop-1y6HQ4wj .handle-yWumRNVF {\n  width: 12px;\n  height: 12px;\n}\n\n.desktop-1y6HQ4wj.scrubbing-1jGsslgJ .handle-yWumRNVF {\n  width: 6px;\n  height: 6px;\n}\n\n.scrubbing-1jGsslgJ .handle-yWumRNVF {\n  width: 12px;\n  height: 12px;\n}\n\n.ink-2JygG5tZ {\n  transform: scale(0);\n  width: 40px;\n  height: 40px;\n}\n\n.scrubbing-1jGsslgJ .ink-2JygG5tZ {\n  transform: scale(1) ;\n  -webkit-animation: ripple-3czL9qGK 200ms ease;\n          animation: ripple-3czL9qGK 200ms ease;\n  z-index: 4;\n  fill: rgba(0, 0, 0, 0.2);\n}\n.light-1iQ_-Gik.scrubbing-1jGsslgJ .ink-2JygG5tZ {\n  fill: rgba(255, 255, 255, 0.2);\n}\n\n@-webkit-keyframes ripple-3czL9qGK {\n  from {\n    transform: scale(0);\n  }\n  to {\n    transform: scale(1);\n  }\n}\n\n@keyframes ripple-3czL9qGK {\n  from {\n    transform: scale(0);\n  }\n  to {\n    transform: scale(1);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"handle": "handle-yWumRNVF",
	"light": "light-1iQ_-Gik",
	"desktop": "desktop-1y6HQ4wj",
	"scrubbing": "scrubbing-1jGsslgJ",
	"ink": "ink-2JygG5tZ",
	"ripple": "ripple-3czL9qGK"
};
module.exports = exports;
