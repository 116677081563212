// @ts-check

/** @typedef {(mediaType: import('src/player/options-pipeline').MediaType | undefined) => string} ErrorMessageWithMediaType */

/** @type {ErrorMessageWithMediaType} */
export const defaultVod = mediaType =>
  `This ${mediaType || 'media'} is currently unavailable.`;

/** @type {string} */
export const defaultLive = 'This stream is not currently available.';

/** @type {ErrorMessageWithMediaType} */
export const aborted = mediaType =>
  `The ${mediaType ||
    'media'} playback was aborted. Please refresh to resume playback.`;

/** @type {string} */
export const network =
  'The network connection was lost. Please refresh to resume playback.';

/** @type {ErrorMessageWithMediaType} */
export const decode = mediaType =>
  `There was ${mediaType === 'audio' ? 'an' : 'a'} ${mediaType ||
    'media'} playback error. Please refresh to resume playback.`;

/** @type {ErrorMessageWithMediaType} */
export const srcNotSupported = mediaType =>
  `The selected ${mediaType ||
    'media'} format is currently not supported in this browser.`;

/** @type {ErrorMessageWithMediaType} */
export const renditionNotFound = mediaType =>
  `Cannot select ${mediaType === 'audio' ? 'an' : 'a'} ${mediaType ||
    'media'} rendition for playback in this browser.`;

/** @type {string} */
export const unknown = 'An unknown error occurred.';

/** @type {string} */
export const showingErrorSlate =
  'VHS is currently showing this error message on-screen.';
