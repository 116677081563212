import { types } from './actions';

const initialState = {
  isInitialized: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT: {
      return {
        ...state,
        isInitialized: true
      };
    }
    case types.DID_SHARE_TWITTER: {
      return {
        ...state,
        sharedTwitter: true
      };
    }
    case types.DID_SHARE_FACEBOOK: {
      return {
        ...state,
        sharedFacebook: true
      };
    }
    case types.DID_SHARE_EMBED: {
      return {
        ...state,
        sharedEmbed: true
      };
    }

    default:
      return state;
  }
};
