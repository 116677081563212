import { types as captionsActions } from 'src/plugins/captions/actions';
import { types as transcriptActions } from 'src/plugins/transcript/actions';
import playerActions from 'src/player/actions';
import { types as controlsActions } from './actions';

const initialState = {
  controlsEnabled: false,
  isLive: false,
  isMouseDown: false,
  wasPlaying: false,
  hasExternalSource: null,
  captionsEnabled: false,
  transcriptEnabled: false,
  duration: 0,
  height: 0,
  forcedEnable: false,
  forcedDisable: false
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case playerActions.DATA_READY: {
      const { isLive, duration } = action.media;
      return { ...state, isLive, duration };
    }
    case playerActions.media.duration.DID_CHANGE: {
      const duration = action.duration;
      return { ...state, duration };
    }
    case playerActions.OPTIONS_READY: {
      const controlsEnabled = Boolean(action.payload.controls);
      const hasExternalSource = Boolean(
        action.payload.src || action.payload.source
      );
      const optionControlsFullscreen = action?.payload?.controls?.fullscreen;
      const fullscreen =
        optionControlsFullscreen === undefined
          ? true
          : optionControlsFullscreen;
      return { ...state, controlsEnabled, hasExternalSource, fullscreen };
    }
    case controlsActions.mouseDown: {
      const { isMouseDown } = action;
      return { ...state, isMouseDown };
    }
    case controlsActions.wasPlaying: {
      const { wasPlaying } = action;
      return { ...state, wasPlaying };
    }
    case controlsActions.heightChange: {
      const { height } = action;
      return { ...state, height };
    }
    case controlsActions.forceDisable: {
      return { ...state, forcedDisable: true, forcedEnable: false };
    }
    case controlsActions.forceEnable: {
      return { ...state, forcedDisable: false, forcedEnable: true };
    }
    case controlsActions.reset: {
      return { ...state, forcedDisable: false, forcedEnable: false };
    }
    case captionsActions.didEnableCaptions: {
      return { ...state, captionsEnabled: true };
    }
    case captionsActions.didDisableCaptions: {
      return { ...state, captionsEnabled: false };
    }
    case transcriptActions.didEnableTranscript: {
      return { ...state, transcriptEnabled: true };
    }
    case transcriptActions.didDisableCaptions: {
      return { ...state, transcriptEnabled: false };
    }
    default: {
      return state;
    }
  }
};
