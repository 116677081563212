// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".franklin-2GJdS5XG {\n  font-family: 'franklin', 'nyt-franklin', Arial, helvetica, sans-serif;\n}\n\n.karnak-1FdvNqmf {\n  font-family: 'karnak', 'nyt-karnak', 'nyt-karnak-display-130124';\n}\n\n.cheltenham-20WX1MC- {\n  font-family: 'cheltenham', 'nyt-cheltenham', Georgia, 'Times New Roman', times,\n    serif;\n}\n\n.cheltenhamCond-3MbDiSP1 {\n  font-family: 'nyt-cheltenham-cond', 'cheltenham', 'nyt-cheltenham', Georgia,\n    'Times New Roman', times, serif;\n}\n", ""]);
// Exports
exports.locals = {
	"franklin": "franklin-2GJdS5XG",
	"karnak": "karnak-1FdvNqmf",
	"cheltenham": "cheltenham-20WX1MC-",
	"cheltenhamCond": "cheltenhamCond-3MbDiSP1"
};
module.exports = exports;
