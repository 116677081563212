import dom from 'corleone';
import template from './template.html';
import './styles.scss';

export default {
  name: 'timecodePopup',
  enable: true,
  proto: {
    template,
    _initialize() {
      this.rendered = false;
      this.bindEvents();
    },

    _loadstart() {
      if (this.progressEl) {
        dom.remove(this.element);
        this.render(this.progressEl);
        this.element.style.display = 'none';
      }
    },

    bindEvents() {
      if (this._player.props.isTouch) {
        this.bindTouchEvents();
      } else {
        this.bindMouseEvents();
      }
    },

    bindTouchEvents() {
      this._on('plugin:controls:progress:touchstart', e => {
        this.onMouseEnter(e);
      });
      this._on('plugin:controls:progress:touchend', () => {
        this.onMouseLeave();
      });
      this._on('plugin:controls:progress:touchmove', e => {
        this.onMouseMove(e.time, e.formattedTime);
      });
    },

    bindMouseEvents() {
      this._on('plugin:controls:progress:mouseenter', e => {
        this.onMouseEnter(e);
      });
      this._on('plugin:controls:progress:mouseleave', () => {
        this.onMouseLeave();
      });
      this._on('plugin:controls:progress:mousemove', e => {
        this.onMouseMove(e.time, e.formattedTime);
      });
    },

    onMouseEnter(progressEl) {
      if (!this.rendered) {
        this.render(progressEl);
        this.element.style.display = 'block';
      }
      this.element.style.display = 'block';
    },

    onMouseLeave() {
      this.element.style.display = 'none';
    },

    onMouseMove(time, formattedTime) {
      if (!this.rendered) {
        return Promise.resolve();
      }

      const position = this.getBoxPosition(time, this.progressEl);
      this.element.style.left = `${position}%`;
      this.positionEl.innerHTML = formattedTime;

      this.element.style.display = 'block';
      return null;
    },

    getBoxPosition(time, progressEl) {
      const duration = this._player.store.getState().player.media.duration;
      const deltaEl =
        100 * (this.element.clientWidth / 2) / progressEl.clientWidth;
      const initPosition = parseFloat((time / duration * 100).toFixed(2));
      return initPosition - deltaEl;
    },

    render(progressEl) {
      this.progressEl = progressEl;
      this.element = dom.create(this.template());
      this.element.style.display = 'none';
      this.progressEl.appendChild(this.element);
      this.positionEl = this.element.querySelector(
        '.nytd-player-timecode-popup-position'
      );
      this.rendered = true;
      this.element.style.display = 'block';
    }
  }
};
