// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3KAFkS0y {\n  width: 60px;\n  height: 60px;\n  background-color: rgba(0, 0, 0, 0.5);\n  position: absolute;\n  top: calc(50% - (60px / 2));\n  left: calc(50% - (60px / 2));\n  border-radius: 50%;\n  opacity: 0;\n  transition: opacity 0.3s ease-out;\n}\n\n.container-3KAFkS0y.show-2x1SIuFT {\n  opacity: 1;\n}\n\n.container-3KAFkS0y.xxl-2xDAO2eO {}\n.container-3KAFkS0y.xl-3Dv6I8NY {}\n.container-3KAFkS0y.l-2DfjHHjS {}\n\n.container-3KAFkS0y.m-vw2KvYNq,\n.container-3KAFkS0y.s-23U9-py7,\n.container-3KAFkS0y.xs-rQLHR4Ax,\n.container-3KAFkS0y.xxs-3sHsZz_s {\n  transform: scale(0.66);\n}\n\n.playIcon-6ivKYBxl {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  margin-left: 3px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3KAFkS0y",
	"show": "show-2x1SIuFT",
	"xxl": "xxl-2xDAO2eO",
	"xl": "xl-3Dv6I8NY",
	"l": "l-2DfjHHjS",
	"m": "m-vw2KvYNq",
	"s": "s-23U9-py7",
	"xs": "xs-rQLHR4Ax",
	"xxs": "xxs-3sHsZz_s",
	"playIcon": "playIcon-6ivKYBxl"
};
module.exports = exports;
