const types = {
  mouseDown: 'plugins/controls/MOUSE_DOWN',
  wasPlaying: 'plugins/controls/WAS_PLAYING',
  heightChange: 'plugins/controls/HEIGHT_CHANGE',
  forceDisable: 'plugins/controls/FORCE_DISABLE',
  forceEnable: 'plugins/controls/FORCE_ENABLE',
  reset: 'plugins/controls/RESET'
};

export default {
  mouseDown: isMouseDown => ({ type: types.mouseDown, isMouseDown }),
  wasPlaying: wasPlaying => ({ type: types.wasPlaying, wasPlaying }),
  heightChange: height => ({ type: types.heightChange, height }),
  forceDisable: () => ({ type: types.forceDisable }),
  forceEnable: () => ({ type: types.forceEnable }),
  reset: () => ({ type: types.reset })
};

export { types };
