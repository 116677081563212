// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!src/stylesheet/font-families.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, "@-webkit-keyframes fadeIn-4If36s2s {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeIn-4If36s2s {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.duration-1DSstssP {\n  font-size: 12px;\n  color: #363636;\n  text-align: right;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  opacity: 1;\n}\n\n.light-3QsREpKi.duration-1DSstssP {\n  color: #ffffff;\n}\n\n.duration-1DSstssP.duration--placeholder-2Hosl4SL {\n  font-weight: 600;\n  -webkit-animation: none;\n          animation: none;\n  opacity: 1;\n}\n\n.placeholder-2zKsBY5D {\n  font-size: 15px;\n  color: #121212;\n}\n", ""]);
// Exports
exports.locals = {
	"duration": "duration-1DSstssP " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["franklin"] + "",
	"light": "light-3QsREpKi",
	"duration--placeholder": "duration--placeholder-2Hosl4SL",
	"placeholder": "placeholder-2zKsBY5D",
	"fadeIn": "fadeIn-4If36s2s"
};
module.exports = exports;
