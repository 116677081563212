const types = {
  INIT: 'plugins/sharetools/INIT',
  DID_SHARE_TWITTER: 'plugins/sharetools/didShareTwitter',
  DID_SHARE_FACEBOOK: 'plugins/sharetools/didShareFacebook',
  DID_SHARE_EMBED: 'plugins/sharetools/didShareEmbed'
};

export default {
  init: () => ({ type: types.INIT }),
  didShareTwitter: () => ({ type: types.DID_SHARE_TWITTER }),
  didShareFacebook: () => ({ type: types.DID_SHARE_FACEBOOK }),
  didShareEmbed: () => ({ type: types.DID_SHARE_EMBED })
};
export { types };
