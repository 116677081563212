// @ts-check

import getHost from './get-host';

/** @typedef {import('../player/options-pipeline').Env} Env */

/**
 * @param {string} host
 * @returns {Env}
 */
const getEnvFromHost = (host = getHost()) => {
  /** @type {Env} */
  let env = 'production';
  const pathname = document.location ? document.location.pathname : null;

  if (
    host === 'localhost' ||
    host.indexOf('.local.') !== -1 ||
    host.indexOf('localhost.') !== -1
  ) {
    env = 'localhost';
  } else if (
    pathname &&
    pathname.indexOf('/video-static/vhs3/preview/') !== -1
  ) {
    env = 'preview';
  } else if (host.indexOf('.dev.') !== -1) {
    env = 'development';
  } else if (host.indexOf('.stg.') !== -1 || host.indexOf('.sbx.') !== -1) {
    env = 'staging';
  }

  return env;
};

export default getEnvFromHost;
