import { orientation } from 'o9n';

export default {
  name: 'fullscreenOnLandscape',
  enable: false,
  proto: {
    state: {},

    _ready() {
      if (typeof this._player.fullscreen !== 'function') return;

      this.mapGlobalToLocalState();
      this.subscribeToStore();
      this.bindEventListeners();
    },

    bindEventListeners() {
      orientation.onchange = () => {
        if (this.shouldGoFullscreen()) {
          this._player.fullscreen(true);
          orientation.lock('landscape');
        }
      };
    },

    mapGlobalToLocalState() {
      const globalState = this._player.store.getState();

      this.state = {
        ...this.state,
        isPlaying: globalState.player.isMediaPlaying,
        isFullscreen: globalState?.plugins?.fullscreen?.isFullscreen || false,
        is360: globalState?.player?.media?.is360 || false
      };
    },

    subscribeToStore() {
      this._player.store.subscribe(() => this.mapGlobalToLocalState());
    },

    shouldGoFullscreen() {
      const { isFullscreen, is360, isPlaying } = this.state;
      return (
        !isFullscreen &&
        !is360 &&
        isPlaying &&
        orientation.type.indexOf('landscape') === 0
      );
    }
  }
};
