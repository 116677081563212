// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1qYO_7TI {\n  position: absolute;\n  top: 0;\n  height: 70px;\n  width: 123.7px;\n  background-position: center;\n  background-size: cover;\n  cursor: pointer;\n}\n\n.container-1qYO_7TI:focus {\n  outline: 1px solid #4a90e2;\n}\n\n.container-1qYO_7TI.xxl-2LOjFpp7 {}\n.container-1qYO_7TI.xl-2CxUK54f {}\n.container-1qYO_7TI.l-1tUHy_r5 {}\n.container-1qYO_7TI.m-3wpLGkwJ {}\n\n.container-1qYO_7TI.s-7YZxwseQ,\n.container-1qYO_7TI.xs-2efzNRP7,\n.container-1qYO_7TI.xxs-1LBLyz77 {\n  height: 60px;\n  width: 106px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1qYO_7TI",
	"xxl": "xxl-2LOjFpp7",
	"xl": "xl-2CxUK54f",
	"l": "l-1tUHy_r5",
	"m": "m-3wpLGkwJ",
	"s": "s-7YZxwseQ",
	"xs": "xs-2efzNRP7",
	"xxs": "xxs-1LBLyz77"
};
module.exports = exports;
