// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".spinner-3Lt2xaOY {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 1;\n  opacity: 0;\n  transition: opacity 300ms ease-out;\n  box-sizing: border-box;\n  display: block;\n}\n\n.light-3mvTZVuT.spinner-3Lt2xaOY {\n  border-color: #ffffff;\n}\n\n@-webkit-keyframes spin-2WokxaVi {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes spin-2WokxaVi {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.spinner-3Lt2xaOY span {\n  display: block;\n  box-sizing: border-box;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  clip: rect(20px, 40px, 40px, 0);\n  opacity: 0;\n  -webkit-animation: none;\n          animation: none;\n}\n\n.vhs-xxs-srxkh4CP.spinner-3Lt2xaOY span{\n  clip: rect(15px, 30px, 30px, 0);\n}\n.vhs-l-CpKHGqeB.spinner-3Lt2xaOY span,\n.vhs-xl-1K5-qrkS.spinner-3Lt2xaOY span,\n.vhs-xxl-LYkNjvut.spinner-3Lt2xaOY span {\n  clip: rect(25px, 60px, 60px, 0);\n}\n.mobile-x5ag1rPp.spinner-3Lt2xaOY span {\n  clip: rect(25px, 60px, 60px, 0);\n}\n\n.spinner-3Lt2xaOY span::after {\n  content: '';\n  box-sizing: border-box;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  border: 2px solid rgba(0,0,0,1);\n  border-radius: 50%;\n}\n.light-3mvTZVuT.spinner-3Lt2xaOY span::after {\n  border-color: #ffffff;\n}\n\n.show-32Q7tepE.spinner-3Lt2xaOY {\n  opacity: 1;\n  transition: opacity 300ms ease-out 300ms;\n  -webkit-animation: spin-2WokxaVi 3000ms linear infinite;\n          animation: spin-2WokxaVi 3000ms linear infinite;\n}\n\n.show-32Q7tepE.spinner-3Lt2xaOY span {\n  opacity: 1;\n  transition: opacity 300ms ease-out;\n  -webkit-animation: spin-2WokxaVi 1500ms cubic-bezier(0.77, 0, 0.175, 1) infinite;\n          animation: spin-2WokxaVi 1500ms cubic-bezier(0.77, 0, 0.175, 1) infinite;\n}\n", ""]);
// Exports
exports.locals = {
	"spinner": "spinner-3Lt2xaOY",
	"light": "light-3mvTZVuT",
	"vhs-xxs": "vhs-xxs-srxkh4CP",
	"vhs-l": "vhs-l-CpKHGqeB",
	"vhs-xl": "vhs-xl-1K5-qrkS",
	"vhs-xxl": "vhs-xxl-LYkNjvut",
	"mobile": "mobile-x5ag1rPp",
	"show": "show-32Q7tepE",
	"spin": "spin-2WokxaVi"
};
module.exports = exports;
