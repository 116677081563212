import preact from 'preact';
import { bind } from 'monocle-decorators';
import styles from './styles.css';

class Curtain extends preact.Component {
  // eslint-disable-next-line consistent-return
  getOnAnimationEndCallBack() {
    if (this.props.close) {
      return this.onClosed;
    } else if (this.props.open) {
      return this.onOpen;
    }
  }

  @bind
  onClosed() {
    this.props.onClosed();
  }

  @bind
  onOpen() {
    this.props.onOpen();
  }

  getCssClasses() {
    const cssClasses = [styles.curtain];

    if (this.props.close) {
      cssClasses.push(styles.fadeIn);
    } else if (this.props.open) {
      cssClasses.push(styles.fadeOut);
    }

    return cssClasses.join(' ');
  }

  render() {
    if (!this.props.open && !this.props.close) {
      return null;
    }

    return (
      <div className={styles.container}>
        <div
          className={this.getCssClasses()}
          onAnimationEnd={this.getOnAnimationEndCallBack()}
        />
      </div>
    );
  }
}

export default Curtain;
