// @ts-check
import dom from 'corleone';
import { actions } from 'src/player/actions';
import template from './template.html';
import './style.scss';

/**
 * Note: This plugin has been modified to react to the Player state store's `player.error`
 * property. For `apiEvents.SLATE` event handling, see the eventErrorDispatcher plugin.
 */
export default {
  name: 'errorSlate',
  enable: true,
  proto: {
    template,
    /** @type {(() => void)[]} */
    subscribers: [],

    _initialize() {
      this.renderSlate();
      this.hide();
      this.subscribe();
    },

    _destroy() {
      this.unsubscribe();
    },

    hide() {
      if (this.element) {
        dom.hide(this.element);
      }
    },

    show() {
      if (this.element) {
        dom.show(this.element);
      }
    },

    onClickReload(event) {
      this._player.reloadVideo();
      this.clearError();

      if (this._player.props.isTouch) {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    renderSlate() {
      this.element = dom.create(this.template());
      this._container.appendChild(this.element);
      this.registerElements();
    },

    registerElements() {
      this.errorMsgElement = this.element.querySelector(
        '.vhs-plugin-error-msg'
      );
      this.reloadElement = this.element.querySelector(
        '.vhs-plugin-error-reload-icon'
      );
      this.reloadElement.addEventListener(
        'click',
        this.onClickReload.bind(this)
      );
      this.alertIcon = this.element.querySelector('span.vhs-icon-alert');
    },

    clearError() {
      this._player.store.dispatch(actions.clearError());
    },

    /**
     * Subscribe to Player state store changes on the `player.error` and `player.error.retry`
     * properties. We use this to redraw the UI with the appropriate error message, enable or
     * disable the "Reload" button (depending on whether or not `error.retry` is `true`).
     */
    subscribe() {
      const { store } = this._player;
      this.subscribers = [
        store.whenever(
          'player.error',
          e => !!e,
          error => {
            this.errorMsgElement.innerHTML = error.message;
            this.show();
          }
        ),
        store.whenever(
          'player.error',
          e => !e,
          () => {
            this.errorMsgElement.innerHTML = '';
            this.hide();
          }
        ),
        store.whenever('player.error.retry', true, () => {
          dom.show(this.reloadElement);
          dom.hide(this.alertIcon);
        }),
        store.whenever('player.error.retry', false, () => {
          dom.hide(this.reloadElement);
          dom.show(this.alertIcon);
        })
      ];
    },

    unsubscribe() {
      this.subscribers.forEach(unsubscriber => {
        if (typeof unsubscriber === 'function') unsubscriber();
      });
      this.subscribers = [];
    }
  }
};
