import preact from 'preact';
import { connect } from 'preact-redux';
import styles from './styles.css';

const mapStateToProps = (state, ownProps) => ({
  posterUrl: state?.player?.media?.posterUrl,
  ...ownProps
});

@connect(mapStateToProps)
class Overlay extends preact.Component {
  render() {
    if (!this.props.posterUrl) {
      return null;
    }

    return (
      <div className={styles.container}>
        <div
          className={styles.posterImage}
          style={{ backgroundImage: `url(${this.props.posterUrl})` }}
        />
      </div>
    );
  }
}

export default Overlay;
