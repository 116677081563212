// #if process.env.NODE_ENV !== 'production'
// import logdown from 'logdown';
// #endif
import clone from 'clone';
import nextTick from 'src/util/next-tick';

// #if process.env.NODE_ENV !== 'production'
// const logger = logdown('vhs:plugins');
// #endif

function handlePluginInitializationError(error, pluginName) {
  // #if process.env.NODE_ENV !== 'production'
//   logger.error(`Error while initializing \`${pluginName}\`: `, error);
  // #endif
}

/**
 * This was previously an ESM `class`, but the way we use it below is not compatible with browsers'
 * native ESM `class` implementations.
 *
 * @see https://github.com/nytimes/vhs/pull/1164#discussion_r710603002
 */
function PluginBase(player) {
  this._player = player;
  this._container = player.container;
  this._options = player.options;
}

function registerPlugin(name, proto, player) {
  /**
   * Note: You can't reassign an ESM `class`'s `prototype` property. This is why `PluginBase` is a
   * function, whereas it was previously an ESM `class` that Babel transformed into a function at
   * build time.
   *
   * @see https://github.com/nytimes/vhs/pull/1164#discussion_r710603002
   *  */
  PluginBase.prototype = clone(proto);
  const plugin = new PluginBase(player);
  plugin._name = name;

  if (typeof plugin._initialize === 'function') {
    plugin._initialize();
  }

  return plugin;
}

function bindListeners(plugin, player) {
  function bindPluginEvent(eventName, methodName) {
    if (typeof plugin[methodName] === 'function') {
      player.on(player.events[eventName], plugin[methodName].bind(plugin));
    }
  }

  const pluginEvents = {
    READY: '_ready',
    PLAY: '_play',
    PAUSE: '_pause',
    MUTE: '_mute',
    VOLUME_CHANGE: '_volume_change',
    SEEKING: '_seeking',
    SEEKED: '_seeked',
    ENDED: '_ended',
    PLAYING: '_playing',
    AD_STARTED: '_ad_started',
    AD_COMPLETED: '_ad_completed',
    AD_PAUSED: '_ad_paused',
    AD_RESUMED: '_ad_resumed',
    AD_SKIPPED: '_ad_skipped',
    AD_STOPPED: '_ad_stopped',
    AD_IMPRESSION: '_ad_impression',
    GO_FULLSCREEN: '_go_fullscreen',
    EXIT_FULLSCREEN: '_exit_fullscreen',
    PROGRESS: '_progress',
    TIME_UPDATE: '_time_update',
    LOAD_START: '_loadstart',
    ERROR: '_error',
    MEDIA_ELEMENT_MOUNTED: '_media_element_mounted',
    TRACKING_EVENT: '_tracking_event',
    RENDITION_NOT_FOUND: '_rendition_not_found',
    IDLE: '_idle',
    ACTIVE: '_active',
    BROWSER_NOT_SUPPORTED: '_browser_not_supported',
    BROWSER_LIVE_NOT_SUPPORTED: '_browser_live_not_supported',
    PLAYER_SIZE_CHANGE: '_player_size_change',
    SLATE: '_slate',
    IN_VIEWPORT: '_in_viewport',
    OUT_VIEWPORT: '_out_viewport',
    DESTROY: '_destroy'
  };

  Object.entries(pluginEvents).forEach(([eventName, methodName]) => {
    bindPluginEvent.apply(player, [eventName, methodName]);
  });
}

export default function registerPlugins(player) {
  const options = player.options;
  const plugins = options.plugins;

  const promises = Object.entries(plugins)
    .filter(
      ([key, value]) =>
        options[key] || (value.enable === true && options[key] !== false)
    )
    .map(([key, value]) => {
      if (typeof value.canEnable === 'function') {
        return nextTick()
          .then(() => value.canEnable(player))
          .then(shouldEnable => {
            if (shouldEnable) {
              const plugin = registerPlugin(key, value.proto, player);
              bindListeners(plugin, player);
            }
          })
          .catch(error => {
            handlePluginInitializationError(error, key);
          });
      }

      return nextTick()
        .then(() => {
          const plugin = registerPlugin(key, value.proto, player);
          bindListeners(plugin, player);
        })
        .catch(error => {
          handlePluginInitializationError(error, key);
        });
    });

  return Promise.all(promises);
}
