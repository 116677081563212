import preact from 'preact';
import Provider from 'preact-context-provider';
import retry from 'async-retry';
import isMobile from 'src/util/is-mobile';
import AudioControlsV2 from './componentV2';
import AudioControlsV3 from './componentV3';
import actions from './componentV2/actions';
import Logger from '../../util/logger';

const logger = new Logger('vhs:plugin:audio');

async function loadControls(version) {
  if (version === 'v2') {
    return AudioControlsV2;
  }

  if (version === 'v3') {
    return AudioControlsV3;
  }

  // return version 1 of AudioControls
  try {
    const { default: AudioControlsV1 } = await retry(
      () => import(/* webpackChunkName: "audioControlsV1" */ './component'),
      { retries: 5 }
    );
    return AudioControlsV1;
  } catch (error) {
    logger.info('Error loading `AudioControlsV1`', error);
    throw error;
  }
}

const render = (container, player, store, AudioControlsComponent) => {
  preact.render(
    <Provider store={store}>
      <AudioControlsComponent player={player} />
    </Provider>,
    container
  );
};

//
// API
//

export default {
  enable: false,
  name: 'audioControls',
  proto: {
    async _initialize() {
      const store = this._player.store;
      const layout =
        this?._player?.options?.audioControls?.layout ||
        (isMobile() ? 'mobile' : 'desktop');
      actions(store).init({ layout });
      const state = store.getState();
      const version = state?.player?.options?.audioControls?.version;
      const AudioControlsComponent = await loadControls(version);
      render(this._container, this._player, store, AudioControlsComponent);
    },

    // Dummy reducer is needed until we remove `combineReducers`
    reducer() {
      return {
        audioControls: (state = {}) => state
      };
    }
  }
};
