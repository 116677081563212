/* eslint-disable react/prop-types, react/no-danger */

import preact from 'preact';
import { connect } from 'preact-redux';
import styles from './styles.css';

const mapStateToProps = (state, ownProps) => {
  const {
    areCaptionsAvailable,
    areCaptionsEnabled,
    currentText
  } = state.plugins.captions;

  let { raiseCaptions } = state.plugins.captions;
  if (state.plugins.controls.forcedDisable) {
    raiseCaptions = false;
  } else if (state.plugins.controls.forcedEnabled) {
    raiseCaptions = true;
  } else if (
    state?.player?.options?.controls?.templateClass === 'controls-v02'
  ) {
    raiseCaptions = state.plugins.fullscreen.isFullscreen;
  }

  const { cssClass } = state.plugins.responsive;
  return {
    areCaptionsAvailable,
    areCaptionsEnabled,
    cssClass,
    currentText,
    raiseCaptions,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({ ...ownProps });

@connect(mapStateToProps, mapDispatchToProps)
class Captions extends preact.Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.areCaptionsAvailable ||
      this.props.areCaptionsAvailable !== nextProps.areCaptionsAvailable
    );
  }

  getContainerCssClasses() {
    const classes = [styles.container, styles[this.props.cssClass]];
    if (this.props.raiseCaptions) {
      classes.push(styles.raised);
    }
    return classes.join(' ');
  }

  render() {
    if (!this.props.areCaptionsAvailable || !this.props.areCaptionsEnabled) {
      return null;
    }
    return (
      <div className={this.getContainerCssClasses()}>
        <div className={styles.cueWrap}>
          <span
            className={styles.captions}
            dangerouslySetInnerHTML={{ __html: this.props.currentText }}
          />
        </div>
      </div>
    );
  }
}

export default Captions;
