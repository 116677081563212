import _o from 'monocle-decorators';
import preact from 'preact';
import formatDuration from 'src/util/format-duration';
import styles from './styles.css';
import Video from './video';
import Footer from './footer';

class RecommendationListLarge extends preact.Component {
  constructor() {
    super();

    this.state = {
      highlightedVideo: 0
    };
  }

  get backgroundStyle() {
    return {
      left: `-${this.props.gridPadding}px`,
      width: `calc(100% + ${this.props.gridPadding * 2}px)`
    };
  }

  get containerStyle() {
    return {
      paddingBottom: `${this.props.gridPadding}px`,
      marginBottom: `-${this.props.gridPadding}px`
    };
  }

  getPosterImg(i) {
    try {
      return this.props.recommendedVideos[i].images.filter(
        rendition => rendition.name === 'videoSixteenByNine540'
      )[0].url;
    } catch (error) {
      return null;
    }
  }

  get numberOfRecommendedVideos() {
    switch (this.props.breakpoint) {
      case 'xxl':
      case 'xl':
        return 4;
      default:
        return 3;
    }
  }

  @_o.debounce(100)
  handleVideoHighlightChange(index = 0) {
    this.setState({
      highlightedVideo: index
    });
  }

  render() {
    const Videos = [];
    for (let i = 0; i < this.numberOfRecommendedVideos; i++) {
      const defaultProps = {
        id: this.props.recommendedVideos[i].id,
        posterImg: this.getPosterImg(i),
        duration: formatDuration(
          this.props.recommendedVideos[i].duration / 1000
        ),
        title: this.props.recommendedVideos[i].headline,
        section: this.props.recommendedVideos[i].section,
        totalSize: this.numberOfRecommendedVideos,
        breakpoint: this.props.breakpoint,
        gridPadding: this.props.gridPadding,
        isVisible: this.props.isVisible,
        player: this.props.player,
        animateCurtainAndPlay: this.props.animateCurtainAndPlay,
        index: i,
        onHighlightChange: index => this.handleVideoHighlightChange(index),
        isHighlighted:
          this.state.highlightedVideo === i && !this.props.isCurtainAnimating
      };

      if (i === 0) {
        Videos.push(
          <Video
            {...defaultProps}
            autoplay
            adSensitivity={this.props.recommendedVideos[i].adSensitivity}
            wasAutoplayCancelled={this.props.wasAutoplayCancelled}
            cancelAutoplay={this.props.cancelAutoplay}
          />
        );
      } else {
        Videos.push(<Video {...defaultProps} />);
      }
    }

    return (
      <div className={styles.container} style={this.containerStyle}>
        <div className={styles.background} style={this.backgroundStyle} />
        <div style={{ display: 'flex' }}>{Videos}</div>
        <div className={styles.spacer} />
        <Footer />
      </div>
    );
  }
}

export default RecommendationListLarge;
