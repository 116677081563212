// @ts-check
/* global webpackPluginVhsRelease, webpackPluginVhsVersion */
import { actions } from 'src/player/actions';
import * as errorMessages from './error-messages';

/**
 * The baseline VHS error data properties. Used when dispatching `actions.didError()`
 * events on the Player state store and when creating Error instances.
 *
 * @typedef {Object} VhsErrorData
 * @property {string} message
 * @property {boolean} [retry]
 */

/**
 * Optional "convenience" properties we may provide on an `Error` asynchronously thrown by VHS.
 * @typedef {Object} VhsErrorDebugData
 * @property {string} [release] - Which VHS release encountered this error.
 * @property {string} [version] - The version number of VHS which encountered this error.
 * @property {unknown} [state] - The Player state at approximately the time this the error was detected.
 */

/**
 * Data emitted with an hls.js `'error'` event from which we cannot automatically recover.
 *
 * The `Hlsjs` class's `triggerFatalError()` method adds a property `hlsjs` with value `true` to
 * signify that this is data for a fatal error we've received from the hls.js library.
 *
 * @typedef {import('hls.js').errorData & { hlsjs: true; }} HlsjsFatalErrorData
 */

/** @typedef {typeof import('hls.js').ErrorTypes} HlsjsErrorTypesRecord */

/**
 * Possible hls.js error types (i.e. for `HlsjsFatalErrorData['type']`).
 *
 * Note that, despite being provided by the type definitions, this custom union type is stricter
 * than the type definition for `Hls.errorData['type']`, which is declareed as a `string`.
 *
 * @typedef {HlsjsErrorTypesRecord[keyof HlsjsErrorTypesRecord]} HlsjsErrorType
 */

const defaultProps = Object.seal({
  isAdActive: false
});

/**
 * A VHS plugin which listens for certain Player events, including `apiEvents.ERROR`,
 * `apiEvents.RENDITION_NOT_FOUND` and `apiEvents.SLATE`, and dispatches `didError` actions on the
 * Player's state store. This allows VHS to both show a human-readable error message to the user
 * and throw an appropriate Error without crashing the surrounding web application.
 *
 * Note that this Errow-throwing technique is designed to allow global onerror handlers, such as
 * the one `@sentry/browser` sets up, to capture these Errors along with their stack trace.
 *
 * Note: This plugin now handles `apiEvents.SLATE` events, as the Error Slate plugin itself has
 * been modified to react to changes on the Player state store's `player.error` property.
 *
 * @see https://jira.nyt.net/browse/UXCL-946
 * @see https://github.com/nytm/wf-project-vi/pull/11970
 * @see http://docs.sentry.io/platforms/javascript/
 */
export default {
  name: 'eventErrorDispatcher',
  enable: true,
  proto: {
    props: defaultProps,

    /** @type {(() => void) | undefined} */
    unsubscriber: undefined,

    /** @returns {typeof defaultProps} */
    mapStateToProps() {
      const state = this._player.store.getState();

      return {
        isAdActive: state.player.isAdActive
      };
    },

    /** @returns {void} */
    _initialize() {
      this.props = this.mapStateToProps();
      this.subscribe();
    },

    /** @returns {void} */
    _destroy() {
      this.unsubscribe();
    },

    /**
     * Handle `apiEvents.RENDITION_NOT_FOUND` events.
     * @returns {void}
     */
    _rendition_not_found() {
      this.dispatchAndThrow({
        message: errorMessages.renditionNotFound(
          this._player.options.mediaType
        ),
        retry: false
      });
    },

    /**
     * Handle `apiEvents.ERROR` events, which could originate from a standard `HTMLMediaElement`'s
     * error event or from the hls.js library's own proprietary error event.
     *
     * @param {ErrorEvent | HlsjsFatalErrorData} data
     * @returns {void}
     */
    _error(data) {
      /**
       * Ignore errors encountered while an ad is active --- we don't control the Google IMA SDK,
       * so in theory it's their issue, not ours.
       */
      if (this.props.isAdActive) {
        return;
      }

      if ('hlsjs' in data) {
        this.onHlsjsError(data);
      } else {
        this.onHtml5Error(data);
      }
    },

    /**
     * Handle `apiEvents.SLATE` events.
     * Currently only used for the "Video Data Failed to Load" error message.
     *
     * @param {{ message?: string }} [evt]
     * @returns {void}
     */
    _slate(evt) {
      if (evt && evt.message) {
        this.dispatchAndThrow({ message: evt.message, retry: false });
      }
    },

    /**
     * See if the current Player's `video` element thinks it can play a particular MIME type.
     * We use this to determine if we should show the user a message related to their browser not
     * supporting the current media `src` we're trying to play, or if we encountered an issue
     * seemingly unrelated to the media format.
     *
     * @returns {boolean}
     */
    canPlay() {
      if (!this._player || !this._player.video) {
        return false;
      }

      if (this._player.options.live) {
        return this._player.video.canPlayType('application/x-mpegURL') !== '';
      }

      const fileType = this._player.video.currentSrc.split('.').pop();
      const mimeType =
        fileType === 'm3u8' ? 'application/x-mpegURL' : `video/${fileType}`;
      return this._player.video.canPlayType(mimeType) !== '';
    },

    /**
     * Dispatch a human-readable error message based on the type of hls.js fatal error data. Also,
     * fall back to a default error message if we cannot determine which type of error this is.
     *
     * @param {HlsjsFatalErrorData} data
     * @returns {void}
     */
    onHlsjsError(data) {
      /** @type {import('src/player/options-pipeline').Options} */
      const { mediaType } = this._player.options;
      /** @type {string} */
      let message;
      switch (/** @type {HlsjsErrorType} */ (data.type)) {
        case 'otherError':
          message = errorMessages.aborted(mediaType);
          break;
        case 'networkError':
          message = errorMessages.network;
          break;
        case 'mediaError':
          message = errorMessages.decode(mediaType);
          break;
        default:
          message = this._player.options.live
            ? errorMessages.defaultLive
            : errorMessages.defaultVod(mediaType);
          break;
      }
      this.dispatchAndThrow({ message, retry: true });
    },

    /**
     * Handle an `ErrorEvent` emitted by a standard `HTMLMediaElement` (_not_ by hls.js).
     *
     * @param {ErrorEvent} evt
     * @returns {void}
     */
    onHtml5Error(evt) {
      const error = this.getError(evt);
      const message = this.getErrorMessage(error);
      const retry =
        error instanceof MediaError === false ||
        error.code !== error.MEDIA_ERR_SRC_NOT_SUPPORTED;
      this.dispatchAndThrow({ message, retry });
    },

    /**
     * Try to extract the `MediaError` from a given `ErrorEvent`.
     *
     * @param {ErrorEvent} evt
     * @returns {MediaError | null}
     */
    getError(evt) {
      if (this._player.options.live) {
        return this._player.video ? this._player.video.error : null;
      }

      if (
        evt &&
        evt.currentTarget &&
        evt.currentTarget instanceof HTMLMediaElement
      ) {
        return evt.currentTarget.error;
      }

      // We couldn't figure out what the Error was
      return null;
    },

    /**
     * Map a particular type of `MediaError` to a human-readable error message. Also, fall back to
     * a default human-readable error message if we cannot determine which type of error this is.
     *
     * @param {MediaError | null} error
     * @returns {string}
     */
    getErrorMessage(error) {
      /** @type {import('src/player/options-pipeline').Options} */
      const { mediaType } = this._player.options;

      if (error) {
        switch (error.code) {
          case error.MEDIA_ERR_ABORTED:
            return errorMessages.aborted(mediaType);
          case error.MEDIA_ERR_NETWORK:
            return errorMessages.network;
          case error.MEDIA_ERR_DECODE:
            return errorMessages.decode(mediaType);
          case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
            return this.canPlay()
              ? errorMessages.defaultVod(mediaType)
              : errorMessages.srcNotSupported(mediaType);
          default:
            // Fall through to the `return` statement below
            break;
        }
      }

      return this._player.options.live
        ? errorMessages.defaultLive
        : errorMessages.defaultVod(mediaType);
    },

    /**
     * Dispatch a `didError` action to the Player state store and asynchronously throw an Error.
     * The latter technique allows global `onerror` handlers, as used in `@sentry/browser`, to
     * capture and report these Errors, even though we don't directly `throw` to the caller.
     *
     * @param {VhsErrorData} errorData
     * @returns {void}
     */
    dispatchAndThrow(errorData) {
      // Immediately dispatch a `didError` action so that subscribers get updated ASAP
      this._player.store.dispatch(actions.didError(errorData));

      /**
       * Construct an Error object with some additional information to make engineers' lives
       * easier when debugging media playback issues.
       * @type {Error & VhsErrorData & VhsErrorDebugData}
       */
      const error = new Error(
        `${errorData.message}${/\b$/.test(errorData.message) ? '.' : ''} ${
          errorMessages.showingErrorSlate
        }`
      );
      error.retry = errorData.retry;
      error.state = this._player.store.getState();
      error.release = webpackPluginVhsRelease;
      error.version = webpackPluginVhsVersion;

      // Give the `setTimeout()` handler function a name to aid debugging
      function throwErrorInSetTimeout() {
        throw error;
      }

      // Throw within a `setTimeout()` handler so a global onerror handler can capture it
      window.setTimeout(throwErrorInSetTimeout, 0);
    },

    /** @returns {void} */
    subscribe() {
      this.unsubscriber = this._player.store.subscribe(() => {
        this.props = this.mapStateToProps();
      });
    },

    /** @returns {void} */
    unsubscribe() {
      if (typeof this.unsubscriber === 'function') this.unsubscriber();
      this.unsubscriber = undefined;
    }
  }
};
