// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!src/stylesheet/font-families.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".tbutton-2L0GaJtC {\n  margin-top: 5px !important;\n  background: transparent;\n  height: 45px;\n  width: 140px;\n  color: white;\n  border: 1px solid !important;\n  border-radius: 4px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-self: center;\n  align-items: center;\n  transition: 0.3s ease;\n}\n\n.tbuttonLightTheme-3bup-CvX.tbutton-2L0GaJtC {\n  border-color: transparent !important;\n  background: rgba(0, 0, 0, 0.1) !important;\n}\n\n.tbuttonDarkTheme-cv8xEMJI.tbutton-2L0GaJtC {\n  border-color: #000000 !important;\n}\n\n.tbutton-2L0GaJtC:hover {\n  border: 1px rgba(255, 255, 255, 1) solid;\n  background: rgba(0, 0, 0, 0.1);\n}\n\n.tbutton-2L0GaJtC:focus {\n  border: 1px rgba(255, 255, 255, 1) solid;\n  background: rgba(0, 0, 0, 0.25);\n}\n\n.textSpan-2JDJ9ilH {\n  font-weight: bold;\n  font-size: 14px;\n  line-height: 20px;\n  margin-left: 15px;\n}\n\n.tbuttonLightTheme-3bup-CvX .textSpan-2JDJ9ilH {\n  color: #ffffff;\n}\n\n.tbuttonDarkTheme-cv8xEMJI .textSpan-2JDJ9ilH {\n  color: #000000;\n}\n\n.buttonContentContainer-1HitmpQG {\n  display: flex;\n  flex-direction: row;\n  margin:  auto 15px;\n}\n", ""]);
// Exports
exports.locals = {
	"tbutton": "tbutton-2L0GaJtC",
	"tbuttonLightTheme": "tbuttonLightTheme-3bup-CvX",
	"tbuttonDarkTheme": "tbuttonDarkTheme-cv8xEMJI",
	"textSpan": "textSpan-2JDJ9ilH " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["franklin"] + "",
	"buttonContentContainer": "buttonContentContainer-1HitmpQG"
};
module.exports = exports;
