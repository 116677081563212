// @ts-check
import { logger } from './purr';

/**
 * Print an info message showing the equivalent `nyt-purr` cookie value we have derived from
 * the Hybrid config using `getPurrCookieValueFromHybridConfig()`.
 *
 * One annoying thing below: Logdown seems to outright _remove_ underscores from its printed output
 * in Safari unless we escape them ourselves, so let's do that so we can ensure we're seeing the
 * accurate derived `purrCookie` value in the console. In this context, `_` indicates a character
 * in the derived cookie value for which the corresponding directive was not provided by the Hybrid
 * config. This is a known feature of the iOS and Android apps, namely, they only expose a subset
 * of the PURR directives.
 *
 * @param {string} purrCookie
 * @returns {void}
 */
export default function logDerivedPurrCookieValue(purrCookie) {
  logger.info(
    `Derived the \`nyt-purr\` cookie value from \`window.config.PurrDirectives\`: "${purrCookie.replace(
      /_/g,
      '\\_'
    )}"`
  );
}
