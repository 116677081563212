// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!src/stylesheet/font-families.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, "@-webkit-keyframes fadeIn-13ze28nJ {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeIn-13ze28nJ {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.duration-1RF_ghPY {\n  font-size: 14px;\n  color: rgba(0,0,0,1);\n  text-align: right;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  opacity: 0;\n  -webkit-animation: fadeIn-13ze28nJ 400ms ease-in 100ms forwards;\n          animation: fadeIn-13ze28nJ 400ms ease-in 100ms forwards;\n}\n\n.light-KhOaTkqG.duration-1RF_ghPY {\n  color: #ffffff;\n}\n\n.duration-1RF_ghPY.duration--placeholder-3QCCqWsG {\n  font-weight: 600;\n  -webkit-animation: none;\n          animation: none;\n  opacity: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"duration": "duration-1RF_ghPY " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["franklin"] + "",
	"fadeIn": "fadeIn-13ze28nJ",
	"light": "light-KhOaTkqG",
	"duration--placeholder": "duration--placeholder-3QCCqWsG"
};
module.exports = exports;
