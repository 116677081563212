// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".persistent-share-button-container-T6t95nAT {\n  position: absolute;\n  z-index: 10;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  padding: 16px;\n}\n\n.persistent-share-button-container-T6t95nAT button {\n  display: inline-block;\n  padding: 0;\n  border: none;\n  background: none;\n  height: 42px;\n  width: 42px;\n}\n\n.persistent-share-button-active-ph-erui8 {\n  opacity: 1;\n  transition: opacity 0.4s ease-in-out 0.2s;\n  -moz-transition: opacity 0.4s ease-in-out 0.2s;\n  -webkit-transition: opacity 0.4s ease-in-out 0.2s;\n}\n\n.persistent-share-button-hidden-10HyaxA8 {\n  opacity: 0;\n  transition: opacity 0.1s ease-in-out 100ms;\n  -moz-transition: opacity 0.1s ease-in-out 100ms;\n  -webkit-transition: opacity 0.1s ease-in-out 100ms;\n}\n", ""]);
// Exports
exports.locals = {
	"persistent-share-button-container": "persistent-share-button-container-T6t95nAT",
	"persistent-share-button-active": "persistent-share-button-active-ph-erui8",
	"persistent-share-button-hidden": "persistent-share-button-hidden-10HyaxA8"
};
module.exports = exports;
