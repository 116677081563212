import isMobile from 'src/util/is-mobile';
import { MOBILE_LAYOUT } from './index';

const getContainerVisibility = state =>
  !state.player.isLive &&
  !state.player.shouldPlay &&
  !state.player.isMediaPlaying &&
  state.player.isMediaEnded;

const getLayout = state => {
  const layout = state?.player?.options?.endSlate?.layout;

  if (layout === MOBILE_LAYOUT) {
    return MOBILE_LAYOUT;
  }

  if (isMobile()) {
    return MOBILE_LAYOUT;
  }

  return null;
};

const getHasRecommendation = state => {
  const allRecommended = state?.player?.media?.playlist?.videos;
  if (!allRecommended || allRecommended.length === 0) {
    return false;
  }

  const hasRecommendation = state?.player?.options?.endSlate?.hasRecommendation;
  if (hasRecommendation === undefined) {
    return true;
  }
  return Boolean(hasRecommendation);
};

const getAutoplayNext = state => {
  const autoplayNext = state?.player?.options?.endSlate?.autoplayNext;

  if (autoplayNext === undefined) {
    return true;
  }

  return Boolean(autoplayNext);
};

const getRecommendedVideos = state => {
  const allRecommended = state?.player?.media?.playlist?.videos;
  if (!allRecommended || allRecommended.length === 0) {
    return [];
  }

  const curVideoid = state.player.media.id;

  let indexOfCurVideo = -1;
  allRecommended.some((video, index) => {
    if (video.id === curVideoid) {
      indexOfCurVideo = index;
      return true;
    }
    return false;
  });

  // Order is important, so the first 5 videos after the current one are chosen.
  const selectedRecommended = allRecommended.slice(
    indexOfCurVideo + 1,
    indexOfCurVideo + 1 + 5
  );
  for (let i = 0; selectedRecommended.length < 5; i++) {
    selectedRecommended.push(allRecommended[i % allRecommended.length]);
  }

  return selectedRecommended;
};

const getShareUrl = state => {
  const isEmbeddedOnly = state?.player?.media?.isEmbeddedOnly;
  const mediaUrl = state?.player?.media?.url;
  const relatedUrl = state?.player?.media?.related?.[0]?.url;
  const shareUrl = state?.player?.options?.shareUrl;

  return (
    shareUrl ||
    (isEmbeddedOnly && relatedUrl) ||
    (!isEmbeddedOnly && mediaUrl) ||
    ''
  );
};

export {
  getContainerVisibility,
  getLayout,
  getHasRecommendation,
  getAutoplayNext,
  getRecommendedVideos,
  getShareUrl
};
