import preact from 'preact'; // eslint-disable-line no-unused-vars

const Arrow = () => (
  <svg
    width="7"
    height="12"
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: 'relative', top: '1px', left: '2px' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.26 6.263l.001.002-5.303 5.303-.707-.707 4.598-4.598L.25 1.665.958.958 6.26 6.26l-.002.002z"
      fill="#999"
    />
  </svg>
);

export default Arrow;
