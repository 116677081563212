import preact from 'preact';
import styles from './styles.css';
import Countdown from '../large/countdown';
import PlayIcon from '../large/play-icon';
import VideoLarge from '../large';

class VideoSmall extends preact.Component {
  get containerCssClasses() {
    const classes = [styles.container, styles[this.props.breakpoint]];

    return classes.join(' ');
  }

  get containerStyle() {
    const style = {
      right: 0,
      backgroundImage: this.props.posterImg
        ? `url(${this.props.posterImg})`
        : 'none'
    };

    if (this.props.breakpoint === 'xs' || this.props.breakpoint === 'xxs') {
      style.right = `-${this.props.gridPadding}px`;
    }

    return style;
  }

  handleClick(...args) {
    return VideoLarge.prototype.handleClick.apply(this, args);
  }

  // To disable focus styling
  // eslint-disable-next-line class-methods-use-this
  handleMouseDown(event) {
    event.preventDefault();
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.handleClick(event);
    }
  }

  render() {
    return (
      <div
        className={this.containerCssClasses}
        style={this.containerStyle}
        onClick={event => this.handleClick(event)}
        onMouseDown={event => this.handleMouseDown(event)}
        onKeyDown={event => this.handleKeyDown(event)}
        role="button"
        tabIndex={0}
      >
        {(!this.props.wasAutoplayCancelled &&
          this.props.autoplay && (
            <Countdown
              wasAutoplayCancelled={this.props.wasAutoplayCancelled}
              breakpoint={this.props.breakpoint}
              isVisible={this.props.isVisible}
              onCountdownFinished={this.props.onCountdownFinished}
            />
          )) || <PlayIcon breakpoint={this.props.breakpoint} show />}
      </div>
    );
  }
}

export default VideoSmall;
