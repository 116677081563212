// @ts-check

/**
 * @param {string} codec
 * @returns {boolean}
 */
const canPlayType = codec => {
  const videoElement = document.createElement('video');

  return (
    typeof videoElement.canPlayType === 'function' &&
    videoElement.canPlayType(codec) !== ''
  );
};

export default canPlayType;
