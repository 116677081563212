import preact from 'preact'; // eslint-disable-line no-unused-vars

const Fatal = () => (
  <svg width="30" viewBox="0 0 25.33 20.83" fill="#666666">
    <path d="M23.94 19.07L13.48 1H13a.8.8 0 0 0-.23 0 .8.8 0 0 0-.23 0h-.16L1.63 19.07a.81.81 0 0 0 .7 1.21h20.91a.81.81 0 0 0 .7-1.21zM12.79 3l9.06 15.69H3.73z" />
    <path d="M12.79 15.25a1.08 1.08 0 1 0 .76.32 1.08 1.08 0 0 0-.76-.32zM11.64 8.15l.77 5.93a.38.38 0 0 0 .37.39.36.36 0 0 0 .37-.38l.77-5.93a1.19 1.19 0 1 0-2.28-.01z" />
  </svg>
);

export default Fatal;
