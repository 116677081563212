// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/muted.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./img/unmuted.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".vhs-unmute{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center center;background-repeat:no-repeat;background-size:30px;bottom:0;right:0;position:absolute;text-indent:-9999px;z-index:9999;width:70px;height:70px;cursor:pointer}.vhs-unmute.mute{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}\n", ""]);
// Exports
module.exports = exports;
