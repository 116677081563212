// @ts-check

/**
 * @param {string} cookieKey
 * @param {string} cookie
 * @returns {string}
 */
function getCookie(cookieKey, cookie = '', document = window.document) {
  if (typeof cookieKey !== 'string') return '';

  try {
    if (!cookie) {
      cookie = document.cookie;
    }
    const cookieMap = new Map();

    cookie.split('; ').forEach(curCookie => {
      const [curCookieKey, curCookieVal] = curCookie.split('=');
      cookieMap.set(curCookieKey, curCookieVal);
    });

    return cookieMap.get(cookieKey) || '';
  } catch (error) {
    return '';
  }
}

export default getCookie;
