import preact from 'preact';
import RecommendationListLarge from './large';
import RecommendationListSmall from './small';
import { MOBILE_LAYOUT } from '../end-slate';

class RecommendationList extends preact.Component {
  static getInitialState() {
    return {
      wasAutoplayCancelled: false
    };
  }

  cancelAutoplay() {
    this.setState({
      wasAutoplayCancelled: true
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible && !this.props.isVisible) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(RecommendationList.getInitialState());
    }
  }

  render() {
    if (!this.props.isVisible) {
      return null;
    }

    if (
      !this.props.recommendedVideos ||
      this.props.recommendedVideos.length === 0
    ) {
      return null;
    }

    if (this.props.presentation === MOBILE_LAYOUT) {
      return (
        <RecommendationListSmall
          {...this.props}
          {...this.state}
          cancelAutoplay={() => this.cancelAutoplay()}
        />
      );
    }

    switch (this.props.breakpoint) {
      case 'xxl':
      case 'xl':
      case 'l':
        return (
          <RecommendationListLarge
            {...this.props}
            {...this.state}
            cancelAutoplay={() => this.cancelAutoplay()}
          />
        );
      default:
        return (
          <RecommendationListSmall
            {...this.props}
            {...this.state}
            cancelAutoplay={() => this.cancelAutoplay()}
          />
        );
    }
  }
}

export default RecommendationList;
