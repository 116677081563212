const types = {
  shouldEnableTranscript: 'plugins/transcript/SHOULD_ENABLE_TRANSCRIPT',
  shouldDisableTranscript: 'plugins/transcript/SHOULD_DISABLE_TRANSCRIPT',
  didEnableTranscript: 'plugins/transcript/DID_ENABLE_TRANSCRIPT',
  didDisableTranscript: 'plugins/transcript/DID_DISABLE_TRANSCRIPT',
  transcriptAvailable: 'plugins/transcript/TRANSCRIPT_AVAILABLE'
};

export default {
  shouldEnableTranscript: () => ({ type: types.shouldEnableTranscript }),
  shouldDisableTranscript: () => ({ type: types.shouldDisableTranscript }),
  didEnableTranscript: () => ({ type: types.didEnableTranscript }),
  didDisableTranscript: () => ({ type: types.didDisableTranscript }),
  transcriptAvailable: available => ({
    type: types.transcriptAvailable,
    isTranscriptAvailable: available
  })
};

export { types };
